import {Card} from '@material-ui/core';
import React, { Component } from "react";
import {Box, Grid, CardContent} from '@material-ui/core';

import MTipLogoAndModule from '../../../misc/MTipLogoAndModule';
import TipAndRateProfile from './TipAndRateProfile';
import TipAndRateTip from './TipAndRateTip';

export default class TipAndRateCard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      is_service_professional:props.is_service_professional,
      id:props.id,
      active_item: props.active_item,
      shared:props.shared,
      default_tip_info:props.default_tip_info,
    }
  }

  render = ()=> {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '89vh' }} >
            <Card>
                <CardContent>
                    <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
                        <MTipLogoAndModule module_name="Tip & Rate" logo_link="/"></MTipLogoAndModule>
                        <TipAndRateProfile active_item = {this.state.active_item} is_service_professional={this.state.is_service_professional}/>
                        <TipAndRateTip id={this.state.id} shared={this.state.shared} default_tip_info={this.state.default_tip_info}/>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    </Box>
    );
  }
}