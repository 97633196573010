
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import {Chip,CardMedia, Box, List, ListItemButton, ListItemText, Stack, Typography, TextField, Divider,} from "@material-ui/core";
import Switch from '@mui/material/Switch';
import { uploadBannerPicture, getMartipiaMenuSettings, toggleMenuBanner, changeGreetingText,changeGreetingTextTranslations, changeMenuTags, changeSubgreetingText, changeSubgreetingTextTranslations } from "../internal/menu_api";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES} from "../../../../config/systemMessages";
import MartipiaMenuCategoryModal from "./MartipiaMenuCategoryModal";
import MartipiaMenuItemModal from "./MartipiaMenuItemModal";
import MartipiaMenuCopyModal from "./MartipiaMenuCopyModal";


class MartipiaMenuSettings extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      business_id: props.business_id,
      menu_settings: props.menu_settings,
      menu_categories: props.menu_categories,
      menu_items: props.menu_items,
    };
  }

  refreshData = () => {
    getMartipiaMenuSettings(this.state.business_id, this.handleFirstRefresh, this.props.handleFailure);
  }

  handleFirstRefresh = (res) => {
    this.setState({
      menu_settings: res.data.menu_settings,
      menu_categories: res.data.categories,
      menu_items: res.data.items,
    });
  }

  handleRefresh = (res) => {
    this.setState({
      menu_settings: res.data,
    });
  }
  
  // Input Handlers
  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState(prevState => ({
      menu_settings: {
        ...prevState.menu_settings,
        [name]: value,
      },
    }));
  };
  
  handleInputChange = (event) => {
    this.setState({ inputTag: event.target.value });
  };
  
  handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && this.state.inputTag) {
      // Prevent form submission
      event.preventDefault();
  
      const newTag = this.state.inputTag.trim();
      // Add tag if it's not already included
      if (newTag && !this.state.menu_settings.menu_tags.includes(newTag)) {
        this.setState(prevState => ({
          menu_settings: {
            ...prevState.menu_settings,
            menu_tags: [...prevState.menu_settings.menu_tags, newTag],
          },
          inputTag: '', // Clear input after adding
        }));
      }
    }
  };
  
  handleDeleteTag = (tagToDelete) => () => {
    this.setState(prevState => ({
      menu_settings: {
        ...prevState.menu_settings,
        menu_tags: prevState.menu_settings.menu_tags.filter(tag => tag !== tagToDelete),
      },
    }));
  };
  
  handleCategorySelect = (category) => () => {
    this.setState({ selected_category: category });
    this.toggleCategoryModal();
  };

  
  handleItemSelect = (item) => () => {
    this.setState({ selected_item: item });
    this.toggleItemModal();
  };

  handleMenuTypeChoice = (event, newMenuTypeChoice) => {
    // Avoid setting null value when no option is chosen
    if (newMenuTypeChoice !== null) {
      this.setState({ menu_type_choice: newMenuTypeChoice });
    }
  };
  
  handleChangeTranslation = (e) => {
    const { name, value } = e.target;
    // Splitting based on the period as a delimiter
    const [translationField, languageCode] = name.split('.');
  
    this.setState(prevState => ({
      menu_settings: {
        ...prevState.menu_settings,
        [translationField]: {
          ...prevState.menu_settings[translationField],
          [languageCode]: value,
        },
      },
    }));
  };
  

  // API

  toggleMenuBanner = () => {
    toggleMenuBanner(this.state.business_id, this.state.menu_settings.id, this.handleRefresh, this.props.handleFailure)
  }
  changeMenuTags = () => {
    changeMenuTags(this.state.business_id, this.state.menu_settings.id, this.state.menu_settings.menu_tags, this.handleSuccesfulChangeMenuTags, this.props.handleFailure)
  }
  handleSuccesfulChangeMenuTags = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_TAGS_UPDATED);
    this.handleRefresh(res);
  }
  
  changeSubgreetingText = () => {
    changeSubgreetingText(this.state.business_id, this.state.menu_settings.id, this.state.menu_settings.menu_greetings_subtext_default, this.handleSuccesfulGreetingSubtextChange, this.props.handleFailure)
  }
  
  changeSubgreetingTextTranslations = () => {
    changeSubgreetingTextTranslations(this.state.business_id, this.state.menu_settings.id, this.state.menu_settings.menu_greetings_subtext_translations, this.handleSuccesfulGreetingSubtextChange, this.props.handleFailure)
  }
  
  handleSuccesfulGreetingSubtextChange = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_GREETINGS_SUBTEXT_UPDATED);
    this.handleRefresh(res);
  }
  
  changeGreetingText = () => {
    changeGreetingText(this.state.business_id, this.state.menu_settings.id, this.state.menu_settings.menu_greetings_text_default, this.handleSuccesfulGreetingTextChange, this.props.handleFailure)
  }
  changeGreetingTextTranslations = () => {
    changeGreetingTextTranslations(this.state.business_id, this.state.menu_settings.id, this.state.menu_settings.menu_greetings_text_translations, this.handleSuccesfulGreetingTextChange, this.props.handleFailure)
  }
  
  handleSuccesfulGreetingTextChange = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_GREETINGS_TEXT_UPDATED);
    this.handleRefresh(res);
  }

    uploadBannerPicture = (event, menu_id, martipiamenubannerpicture) => {
      const myFile = event.target.files[0]
      const formData = new FormData()
      formData.append("file", myFile)
      formData.append("business",this.state.business_id)
      formData.append("menu_id",menu_id)

      if (!martipiamenubannerpicture)  
      { 
        this.props.handleLoading()
        uploadBannerPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
      }
      else
      {
        formData.append("id",martipiamenubannerpicture.id)
        this.props.handleLoading()
        uploadBannerPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
      };
    }
  
  handleSuccessfulPictureUpload = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_BANNER_PICTURE_UPDATED);
    //insert the new picture into the menu settings state value
    this.setState(prevState => ({
      menu_settings: {
        ...prevState.menu_settings,
        martipiamenubannerpicture: res.data,
      },
  }));
  }

  handleSuccessfulMenuCopy = () => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_COPIED);
  }

  handleSuccessfulItemCopy = () => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_ITEM_COPY_SUCCESS);
  }


// Modals
  toggleBusinessesCopyModal = () => {
    this.setState({businesses_modal_open: !this.state.businesses_modal_open})
  }

  toggleItemModal = () => {
    this.setState({item_modal_open: !this.state.item_modal_open})
  }
  toggleCategoryModal = () => {
    this.setState({category_modal_open: !this.state.category_modal_open})
  }
  


  render = ()=> {

  const { toggleModal} = this.props;

  return (
    
    <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Martipia Menu Settings</DialogTitle>
      <DialogContent>
        {this.state.item_modal_open && <MartipiaMenuItemModal open={this.state.item_modal_open} toggleModal={this.toggleItemModal} item={this.state.selected_item} categories={this.state.menu_categories} tags={this.state.menu_settings.menu_tags} menu_id={this.state.menu_settings.id} business_id={this.state.business_id} refreshData={this.refreshData} handleSuccessfulItemCopy={this.handleSuccessfulItemCopy}/>}
        {this.state.category_modal_open && <MartipiaMenuCategoryModal open={this.state.category_modal_open} toggleModal={this.toggleCategoryModal} category={this.state.selected_category} menu_id={this.state.menu_settings.id} business_id={this.state.business_id} refreshData={this.refreshData}/>}
        {this.state.businesses_modal_open && <MartipiaMenuCopyModal open={this.state.businesses_modal_open} toggleModal={this.toggleBusinessesCopyModal} business_id={this.state.business_id} menu_id={this.state.menu_settings.id} refreshData={this.refreshData} handleSuccessfulMenuCopy={this.handleSuccessfulMenuCopy}/>}
        
        <Stack direction="column" spacing={3} alignItems="center">
          <DialogContentText>
          Create a menu and customize it - create menu categories, menu items, tags, banners, and more.
          </DialogContentText>
        <Stack direction="column" spacing={3} alignItems="center">
          <Typography variant="h5">
            To view your menu, click the button below.
          </Typography>
          <Button variant="contained" color="primary" href={"/menu/"+this.state.menu_settings.business_profile_shared_qr} target="_blank">
            Preview
          </Button>
          <Stack justifyContent={"flex-start"} spacing={1}>

            <Stack direction = 'row' spacing={2} justifyContent='space-between'>
              <Typography align={"left"} variant='subtitle'>          
                Copy menu from another owned business.
              </Typography>
              <Button 
                type='submit'
                size="small"
                variant="contained" 
                color="secondary" 
                onClick={() => this.toggleBusinessesCopyModal()}
                >
                Copy
              </Button>
            </Stack>

            <Typography align={"left"} variant='subtitle'>          
              Enable front page banner.
            </Typography>
              <Switch
              checked={this.state.menu_settings.menu_banner_enabled || false}
              onChange={() => this.toggleMenuBanner()}
              name="shared_qr"
              color="primary"  
            />

            {this.state.menu_settings.menu_banner_enabled && (
            <div>
              <Typography align={"left"} variant='subtitle'>
                Upload banner image. We recommend using a 2.5:1 aspect ratio image.
              </Typography>
              <Button
              variant="outlined"
              component="label"
              >
                Upload Picture
                <input
                  type="file"
                  hidden
                  onChange = {(e) => this.uploadBannerPicture(e, this.state.menu_settings.id, this.state.menu_settings.martipiamenubannerpicture)}
                  />
              </Button>
              {this.state.menu_settings.martipiamenubannerpicture && (
                <Stack>
                  <Typography align={"left"} variant='subtitle'>
                    Banner image (it will be cut off here, but users may view the full image):
                  </Typography>
                  <CardMedia 
                    component="img"
                    height="140"
                    image={this.state.menu_settings.martipiamenubannerpicture.file}
                    alt="menu banner"/>
                </Stack>
              )}
            </div>
            )}

            <Typography align={"left"} variant="body2">
              Please enter the default front page greetings text. If you don't have a translation, it will be used as the default.
            </Typography>
            <Stack direction = 'row' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="Greetings text"
                name="menu_greetings_text_default"
                type="text"
                value={this.state.menu_settings.menu_greetings_text_default || ""}
                onChange={this.handleChange}
                placeholder="Menu Greetings Text"
              >
              </TextField>
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeGreetingText()}
                >
                Save
              </Button>
            </Stack>

            <Typography align={"left"} variant="body2">
              Please enter the translations for the front page greetings text.
            </Typography>
            <Stack direction='column' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="Georgian Greetings Text"
                name="menu_greetings_text_translations.ka"
                type="text"
                value={this.state.menu_settings.menu_greetings_text_translations?.ka || ""}
                onChange={this.handleChangeTranslation }
                placeholder="Georgian translation"
              />
              <TextField
                margin="dense"
                fullWidth
                label="English Greetings Text"
                name="menu_greetings_text_translations.en" // Notice the dot notation
                type="text"
                value={this.state.menu_settings.menu_greetings_text_translations?.en || ""}
                onChange={this.handleChangeTranslation}
                placeholder="English translation"
              />
              <TextField
                margin="dense"
                fullWidth
                label="Russian Greetings Text"
                name="menu_greetings_text_translations.ru"
                type="text"
                value={this.state.menu_settings.menu_greetings_text_translations?.ru || ""}
                onChange={this.handleChangeTranslation }
                placeholder="Russian translation"
              />
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeGreetingTextTranslations()}
                >
                Save Translations
              </Button>
            </Stack>

            
            <Typography align={"left"} variant="body2">
              Please enter the default front page greetings sub-text. If you don't have a translation, it will be used as the default.
            </Typography>
            <Stack direction = 'row' spacing={2}>
              <TextField
                fullWidth
                margin="dense"
                label="Greetings Sub-text"
                name="menu_greetings_subtext_default"
                type="text"
                value={this.state.menu_settings.menu_greetings_subtext_default || ""}
                onChange={this.handleChange}
                placeholder="Menu Greetings Sub-text"
              >
              </TextField>
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeSubgreetingText()}
                >
                Save
              </Button>
            </Stack>

            
            <Typography align={"left"} variant="body2">
              Please enter the translations for the front page greetings sub-text.
            </Typography>
              <TextField
                margin="dense"
                fullWidth
                label="Georgian Greetings Text"
                name="menu_greetings_subtext_translations.ka"
                type="text"
                value={this.state.menu_settings.menu_greetings_subtext_translations?.ka || ""}
                onChange={this.handleChangeTranslation }
                placeholder="Georgian translation"
              />
            <Stack direction='column' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="English Greetings Sub-text"
                name="menu_greetings_subtext_translations.en" // Notice the dot notation
                type="text"
                value={this.state.menu_settings.menu_greetings_subtext_translations?.en || ""}
                onChange={this.handleChangeTranslation}
                placeholder="English translation"
              />
              <TextField
                margin="dense"
                fullWidth
                label="Russian Greetings Text"
                name="menu_greetings_subtext_translations.ru"
                type="text"
                value={this.state.menu_settings.menu_greetings_subtext_translations?.ru || ""}
                onChange={this.handleChangeTranslation }
                placeholder="Russian translation"
              />
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeSubgreetingTextTranslations()}
                >
                Save Translations
              </Button>
            </Stack>

            <Typography align="left" variant="body2">
              Please enter the menu item tags, like "new", "vegan", etc. Hit "ENTER" and don't forget to save!
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {this.state.menu_settings.menu_tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={this.handleDeleteTag(tag)}
                />
              ))}
            </Box>
            <Stack direction={'row'}>
              <TextField
                fullWidth
                size="small"
                placeholder="Add a tag"
                value={this.state.inputTag}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
              />
              
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeMenuTags()}>
                Save
              </Button>
            </Stack>
            <Divider />
            <Typography align="left" variant="h6">
              Menu Categories
            </Typography>
            <Typography align="left" variant="body">
              Create new categories, or click an existing one to edit it.
            </Typography>

            <Box sx={{ height:'auto', maxHeight: 200, overflow: 'auto', mt: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
              <List>
                {this.state.menu_categories.map((category) => (
                  <ListItemButton  key={category.id} onClick={this.handleCategorySelect(category)}>
                    <ListItemText primary={category.name_default} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
            <Button onClick={this.handleCategorySelect({})} variant='outlined'>
              Create a menu category.
            </Button>
            
            <Divider />
            <Typography align="left" variant="h6">
              Menu Items
            </Typography>
            <Typography align="left" variant="body">
              Create new items, or click an existing one to edit it.
            </Typography>
            <Box sx={{ height:'auto', maxHeight: 200, overflow: 'auto', mt: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
              <List>
                {this.state.menu_items.map((item) => (
                  <ListItemButton  key={item.id} onClick={this.handleItemSelect(item)}>
                    <ListItemText primary={item.name_default} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
            <Button onClick={this.handleItemSelect('')} variant='outlined'>
              Create a menu item.
            </Button>

          </Stack>
        </Stack>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withErrorHandling(MartipiaMenuSettings);