const georgian_translations= {
    'welcome': 'კეთილი მოსვლა ჩვენს ვებსაიტზე',

    //TipAndRateTip
    'TipAndRateTip_tip_label':'თიფი',
    'TipAndRateTip_tip_button':'გადარიცხვა',
    'TipAndRateTip_tip_redirect':'გადამისამართება გადახდის გვერდზე',    
    'TipAndRateTip_comment_label':'კომენტარი​',
    'TipAndRateTip_payment_options_card_label':'ბარათი',
    'TipAndRateTip_payment_options_label':'გადახდის მეთოდები',
    'TipAndRateTip_please_accept':'გთხოვთ დაეთანხმოთ',
    'TipAndRateTip_toc':'წესები და პირობები',
    'TipAndRateTip_privacy_policy':'კონფიდენციალურობის დაცვის პოლიტიკა',
    'TipAndRateTip_please_rate':'Გთხოვთ შეაფასოთ',
    'TipAndRateTip_cover_transaction_cost_text':'მინდა დავფარო ტრანზაქციის საკომისიო.',
    'TipAndRateTip_cover_transaction_cost_hover':'მონიშნეთ ეს ველი, თუ გსურთ დაფაროთ 7.9% ტრანზაქციის საკომისიო.',

    //TipAndRateListCollapsable
    'TipAndRateListCollapsable_choose_employee':'აირჩიეთ თანამშრომელი',
    'TipAndRateListCollapsable_staff_list_button_open':'🤔 თიფი თანამშრომელისთვის 🔍',
    'TipAndRateListCollapsable_staff_list_button_close':'დახურე',
    
    //TipAndRateSplitTipCollapsable
    'TipAndRateSplitTipCollapsable_tip_box_button_open':'😊 თიფი ყველა თანამშრომელისთვის 🤝',
    'TipAndRateSplitTipCollapsable_tip_box_button_close':'დახურე',

    //TipAndRateMenu
    'TipAndRateMenu_view_menu_button':'📋 მენიუ 🍽️',
    
    //TipAndRateProfile
    'TipAndRateProfile_profile_reviews':'მიმოხილვა​',

    //TipAndRateEasyTip
    'TipAndRateEasyTip_tip_button':'😎 თიფი 👌',

     //ThankYou
    'ThankYou_thank_you_text':'🙌 მადლობა თიფისთვის! 💚',
    
    //ThankYouCard
    'ThankYouCard_google_review_button':'დატოვეთ Google Review',
  }

  export default georgian_translations;