
import React, { Component } from "react";
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class DeleteModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    }
  };
  
  render = ()=> {

  const { toggle, deleteAction, message} = this.props;
  const messageText = message ? message : "Are you sure you want to delete? All data and remaining balance may become inaccessible."
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggle} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Message:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {messageText} 
          </DialogContentText>          
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="primary">
            Close
          </Button>
          <Button onClick={deleteAction} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }

}