import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


export default class IikoIntegrationModalDetails extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      platform_info: props.platform_info,
    };
  }

  handleChange = (e) => {
    let { value, name } = e.target; 
    const prevItem = this.state.platform_info;
    const platformInfo = { ...prevItem, [name]: value };
    this.setState({
      platform_info: platformInfo,
    });
  };

  render = ()=> {  
    const { toggleModal, submitIntegrationsInfo } = this.props;
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            <Typography align="center" variant='h4'>
              iiko
            </Typography>
          </DialogContentText>
          <TextField
            margin="dense"
            id="businessProfile-integration_detail-iiko"
            label="User ID"
            name="user_id"
            type="text"
            value={this.state.platform_info.user_id}
            onChange={this.handleChange}
            placeholder="Enter the User ID."
            fullWidth
          />
        </DialogContent>        
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick=
          { () =>
            {
            submitIntegrationsInfo("iiko", this.state.platform_info);
            toggleModal();
            }
          }>
            Save
          </Button>
        </DialogActions>
      </div> 
      );
  }
}
