import React, { Component } from "react";
import { Typography, Stack, Container, Divider, Card, CardHeader, CardContent } from '@material-ui/core';
import { getBusinessReviewsData, getBusinessHighestTip } from "./scripts"; 
import MTipReviewAnalytics from "../../martipiaReviews/MTipReviewAnalytics";
import withErrorHandling from "../../../hocs/withErrorHandling";






class BusinessProfileReviewsDashboard extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      id:props.id.id,
      reviews:"",
      highest_tip:"",
      message_modal:false,
      modal_message:"",
    };
  }

  componentDidMount ()
  {
      this.refreshData();
  }
  
  handleSuccessfulReviewsRefresh = (res) => {
    this.setState({
      reviews: res.data,
    });
  }
  
  handleSuccessfulHighestTipRefresh = (res) => {
    this.setState({
      highest_tip: res.data,
    });
  }
  

  refreshData = () =>
  {
    getBusinessReviewsData(this.state.id, this.handleSuccessfulReviewsRefresh, this.props.handleFailure)
    getBusinessHighestTip(this.state.id, this.handleSuccessfulHighestTipRefresh, this.props.handleFailure)
  };

  render() 
  {
    return  (
      <Container>
        <Card >
          <CardHeader title="📊 Review Analytics 📈" />
          <Divider />
          <CardContent>
            <Stack alignItems="center">
            {this.state.reviews.length && this.state.highest_tip
              ? <MTipReviewAnalytics reviews={this.state.reviews} is_business={true} business_id={this.state.id} highest_tip={this.state.highest_tip}/>
              :<Typography variant="body"> No reviews are currently registered with this business. </Typography>
            }
            </Stack>
          </CardContent>
        </Card>
      </Container>

    );
  }
}

export default withErrorHandling(BusinessProfileReviewsDashboard);