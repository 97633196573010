import React, { Component } from "react";
import {Box, Grid,  Stack,  Card, Divider, CardHeader, CardContent, Container } from '@material-ui/core';

import Scrollbar from '../../misc/navigation/Scrollbar';
import BusinessProfileImage from '../businessProfile/BusinessProfileImage';
import {refreshBusinessProfileList} from "./scripts";
import withErrorHandling from "../../hocs/withErrorHandling";

class MTipMyBusinesses extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      active_item: '',
      modal: false,
      business_profile_list: [],
      message_modal:"",
    };
  }

  componentDidMount ()
  {
      this.refreshData();
  }

  handleSuccessfulRefresh = (res) => {
    this.setState({
      business_profile_list: res.data,
    });
  }
  

  refreshData = () =>
  {
    refreshBusinessProfileList(this.handleSuccessfulRefresh,this.props.handleFailure)
  };

  handleSuccesfulSubmit = (res) => {
    refreshBusinessProfileList(this.handleSuccessfulRefresh,this.props.handleFailure)
  }
  
  renderBusinesses = () => {
    const renderedProfiles = [];
    this.state.business_profile_list.forEach((businessInfo) => {
      renderedProfiles.push(
        <BusinessProfileImage businessInfo={businessInfo} link={`/mtip/manager/${businessInfo.id}`}/>)
      })
    return (renderedProfiles);
  }

  updated = () => {
    this.props.refreshState()
    this.refreshData()
  }



  render() 
  {
    return  (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <Container>
              <Card>
                <CardHeader title="Registered Businesses" />
                <Divider />
                <Scrollbar>
                  <CardContent>
                    <Stack alignItems="center"  spacing={3}>
                    {this.renderBusinesses()}
                    </Stack>
                    {this.props.updated
                    ? (this.updated())
                    : null}
                  </CardContent>
                </Scrollbar>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Box>
    );
  }
}


export default withErrorHandling(MTipMyBusinesses);