import React, { Component } from "react";
// material
import { Stack, Button, Typography, } from '@material-ui/core';
import dayjs from 'dayjs';

import {withStyles} from "@material-ui/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import withErrorHandling from "../../hocs/withErrorHandling";
import { getServiceProfessionalReviewsReport, getBusinessReviewsReport } from "./scripts";
const styles = {
  table: {
    border: '1px solid #000', // Customize as needed
  },
};

class MTipReviewReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_business: (props.is_business) ? props.is_business : false,
      business_id: (props.business_id) ? props.business_id : "",
      isReportOpen: false
    };
  }
  
  
  componentDidMount () {
    this.refreshData()
  }
  
  handleReportToggle() {
    this.setState(prevState => ({
      isReportOpen: !prevState.isReportOpen
    }));
  }



  handleSuccesfulReportReturn_7days = (res) => {

    if (this.state.is_business) {   
      // this.getServiceProfessionalReviewsReport_7days();

      this.setState({ 
        seven_day_report: 
          [
            {
              Statistic:"Number of Tips",
              Value:res.data.total_amount
            },
            {
              Statistic:"Sum of Tips",
              Value: (res.data.sum_amount) ? res.data.sum_amount : 'N/A'
            },
          ],
          seven_day_service_professional_stats:res.data.stats
      })
    }
    else {
      this.setState({ 
        seven_day_report: 
          [
            {
              Statistic:"Number of Tips",
              Value:res.data.total_amount
            },
            {
              Statistic:"Sum of Tips",
              Value: (res.data.sum_amount) ? res.data.sum_amount : 'N/A'
            },
            {
              Statistic:"Personal Sum of Shared Tips",
              Value: (res.data.shared_sum_amount) ? res.data.shared_sum_amount : 'N/A'
            },
          ],
      })
    }
  }

  handleSuccesfulReportReturn_custom = (res) => {
    if (this.state.is_business) {   
      // this.getServiceProfessionalReviewsReport_7days();

      this.setState({ 
        custom_report: 
          [
            {
              Statistic:"Number of Tips",
              Value:res.data.total_amount
            },
            {
              Statistic:"Sum of Tips",
              Value: (res.data.sum_amount) ? res.data.sum_amount : 'N/A'
            },
          ],
          custom_service_professional_stats: res.data.stats,
      })
    }
    else {
      this.setState({ 
        custom_report: 
          [
            {
              Statistic:"Number of Tips",
              Value:res.data.total_amount
            },
            {
              Statistic:"Sum of Tips",
              Value: (res.data.sum_amount) ? res.data.sum_amount : 'N/A'
            },
            {
              Statistic:"Personal Sum of Shared Tips",
              Value: (res.data.shared_sum_amount) ? res.data.shared_sum_amount : 'N/A'
            },
          ],
      })
    }
  }

  setReportStartDate = (e) => {
    this.setState({ 
      report_start_date: e.unix(),
      start_date_set:true,
    });
  }
  setReportEndDate = (e) => {
    this.setState({ 
      report_end_date: e.unix(),
      end_date_set:true,
    });
  }

  refreshData = () =>
  {
    if (this.state.is_business) {   
      this.getBusinessReviewsReport_7days();
    }
    else {
      this.getServiceProfessionalReviewsReport_7days();
    }
  };

  getServiceProfessionalReviewsReport_7days = () => {
    const today = dayjs().unix();
    const sevenDaysAgo = dayjs().subtract(7, "day").unix()
    getServiceProfessionalReviewsReport(sevenDaysAgo,today,this.handleSuccesfulReportReturn_7days,this.props.handleFailure)
  }
  
  getServiceProfessionalReviewsReport_custom = () => {
    getServiceProfessionalReviewsReport(this.state.report_start_date, this.state.report_end_date, this.handleSuccesfulReportReturn_custom,this.props.handleFailure)
  }

  getBusinessReviewsReport_7days = () => {
    const today = dayjs().unix();
    const sevenDaysAgo = dayjs().subtract(7, "day").unix()
    getBusinessReviewsReport(sevenDaysAgo,today,this.state.business_id, this.handleSuccesfulReportReturn_7days,this.props.handleFailure)
  }
  
  getBusinessReviewsReport_custom = () => {
    getBusinessReviewsReport(this.state.report_start_date, this.state.report_end_date,this.state.business_id,  this.handleSuccesfulReportReturn_custom, this.props.handleFailure)
  }

  exportToCSV = (overall_data, service_professional_data) => {
    const csvRows = [];
    csvRows.push(['Martipia Reviews Report']);
    csvRows.push([]);
    const headers = Object.keys(overall_data[0]);
    csvRows.push(headers.join(','));

    overall_data.forEach(row => {
      const values = headers.map(header => {
        const escapedValue = String(row[header]).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    });

    service_professional_data.forEach(row => {

      csvRows.push([]);
      csvRows.push([row.service_professional_name]);
      const row_headers = Object.keys(row);
      const values = row_headers.map(header => {
        const escapedValue = String(row[header]).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(`"Number of Tips",${values[1]}`);
      csvRows.push(`"Sum of Tips",${values[2]}`);
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'martipia_reviews_report.csv';
    link.click();
    URL.revokeObjectURL(link.href);
  };

  render () {

    const { classes } = this.props;
    return  (
      <Stack direction="column" alignItems="center" spacing={2}>
        
          {(this.state.seven_day_report) 
          
            ? (
              
            <TableContainer component={Paper} className={classes.table}>
              <Stack alignItems="center">
                <Typography align='center' variant='body' >Last 7 days</Typography>
                <Button variant="contained" color="primary" onClick={() => this.handleReportToggle()}>
                  {this.state.isReportOpen ? 'Collapse Report' : 'Display Report'}
                </Button>
              </Stack>
              {this.state.isReportOpen && (
                <Table>
                  <TableBody>
                    {this.state.seven_day_report.map((row) => (
                      <TableRow
                        key={row.Statistic}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.Statistic}
                        </TableCell>
                        <TableCell align="right">
                          {row.Value}
                        </TableCell>
                      </TableRow>
                    ))}
                    {(this.state.is_business)
                    ? (this.state.seven_day_service_professional_stats.map((stat) => (
                      <React.Fragment>
                        <TableRow
                          key={stat.service_professional_name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row" colSpan={2}>
                            <b>{stat.service_professional_name}</b>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          key={stat.service_professional_name + ' - Number of Tips'}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {'Number of Tips'}
                          </TableCell>
                          <TableCell align="right">
                            {stat.count}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          key={stat.service_professional_name + ' - Sum of Tips'}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {'Sum of Tips'}
                          </TableCell>
                          <TableCell align="right">
                            {stat.sum_amount}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )))
                    : ("")
                    }
                    
                  </TableBody>
                </Table>
              )}
              <Stack alignItems="center">
              {(this.state.is_business)
                ? (
                  <Button variant="text" color="primary" size="small" onClick= { () => this.exportToCSV(this.state.seven_day_report, this.state.seven_day_service_professional_stats)} >
                    Download csv
                  </Button>
                )
                : (
                  <Button variant="text" color="primary" size="small" onClick= { () => this.exportToCSV(this.state.seven_day_report, [])} >
                    Download csv
                  </Button>
                )
              }
              </Stack>
            </TableContainer>
            )
            : (<Typography>No 7 day report data available.</Typography>)}
        
          <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="body2"><b>Custom Report.</b></Typography>
          <Typography variant="body2">Provide a date range to generate a tip report.</Typography>
            <Stack direction="row">
                {/* <MobileDatePicker disableFuture="true" label="Start" minDate={dayjs.unix(this.state.report_end_date).subtract(6, "month")} maxDate={dayjs.unix(this.state.report_end_date)} onChange = { (e) => this.setReportStartDate(e)}/>
                <MobileDatePicker disableFuture="true" minDate={dayjs.unix(this.state.report_start_date)} maxDate={dayjs.unix(this.state.report_start_date).add(6, 'month')} label="End" onChange = { (e) => this.setReportEndDate(e)}/> */}

                <MobileDatePicker disableFuture="true" label="Start" onChange = { (e) => this.setReportStartDate(e)}/>
                <MobileDatePicker disableFuture="true" label="End" onChange = { (e) => this.setReportEndDate(e)}/>
            </Stack>
            {(this.state.is_business)
              ? (
                <Button variant="outlined" color="secondary" disabled={!(this.state.start_date_set && this.state.end_date_set)} onClick= { () => this.getBusinessReviewsReport_custom()} >
                  Generate Report
                </Button>
              )
              : (
                <Button variant="outlined" color="secondary" disabled={!(this.state.start_date_set && this.state.end_date_set)} onClick= { () => this.getServiceProfessionalReviewsReport_custom()} >
                  Generate Report
                </Button>
              )
            }
        {(this.state.custom_report)   
          ? (
            <TableContainer component={Paper} className={classes.table}>
              <Stack alignItems="center">
                <Typography align='center' variant='body' >Custom Report</Typography>
              </Stack>
              <Table>
                <TableBody>
                  {this.state.custom_report.map((row) => (
                    <TableRow
                      key={row.Statistic}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.Statistic}
                      </TableCell>
                      <TableCell align="right">{row.Value}</TableCell>
                    </TableRow>
                  ))}
                  {(this.state.is_business)
                  ? (this.state.custom_service_professional_stats.map((stat) => (
                    <React.Fragment>
                    <TableRow
                      key={stat.service_professional_name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" colSpan={2}>
                        <b>{stat.service_professional_name}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key={stat.service_professional_name + ' - Number of Tips'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {'Number of Tips'}
                      </TableCell>
                      <TableCell align="right">
                        {stat.count}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key={stat.service_professional_name + ' - Sum of Tips'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {'Sum of Tips'}
                      </TableCell>
                      <TableCell align="right">
                        {stat.sum_amount}
                      </TableCell>
                    </TableRow>
                </React.Fragment>
                )))
                : ("")
                }
                </TableBody>
              </Table>
              <Stack alignItems="center">
              {(this.state.is_business)
                ? (
                  <Button variant="text" color="primary" size="small" onClick= { () => this.exportToCSV(this.state.custom_report, this.state.custom_service_professional_stats)} >
                    Download csv
                  </Button>
                )
                : (
                  <Button variant="text" color="primary" size="small" onClick= { () => this.exportToCSV(this.state.custom_report, [])} >
                    Download csv
                  </Button>
                )
              }
              </Stack>
            </TableContainer>
            )
          : ("")
        }
            </Stack>
      </Stack>
    );
  }
}

export default withErrorHandling(withStyles(styles)(MTipReviewReport));
