import {
  Navigate
} from "react-router-dom";
import React, { Component } from "react";
// material
import {Box, Grid, Card, CardContent, CircularProgress, Stack, Typography,} from '@material-ui/core';
// utils

import { withTranslation } from 'react-i18next';

import ImageCard from "../../../misc/ImageCard";

import { getProfileInfoPublic, getThankyouData } from "../scripts";
import ThankYouCard from "./ThankYouCard";
import MTipLogoAndModule from "../../../misc/MTipLogoAndModule";
import withErrorHandling from "../../../hocs/withErrorHandling";
// import Iframe from 'react-iframe'


class ThankYou extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      active_item: '',
      profile_info: '',
      modal:"",
      thank_you:"",
      id:props.id.id,
      redirect_url:"",
      profile_loading:true,
      thankyou_loading:true,
    };
  }
  
  componentDidMount () {
    this.getProfileInfoPublic(this.state.id)
  }

  getProfileInfoPublic = (code) => {
    getProfileInfoPublic(code, this.handleSuccessfulProfileRefresh,this.handleFailure)
    getThankyouData(code, this.handleSuccessuflThankyouRefresh, this.handleFailure)
  };

  
  handleSuccessfulProfileRefresh = (res) => {
    if (res.data.shared_qr) {
      this.setState({
        profile_info:res.data.business_info,
        shared_qr:true,
        profile_loading:false,
      });
    }
    else this.setState({
      shared_qr:false,
      profile_info: res.data.service_professional_info,
      profile_loading:false,
    });
    
  }
  
  
  handleSuccessuflThankyouRefresh = (res) => {
    this.setState({
      thankyou_card: res.data,
      thankyou_loading:false,
    });
    
  }
  
  handleFailure = (err) => {
      console.log(err)
  }

    
  buttonRedirect = () => {
    this.setState({
      redirect:this.state.redirect_url,
    })
  }

  render = () => {
    const { t } = this.props;
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return  (
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '89vh' }} >
          <Card>
            <CardContent>
              <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >    
                <MTipLogoAndModule module_name="Tip & Rate" logo_link="https://martipia.com"></MTipLogoAndModule>
                <Stack direction="column" spacing={0} justifyContent="space-evenly" alignItems="center">
                    <Typography>
                      {t('ThankYou_thank_you_text')}
                    </Typography>
                  {this.state.profile_loading
                    ? <CircularProgress size={100} thickness={20} />
                    :(
                      (this.state.shared_qr)
                      ? (
                        (this.state.profile_info.businessprofilepicture)
                            ? (
                                ImageCard(
                                this.state.profile_info.businessprofilepicture.file,
                                this.state.profile_info.name,
                                this.state.profile_info.description,
                                )
                              )
                            : (
                                ImageCard(
                                "/static/no_picture_business.png",
                                this.state.profile_info.name,
                                this.state.profile_info.description,
                                )
                              )
                        )
                      : (
                        (this.state.profile_info.serviceprofessionalpicture)
                            ? (
                                ImageCard(
                                this.state.profile_info.serviceprofessionalpicture.file,
                                this.state.profile_info.name,
                                this.state.profile_info.bio,
                                )
                              )
                            : (
                                ImageCard(
                                "/static/no_picture_person.png",
                                this.state.profile_info.name,
                                this.state.profile_info.bio,
                                )
                              )
                        )
                      )      
                  }
                  {this.state.thankyou_loading
                    ? <CircularProgress size={50} thickness={20} />
                    :(this.state.thankyou_card && <ThankYouCard thankyou_card={this.state.thankyou_card} />)
                  } 
                </Stack>
                
              </Grid>  
            </CardContent>
          </Card>
        </Grid>
      </Box>
    );
  }
}

export default withErrorHandling(withTranslation()(ThankYou));