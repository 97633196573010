import { Box, Typography, Container } from '@material-ui/core';

import Page from '../Page';

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Page title="MARTIPIA | Page not found">
      <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <Box
                component="img"
                src="/static/404.png"
                sx={{mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
          </Box>
              <Typography sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }} variant="h3" paragraph>
                Page not found!
              </Typography>
      </Container>
    </Page>
  );
}
