import React from "react";
import { Button, Stack, TextField, Box, CircularProgress} from '@material-ui/core';
import {requestResetPassword} from "./scripts"
import withErrorHandling from "../../hocs/withErrorHandling";

class ForgotPasswordForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        email:"",
        confirmed_email:"",
        data_provided:false,
        confirms_correct:true,
        emails_correct:true,
    }   
  }
  


  checkEmpty() {
    if (this.state.email && this.state.confirmed_email) {
      this.setState({
        data_provided:true
      })
    }
    else {
      this.setState({
        data_provided:false
      })
    }
  }

  checkConfirms() {
    if ((this.state.email!==this.state.confirmed_email)) {
      this.setState({
        confirms_correct : false,
        emails_correct:false,
      })
    }
    else {
      this.setState({
        emails_correct:true,
      })
    }
    
    if ((this.state.email===this.state.confirmed_email)) {
      this.setState({
        confirms_correct : true,
        })
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.state[name]=value
    this.checkEmpty()
    this.checkConfirms()
  }

  render() {
    return (
      <form onSubmit = { e => {
        this.props.setLoadingTrue()
        requestResetPassword(e, this.state, this.props.handleSuccess, this.props.handleFailure)
        }
      }>
            
        <Stack direction = 'column'>
          <TextField
            margin="dense"
            id="user-email"
            label="Email"
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
            error = {this.props.error}
            color = {this.props.error ? "error" : "primary"}
            helperText={this.props.error ? "Incorrect email provided." : ""}
            placeholder="Enter your email"
            required="true"
            />
          <TextField
            margin="dense"
            id="user-confirmed-email"
            label="Confirm Email"
            name="confirmed_email"
            type="email"
            value={this.state.confirmed_email}
            onChange={this.handleChange}
            error = {(this.props.error || !this.state.emails_correct) ? true : false}
            color = {this.props.error ? "error" : "primary"}
            helperText={(this.props.error || !this.state.emails_correct) ? (this.props.error ? "Incorrect email provided." : "Confirmed email doesn't match." ) : ""}
            placeholder="Confirm your email"
            required="true"
            />
        </Stack>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button 
              fullWidth
              type='submit'
              variant="contained" 
              color="primary" 
              disabled = {this.props.loading || !this.state.data_provided || !this.state.confirms_correct}
              >
              Submit
          </Button>
          {this.props.loading && (
              <CircularProgress
                  size={24}
                  sx={{
                  color: "primary",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  }}
            />)}
        </Box>
      </form>
    );
  }
}

export default withErrorHandling(ForgotPasswordForm);