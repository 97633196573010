
import { makeStyles } from '@material-ui/styles';

export const background_details_useStyles = makeStyles((theme) => ({
  root_class: {
    height: '100%',
    minHeight: '100vh', // Ensure at least viewport height
    overflow: 'auto', // Handle internal scrolling
    backgroundImage: `
    url('/static/background_details/background_details.png')
    `,
    backgroundRepeat: 'repeat',
    backgroundPosition: '0 0',
    backgroundSize: '594px 545px', // Adjust for more spacing
  },
}));

