import React, { Component } from "react";
import {Button, Grid, } from '@material-ui/core';
import { withTranslation } from 'react-i18next';


class TipAndRateEasyTip extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      redirect:false,
      easy_tip_profile:props.easy_tip_profile,
    };
  }
  


  redirectToEasyProfile = () => {
    const abs_link = (process.env.REACT_APP_ENVIRONMENT==='PRODUCTION') ? 'https://app.martipia.com/rate/' + this.state.easy_tip_profile : 'https://test.app.martipia.com/rate/' + this.state.easy_tip_profile;
    window.location.href = abs_link;
  }


  render = ()=> {

  const { t } = this.props;
  const isComponentActive = this.props.activeComponent === 'easy_tip_enabled';
    return (
      <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
        {(!(this.props.activeComponent) || !(this.props.activeComponent!=='easy_tip_enabled')) &&(
          <Button 
            onClick = {() => this.redirectToEasyProfile()} 
            variant="contained"
            fullWidth>
            {t('TipAndRateEasyTip_tip_button')}
          </Button>
        )}
      </Grid>
    );
  }

}

export default  withTranslation()(TipAndRateEasyTip);