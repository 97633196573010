import {Navigate} from "react-router-dom"
import React, { Component, createRef } from "react";
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import arrow_back from '@iconify/icons-eva/arrow-back-outline';
// material
import { Box, Avatar, Divider,Drawer,Stack, Button, Typography, IconButton } from '@material-ui/core';
import MTipLogo from "../../../misc/MTipLogo";

import LanguageToggle from "../../../../utils/languageToggle";
import { withTranslation } from 'react-i18next';

class MenuNavbar extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state = {
    };
    this.contentRef = createRef();
  };


  componentDidUpdate(prevProps) {
    // Only log the height if the drawer was newly opened to avoid excessive logging
    if (!prevProps.navbar_open && this.props.navbar_open) {
      this.logDrawerHeight();
    }
  }

  logDrawerHeight = () => {
    const height = this.contentRef.current.clientHeight;
    this.props.updateDrawerHeight(height);
  };

  render() {
    const { t, closeNavbar, navbar_open, categories, filterItems, active_category, i18n } = this.props;
    
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      
      <Drawer
        anchor="top"
        variant="persistent"
        open={navbar_open}
        onClose={closeNavbar}
        PaperProps={{
          elevation: 4, // Adjust elevation to control the depth of the shadow
          sx: {
            borderBottomLeftRadius: 8, // Adjust the value to your preference
            borderBottomRightRadius: 8, // Adjust the value to your preference
          },
        }}
      >
      <Stack ref={this.contentRef} direction="column" spacing={0.5}>
        
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack direction="row" sx={{ width: '100%', justifyContent: 'space-between', padding: 1 }} alignItems="center">
              <Box sx={{ width: '33.33%' }} >
                <Avatar alt={this.props.business_info.name} src={this.props.business_info.businessprofilepicture ? this.props.business_info.businessprofilepicture.file : "/static/no_picture_business.png"}/>
              </Box>

              <Box sx={{ width: '33.33%', display: 'flex', justifyContent: 'center' }}>
                  <MTipLogo sx={{ height: 35, width: 35 }} />
              </Box>

              {/* Right Section: Items aligned to the right */}
              <Box sx={{ width: '33.33%', display: 'flex', justifyContent: 'flex-end', gap: '10px', }}>
                <Button variant="contained" onClick={() => { this.setState({redirect:`/rate/${this.props.menu_info.business_profile_shared_qr}`})}}>
                  <Typography variant="body2">{t('TipAndRateTip_tip_label')}</Typography>
                </Button>
                <LanguageToggle></LanguageToggle>
              </Box>
            </Stack>
          </Box>
          <Divider />

          <Box display="flex" alignItems="center" width="100%">
            <IconButton onClick={closeNavbar} sx={{ zIndex: 1 }}>
              <Icon icon={arrow_back} width={20} height={20} />
            </IconButton>
            <Box sx={{  overflowX: 'auto', paddingRight: 2, paddingLeft: 2 }}>
              <Box sx={{ display: 'flex',  gap: 2, overflowX: 'auto' }}>
                {categories.map((categoryObj) => (
                  <Button
                    key={categoryObj.id}
                    onClick={() => filterItems(categoryObj)}
                    variant={active_category.id === categoryObj.id ? 'contained' : 'outlined'}
                    color='primary'
                    sx={{
                      minWidth: 100, // Ensures a minimum width for short names
                      flexGrow: 1,
                      margin: '0 8px', // Adjust spacing between buttons as needed
                    }}
                  >
                    <Typography variant="body2">
                      {categoryObj.name_translations && categoryObj.name_translations[i18n.language]
                      ? categoryObj.name_translations[i18n.language]
                      : categoryObj.name_default}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </Box>
          </Box>

        </Stack>
      </Drawer>
      );
    };
}

MenuNavbar.propTypes = {
    onOpenSidebar: PropTypes.func.isRequired
  };

  export default withTranslation()(MenuNavbar);