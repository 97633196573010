import {makeApiPostRequestAuthenticated, makeApiGetRequestAuthenticated} from '../../../utils/apiRequest';

export function checkLogin (handleLogin, handleFailedLogin) {
  makeApiGetRequestAuthenticated({
    url: '/api/current_user/',
  }, handleLogin, handleFailedLogin);
}


export function updateUserInfo (data, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_user/',
    data: data,
  }, handleSuccess, handleFailure);
}


export function updatePassword (data, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: `/api/change_password/`,
    data:{
      old_password1: data.old_password,
      old_password2: data.old_confirmed_password,
      new_password1: data.new_password,
      new_password2: data.new_confirmed_password,
    },
  }, handleSuccess, handleFailure);
}
