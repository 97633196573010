import { Navigate } from "react-router-dom";

import React from "react";
import {InputAdornment,IconButton, Button, Stack, TextField, Box, CircularProgress, Checkbox, Typography,} from '@material-ui/core';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {signUp} from "./scripts"
import { login_no_event } from "../login/scripts";
import withErrorHandling from "../../hocs/withErrorHandling";


class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            username: "",
            password: "",
            confirmed_password:"",
            first_name: "",
            last_name: "",
            email:"",
            confirmed_email:"",
            sign_up_code:"",
            terms_conditions_privacy_policy:"",
            showPassword:false,
            redirect:"",
            data_provided:false,
            confirms_correct:true,
            emails_correct:true,
            passwords_correct:true,
        }   
      }
    
    handleSignup = (res) => {
      login_no_event(this.state, this.handleLogin, this.props.handleFailure)
    }
    
    handleLogin = (res) => {
      this.props.handleSuccessNoMessage()
      localStorage.setItem('token',res.data.access);
      localStorage.setItem('refreshToken',res.data.refresh);
      this.setState({
        redirect:"/mtip",
      })
    }



    checkEmpty() {
      if (this.state.username && this.state.password && this.state.first_name && this.state.last_name && this.state.email && this.state.sign_up_code && this.state.terms_conditions_privacy_policy) {
        this.setState({
          data_provided:true
        })
      }
      else {
        this.setState({
          data_provided:false
        })
      }
    }

    checkConfirms() {
      if ((this.state.password!==this.state.confirmed_password)) {
        this.setState({
          confirms_correct : false,
          passwords_correct:false,
        })
      }
      else {
        this.setState({
          passwords_correct:true,
        })
      }

      if ((this.state.email!==this.state.confirmed_email)) {
        this.setState({
          confirms_correct : false,
          emails_correct:false,
        })
      }
      else {
        this.setState({
          emails_correct:true,
        })
      }

      if ((this.state.password===this.state.confirmed_password) && (this.state.email===this.state.confirmed_email)) {
        this.setState({
          confirms_correct : true,
        })
      }
    }

    handleCheckbox = e => {
      const name = e.target.name
      const value = e.target.checked
      this.state[name]=value
      this.checkEmpty()
      this.checkConfirms()
  }

    handleChange = e => {
      const name = e.target.name
      const value = e.target.value
      this.state[name]=value
      this.checkEmpty()
      this.checkConfirms()
  }

    showPassword = () => {
        this.setState({
            showPassword:!this.state.showPassword
        })
    }

    render() {
        if (this.state.redirect) {
          return <Navigate to={this.state.redirect} />
        }
        return (
            <form onSubmit = { e => {
              signUp(e, this.state,this.handleSignup, this.props.handleFailure)
              this.props.setLoadingTrue()
              }
            }>
              
                <Stack direction = 'column'>
                    <TextField
                      margin="dense"
                      id="user-username"
                      label="Username"
                      name="username"
                      type="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      error = {this.props.error}
                      color = {this.props.error ? "error" : "primary"}
                      helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                      placeholder="Enter your username"
                      required="true"
                      />
                    <TextField
                      margin="dense"
                      id="user-password"
                      label="Password"
                      name="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange}
                      error = {this.props.error}
                      color = {this.props.error ? "error" : "primary"}
                      helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                      placeholder="Enter your password"
                      required="true"
                      InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={this.showPassword} edge="end">
                                <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}/>
                    <TextField
                      margin="dense"
                      id="user-confirmed-password"
                      label="Confirm Password"
                      name="confirmed_password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.confirmed_password}
                      onChange={this.handleChange}
                      error = {(this.props.error || !this.state.passwords_correct) ? true : false}
                      color = {this.props.error ? "error" : "primary"}
                      helperText={(this.props.error || !this.state.passwords_correct) ? (this.props.error ? "Incorrect credentials or access code provided." : "Confirmed password doesn't match." ) : ""}
                      placeholder="Confirm your password"
                      required="true"
                      InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={this.showPassword} edge="end">
                                <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                      }}/>
                      <TextField
                        margin="dense"
                        id="user-first-name"
                        label="First name"
                        name="first_name"
                        type="text"
                        value={this.state.first_name}
                        onChange={this.handleChange}
                        error = {this.props.error}
                        color = {this.props.error ? "error" : "primary"}
                        helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                        placeholder="Enter your first name"
                        required="true"
                        />
                      <TextField
                        margin="dense"
                        id="user-last-name"
                        label="Last name"
                        name="last_name"
                        type="text"
                        value={this.state.last_name}
                        onChange={this.handleChange}
                        error = {this.props.error}
                        color = {this.props.error ? "error" : "primary"}
                        helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                        placeholder="Enter your last name"
                        required="true"
                        />
                      <TextField
                        margin="dense"
                        id="user-email"
                        label="Email"
                        name="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        error = {this.props.error}
                        color = {this.props.error ? "error" : "primary"}
                        helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                        placeholder="Enter your email"
                        required="true"
                        />
                      <TextField
                        margin="dense"
                        id="user-confirmed-email"
                        label="Confirm Email"
                        name="confirmed_email"
                        type="email"
                        value={this.state.confirmed_email}
                        onChange={this.handleChange}
                        error = {(this.props.error || !this.state.emails_correct) ? true : false}
                        color = {this.props.error ? "error" : "primary"}
                        helperText={(this.props.error || !this.state.emails_correct) ? (this.props.error ? "Incorrect credentials or access code provided." : "Confirmed email doesn't match." ) : ""}
                        placeholder="Confirm your email"
                        required="true"
                        />
                      <TextField
                        margin="dense"
                        id="user-sign-up-code"
                        label="Sign Up Code"
                        name="sign_up_code"
                        type="text"
                        value={this.state.sign_up_code}
                        onChange={this.handleChange}
                        error = {this.props.error}
                        color = {this.props.error ? "error" : "primary"}
                        helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                        placeholder="Enter your sign up code"
                        required="true"
                        />
                      <Typography>
                        Please accept the <a href="https://www.martipia.com/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="https://www.martipia.com/privacy-policy" target="_blank">Privacy Policy</a> to sign up.
                      </Typography>
                      <Checkbox
                        margin="dense"
                        id="user-terms_conditions_privacy_policy"
                        name="terms_conditions_privacy_policy"
                        checked={this.state.terms_conditions_privacy_policy}
                        onChange={this.handleCheckbox}
                        error = {this.props.error}
                        color = {this.props.error ? "error" : "primary"}
                        helperText={this.props.error ? "Incorrect credentials or access code provided." : ""}
                        required="true"
                        />
                </Stack>
                
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button 
                    fullWidth
                    type='submit'
                    variant="contained" 
                    color="primary" 
                    disabled = {this.props.loading || !this.state.data_provided || !this.state.confirms_correct}
                    >
                    Submit
                </Button>
                {this.props.loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "primary",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />)}
            </Box>
            </form>
        );
    }
}

export default withErrorHandling(RegisterForm);