import { Navigate } from "react-router-dom";
import React, { Component, createRef, } from "react";
import {Card,Divider, CardActionArea,Modal,CardHeader,CardMedia, Avatar, CardContent, Button, Box,  Stack, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import MTipLogo from "../../../misc/MTipLogo";

import LanguageToggle from "../../../../utils/languageToggle";
import LinkifyWrapper from "../../../wrappers/LinkifyWrapper";

import MartipiaMenuCategories from './MartipiaMenuCategories'
import withErrorHandling from '../../../hocs/withErrorHandling'

const modalStyle = {
  position: 'fixed', // Cover the entire screen
  top: 0,
  left: 0,
  width: '100vw', // Full viewport width
  height: '100vh', // Full viewport height
  bgcolor: 'rgba(0, 0, 0, 0.7)', // Optional: semi-transparent background for better focus on the image
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  overflow: 'hidden', // Hide overflow
};

const imageContainerStyle = {
  position: 'relative', // Needed for absolute positioning of the close button
  width: 'auto', // Allow the container to expand to the image size
  height: 'auto', // Allow the container to expand to the image size
  maxHeight: '100vh', // Ensure the container does not exceed the viewport height
  maxWidth: '100vw', // Ensure the container does not exceed the viewport width
  overflow: 'auto', // Enable scrolling within the container
};


// Adjusted Button styles for visibility and placement
const closeButtonStyle = {
  position: 'fixed', // Position relative to the viewport
  top: '2vh', // Slightly down from the top of the viewport
  right: '5vw', // Slightly in from the right edge of the viewport
  zIndex: 10, // Ensure it's above other content
  color: '#fff', // Text color for visibility
  backgroundColor: '#1976d2', // Use theme color or a visible color
  '&:hover': {
    backgroundColor: '#115293', // Darken on hover for feedback
  },
};
class MartipiaMenuFrontPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      banner_open: false,
    };
    this.itemsRef = createRef(); // Create a ref for scrolling purpose
  }

  toggleBanner = () => {
    this.setState((prevState) => ({ banner_open: !prevState.banner_open }));
  };

  render = ()=> {
    const { t,i18n, filterItems } = this.props;
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div>
        <Modal
          open={this.state.banner_open}
          onClose={this.toggleBanner}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
        {this.state.banner_open && (
          <Box sx={modalStyle}>
            <Box sx={imageContainerStyle}>
              <img
                src={this.props.menu_info.martipiamenubannerpicture.file}
                alt={this.props.business_info.name + ' banner picture.'}
                style={{
                  maxHeight: '90vh', // Max 80% of the viewport height
                  maxWidth: '90vw', // Max 80% of the viewport width
                  objectFit: 'contain', // Ensure the image is scaled to fit while maintaining its aspect ratio
                }}
              />
              <Button
                onClick={this.toggleBanner}
                sx={closeButtonStyle}
                variant="contained"
              >
                Close
              </Button>
            </Box>
          </Box>
        )}
        </Modal>
                
        <Card justifyContent='center' alignItems='center'>
        
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack direction="row" sx={{ width: '100%', justifyContent: 'space-between', padding: 1 }} alignItems="center">
            <Box sx={{ width: '33.33%' }} >
              <Typography variant="h6" fontSize={'0.8rem'}>MARTIPIA: Menu</Typography>
            </Box>

            <Box sx={{ width: '33.33%', display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                <MTipLogo sx={{ height: 35, width: 35 }} />
            </Box>

              {/* Right Section: Items aligned to the right */}
              <Box sx={{ width: '33.33%', display: 'flex', justifyContent: 'flex-end', gap: '10px', }}>
                <Button variant="contained" onClick={() => { this.setState({redirect:`/rate/${this.props.menu_info.business_profile_shared_qr}`})}}>
                  <Typography variant="body2">{t('TipAndRateTip_tip_label')}</Typography>
                </Button>
                <LanguageToggle></LanguageToggle>
            </Box>
          </Stack>
        </Box>
        <Divider />

          <Divider></Divider>
          <CardHeader 
            avatar={
              <Avatar alt={this.props.business_info.name} src={this.props.business_info.businessprofilepicture ? this.props.business_info.businessprofilepicture.file : "/static/no_picture_business.png"}/>
            }
          title={this.props.business_info.name}
          subheader={this.props.business_info.description}/>

          {this.props.menu_info.menu_banner_enabled && this.props.menu_info.martipiamenubannerpicture.file && (

          <CardActionArea onClick={() => this.toggleBanner()}>
            <CardMedia
              component="img"
              image={this.props.menu_info.martipiamenubannerpicture.file}
              sx={{ borderRadius: '16px' }}
              />
          </CardActionArea>
          )}
          <CardContent>
            <LinkifyWrapper>
              <Typography variant="h6" >
              {this.props.menu_info.menu_greetings_text_translations && this.props.menu_info.menu_greetings_text_translations[i18n.language]
              ? this.props.menu_info.menu_greetings_text_translations[i18n.language]
              : this.props.menu_info.menu_greetings_text_default}
              </Typography>
            </LinkifyWrapper>
            <LinkifyWrapper>
              <Typography variant="body2">
                {this.props.menu_info.menu_greetings_subtext_translations && this.props.menu_info.menu_greetings_subtext_translations[i18n.language]
                ? this.props.menu_info.menu_greetings_subtext_translations[i18n.language]
                : this.props.menu_info.menu_greetings_subtext_default}
              </Typography>
            </LinkifyWrapper>
            <Box mb={4} />
            <MartipiaMenuCategories categories={this.props.categories} filterItems={filterItems} />
          </CardContent>
        </Card>
          
      </div>
    )
  }
}

export default withErrorHandling(withTranslation()(MartipiaMenuFrontPage));