import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import GoogleReviewThankyouCardModalDetails from "./GoogleReviewThankyouCardModalDetails";


export default class BusinessProfileThankyouCardsDetailModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type,
      thankyou_data: props.thankyou_data,
      open: true,
    };
    
  }

  render = ()=> {
    const { toggleModal, submitThankyouData, toggleThankyouData } = this.props;
    return (  
      <div>
        <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Thank-you Card Details</DialogTitle>
            {(this.state.type==='google_reviews') ? <GoogleReviewThankyouCardModalDetails toggleThankyouData = {toggleThankyouData} thankyou_data = {this.state.thankyou_data} submitThankyouData = {submitThankyouData} toggleModal={toggleModal} /> : null}
            {/* {(this.state.type==='other_link') ? <IikoIntegrationModalDetails thankyou_data = {this.state.thankyou_data} submitThankyouData = {submitThankyouData} toggleModal = {toggleModal} /> : null}                       */}
        </Dialog>
      </div> 
    );
  }
}
