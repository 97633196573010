import { Navigate, Route, Routes } from 'react-router-dom';
// layouts
import MTipLayout from './layouts/internal/MTipLayout';
import LogoOnlyLayout from './layouts/external/LogoOnlyLayout';
import DemoLogoOnlyLayout from './layouts/external/DemoLogoOnlyLayout';
import NoLayout from './layouts/external/NoLayout';
//
import MTipManager from './pages/mtip-internal/MTipManager';
import MTipBusinessManager from './pages/mtip-internal/MTipBusinessManager';
import MTipWallet from './pages/mtip-internal/MTipWallet';
import MTipThankYou from './pages/rate/MTipThankYou';
import MTipRate from './pages/rate/MTipRate';
import MTipDemo from './pages/demo/MTipDemo';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Page404 from './pages/misc/Page404';
import MTipUser from './pages/mtip-internal/MTipUser';
import ContactRequest from './pages/misc/ContactRequest';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import MTipMenu from './pages/menu/MTipMenu';

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
      <Route path="/mtip" element= {<MTipLayout/> } >
        <Route path="" element ={<Navigate to="/mtip/wallet" replace/>} />
        <Route path="manager">
          <Route path="" element ={<MTipManager/>} />
          <Route path=":id"  element ={<MTipBusinessManager/>}/>
        </Route>
        <Route path="wallet" element ={<MTipWallet/>}/>
        <Route path="user" element ={<MTipUser/>}/>
      </Route>
      <Route path="/demo" element= {<DemoLogoOnlyLayout/> } >
        <Route path="" element ={<MTipDemo/>}/>
      </Route>
      <Route path="/rate" element= {<NoLayout/> } >
        <Route path=":id" element ={<MTipRate/>}/>
      </Route>
      <Route path="/menu" element= {<NoLayout/> } >
        <Route path=":id" element ={<MTipMenu/>}/>
      </Route>
      <Route path="/contact" element= {<NoLayout/> } >
        <Route path=":code" element ={<ContactRequest/>}/>
        <Route path="" element ={<ContactRequest/>}/>
      </Route>
      
      <Route path="/thankyou" element= {<NoLayout/> } >
        <Route path=":id" element ={<MTipThankYou/>}/>
      </Route>
      <Route path="/" element= {<LogoOnlyLayout/> } >
        <Route path="" element ={<Navigate to="/login/" replace/>} />
        <Route path="login" element ={<Login/>}/>
        <Route path="register" element ={<Register/>}/>
        <Route path="forgot_password" element ={<ForgotPassword/>}/>
        <Route path='*' element={<Page404 />} />
        <Route path="/reset_password/:uid/:token" element ={<ResetPassword/>}/>
      </Route>
    </Routes>
  );
}


