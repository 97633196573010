import React, { Component } from "react";
import { Avatar, Button,Stack, Typography } from '@material-ui/core';

class MartipiaMenuCategoryButton extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
    };
    
  }
    
  render = ()=> {
    const { doFunction, categoryObj, contained } = this.props;
    const _contained = contained ? contained : false;
    return (
      <Button 
        onClick={() => doFunction(categoryObj)}
        fullWidth
        variant={ _contained ? 'contained' : 'outlined'}
        color='primary'
          style={{
            borderWidth: '3px',
            borderRadius: '100%', // Makes the button circular
            width: '130px', // Equal to the height to make it circular
            height: '130px', // Equal to the width to make it circular
            shadow: 1, // Add shadow to make it look like a button
            boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.3)', // Add shadow to make it look like a button
          }}>
          <Stack direction={'column'} alignItems="center">
            {categoryObj.display_type==='picture' && categoryObj.martipiamenucategorypicture
            ? (
              <Avatar sx={{ width: 120, height: 120, bgcolor: 'transparent ' }} variant='circular' src={categoryObj.martipiamenucategorypicture.file}/>
            )
          : (
            <Avatar sx={{ width: 100, height: 100, bgcolor: 'transparent ' }} variant='rounded'>
              <Typography fontSize={'4rem'}>{categoryObj.emoji ? categoryObj.emoji : "📜"}</Typography>
            </Avatar>
          )}
           
          </Stack>
        </Button>
      )
    }
  }

export default MartipiaMenuCategoryButton;