import { Icon } from '@iconify/react';
import Rating from '@material-ui/lab/Rating';

import { withTranslation } from 'react-i18next';

import apple_logo from '@iconify/icons-ant-design/apple-filled';
import google_logo from '@iconify/icons-ant-design/google-circle-filled';

import question_circle from '@iconify/icons-ant-design/question-circle';
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Stack, Typography, InputAdornment, CircularProgress, Checkbox} from '@material-ui/core';
import { submitReview } from "../scripts";
import { isIOS, isMacOs} from 'react-device-detect';
import Tooltip from '@material-ui/core/Tooltip';
import withErrorHandling from '../../../hocs/withErrorHandling';

class TipAndRateTip extends Component {
  
  constructor(props) {
    super(props);
    const MARTIPIA_DEFAULT_TIP = 5
    const default_tip = (this.props.default_tip_info.default_tip_amount) ? this.props.default_tip_info.default_tip_amount : MARTIPIA_DEFAULT_TIP
    this.state = {
      tip_loaded:false,
      open: true,
      id:props.id,
      terms_conditions_privacy_policy:true,
      cover_comission:true,
      tip: {
        tip_type:((isIOS || isMacOs) && (process.env.REACT_APP_ENVIRONMENT==='PRODUCTION') ) ? "apple pay" : ((process.env.REACT_APP_ENVIRONMENT==='PRODUCTION')?"google pay":"card"),
        amount:"",
        display_amount:default_tip,
      },
      review: {
        comment: "",
        rating: "5",
        shared:props.shared,
      },
    }
  }


  handleSuccesfulSubmit = (res) => {
    this.props.setLoadingFalse();
    this.setState({
        redirect_url:res.data['url'],
        tip_loaded:true,
      }) 
    window.location.href=this.state.redirect_url
  }

  handleTipNumberChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const prevItem = this.state.tip;
    const newTip = { ...prevItem, [name]: value };
    const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        tip: newTip,
      });
    }
  }

  handleTipChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const prevItem = this.state.tip;
    const newTip = { ...prevItem, [name]: value };
    this.setState({
      tip: newTip,
    });
  };


  handleReviewChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const prevItem = this.state.review;
    const newReview = { ...prevItem, [name]: value };
    this.setState({
      review: newReview,
    });
  };

  
  submitReview = () => {   
    const _tip = this.state.tip
    if (this.state.cover_comission) {
      _tip.amount=(_tip.display_amount/0.921).toFixed(2)
    }
    else {
      _tip.amount=_tip.display_amount
    }
    this.props.setLoadingTrue();
    submitReview(this.state.review, this.state.tip, this.state.id, this.handleSuccesfulSubmit, this.props.handleFailure)
  }


  handleCheckbox = e => {
    const value = e.target.checked
    this.setState({
      terms_conditions_privacy_policy: value,
    });
  }

  handleComissionCheckbox = e => {
    const value = e.target.checked
    this.setState({
      cover_comission: value,
    });
  }

  render = ()=> {
    //constants
    const { t } = this.props;
    const coverTransactionCostText = t('TipAndRateTip_cover_transaction_cost_hover')
    const payment_options = ((isIOS || isMacOs) && (process.env.REACT_APP_ENVIRONMENT==='PRODUCTION')) 
        ?[
          {
            value:"apple pay",
            label:"Apple Pay",
            icon:<Icon icon={apple_logo} />
          },
          {
            value:"google pay",
            label:"Google Pay",
            icon:<Icon icon={google_logo} />
          },
          {
            value:"card",
            label:t('TipAndRateTip_payment_options_card_label'),
          }
        ]
        :( (process.env.REACT_APP_ENVIRONMENT==='PRODUCTION') 
          ?[
            {
              value:"google pay",
              label:"Google Pay",
              icon:<Icon icon={google_logo} />
            },
            {
              value:"card",
              label:t('TipAndRateTip_payment_options_card_label'),
            }
          ]
          :
          [
            {
            value:"card",
            label:t('TipAndRateTip_payment_options_card_label'),
            }
          ]
        )

    return (
      <Stack direction="column" alignItems="center" spacing={0.5}>
        <Stack direction="row">
          <TextField
              margin="dense"
              id="review-tip"
              label={t('TipAndRateTip_tip_label')}
              name="display_amount"
              type="number"
              fullWidth="true"
              InputProps={{
              startAdornment: <InputAdornment position="start">₾</InputAdornment>,
              }}
              value={this.state.tip.display_amount}
              onChange={this.handleTipNumberChange}
              />
          <TextField
              margin="dense"
              id="review-payment_type"
              label={t('TipAndRateTip_payment_options_label')}
              name="tip_type"
              select
              fullWidth="true"
              value={this.state.tip.tip_type}
              onChange={this.handleTipChange}
              > 
              {payment_options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                <Stack spacing={0.5} direction="row" alignItems="flex-end" justifyContent="flex-start">
                    <Typography variant="body" fontSize="0.7rem" >
                    {option.label} 
                    </Typography>
                    <Typography variant="body" fontSize="1.Orem" >
                    {option.icon  
                    ? option.icon
                    :""
                    }
                    </Typography>
                </Stack>
                </MenuItem>
              ))}
          </TextField>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly">
          <Typography variant="body">
              {t('TipAndRateTip_please_rate')}:
          </Typography>
          <Rating
              size="medium"
              name="rating"
              value={this.state.review.rating}
              onChange={this.handleReviewChange}
          />    
        </Stack>
        
        <TextField
        margin="dense"
        id="review-comment"
        label={t('TipAndRateTip_comment_label')}
        name="comment"
        type="text"
        value={this.state.review.comment}
        onChange={this.handleReviewChange}
        fullWidth="true"
        />

          {this.props.loading
            ? <CircularProgress size={36}/>
            :(this.state.tip_loaded
              ?(
                <Typography variant="body" fontSize="0.7rem" >
                  {t('TipAndRateTip_tip_redirect')}...<CircularProgress size={32}/>  
                </Typography>
              )
              :( 
                <Button fullWidth="true" size="medium" disabled = {!this.state.terms_conditions_privacy_policy} variant = 'contained' color="primary"  onClick= {() => { this.submitReview()}}>
                    <Typography variant="body" >
                      💸 {t('TipAndRateTip_tip_button')} 💸
                    </Typography>
                </Button>
              )
            )
          }

        <Stack direction="column" alignItems='flex-start' justifyContent="flex-start" spacing={0}>

          <Tooltip title={coverTransactionCostText}>
            <Stack direction="row" alignItems='center' >
              <Checkbox
                size="small"
                defaultChecked="true"
                name="cover_comission"
                onChange={this.handleComissionCheckbox}
                disabled={this.props.loading || this.state.tip_loaded}
                />
              <Typography noWrap='true' variant="caption" fontSize='0.7rem'>
                  {t('TipAndRateTip_cover_transaction_cost_text')} <Icon icon={question_circle}/>
              </Typography>
            </Stack>
          </Tooltip>

          <Stack direction="row" alignItems='center'>
            <Checkbox
              size="small"
              defaultChecked="true"
              name="terms_conditions_privacy_policy"
              onChange={this.handleCheckbox}
              disabled={this.props.loading || this.state.tip_loaded}
              />
            <Stack direction="column">
              <Typography variant="caption" fontSize='0.7rem'>
                  {t('TipAndRateTip_please_accept')}:
                  <a href="https://www.martipia.com/terms-and-conditions" target="_blank">{t('TipAndRateTip_toc')}</a>
                  ,
                  <a href="https://www.martipia.com/privacy-policy" target="_blank">{t('TipAndRateTip_privacy_policy')}</a>.

              </Typography>
            </Stack>
          </Stack>          
        </Stack>
      </Stack>
    );
  }
}

export default withErrorHandling(withTranslation()(TipAndRateTip));