import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import { Stack, Typography } from "@material-ui/core";
import BusinessProfilePlatformIntegrationsModal from "./BusinessProfilePlatformIntegrationsModal";


export default class BusinessProfileIntegrationsModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      integrations_info: props.integrations_info,
      open: true,
      platform_integrations_modal:false,
      platform_info:{},
      platform:"",
      // shared_list_info:"",
    };
  }

   openPlatformIntegrationModal = (e) => {
    var found = false
    for (const integration_info of this.state.integrations_info) { 
      if (integration_info.integrated_platform===e) {
        var integration_data = {
          ...integration_info.data, 
          ...{"id":integration_info.id},
          ...{"override_code":integration_info.override_code},
          ...{"override_integrations":integration_info.override_integrations},
        }
        this.setState({
          platform_info: integration_data,
          platform:e,

          // shared_list_info: integration_info.user_data_shared
        })
        found= true
      } 
    }
    if (!found) {
      this.setState({
        platform_info: {},
        platform:e,
      })
    }
    this.state["platform"]=e
    this.togglePlatformIntegrationsModal()
  }

  
  togglePlatformIntegrationsModal = () =>  {
    const prevModal = this.state.platform_integrations_modal;
    this.setState({ 
      platform_integrations_modal: !prevModal,
    });
  };


  render = ()=> {

  const { toggleModal, submitIntegrationsInfo} = this.props;
  return (
    <div>
    {/* {(this.state.platform_integrations_modal) ? <BusinessProfilePlatformIntegrationsModal platform = {this.state.platform} platform_info = {this.state.platform_info} shared_list_info={this.state.shared_list_info} submitIntegrationsInfo = {submitIntegrationsInfo} toggleModal = {this.togglePlatformIntegrationsModal}/> : null}   */}
      {(this.state.platform_integrations_modal) ? <BusinessProfilePlatformIntegrationsModal platform = {this.state.platform} platform_info = {this.state.platform_info} submitIntegrationsInfo = {submitIntegrationsInfo} toggleModal = {this.togglePlatformIntegrationsModal}/> : null}  
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Business Platform Integrations</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3} alignItems="center">
          <DialogContentText>
            If you use any of the below platforms, please provide the necessary details to integrate with the Martipia platform.
          </DialogContentText>
            <Stack>
              <Button onClick={ () => {this.openPlatformIntegrationModal("poster")}} color="primary"  variant="outlined">
                <Typography align="center" variant='h4'>      
                  Poster
                </Typography>
              </Button>
              <Typography align="center" variant='subtitle'>          
                <a href="https://joinposter.com/en" target="_blank">https://joinposter.com/en</a>
              </Typography>
            </Stack>
            <Stack>
              <Button onClick={ () => {this.openPlatformIntegrationModal("iiko")}} color="primary"  variant="outlined">
                <Typography align="center" variant='h4'>      
                  iiko
                </Typography>
              </Button>
              <Typography align="center" variant='subtitle'>          
                <a href="https://www.syrve.com/en-gb/" target="_blank">https://www.syrve.com/en-gb/</a>
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
