const english_translations = {
    'welcome': 'Welcome to our website',
    //TipAndRateTip
    'TipAndRateTip_tip_label':'Tip',
    'TipAndRateTip_tip_button':'Tip',
    'TipAndRateTip_tip_redirect':'Redirecting to the payment page',    
    'TipAndRateTip_comment_label':'Comments',
    'TipAndRateTip_payment_options_card_label':'Card',
    'TipAndRateTip_payment_options_label':'Payment Options',
    'TipAndRateTip_please_accept':'Please accept',
    'TipAndRateTip_toc':'Terms & Conditions',
    'TipAndRateTip_privacy_policy':'Privacy Policy',
    'TipAndRateTip_please_rate':'Please Rate',
    'TipAndRateTip_cover_transaction_cost_text':'I want to cover the transaction cost.',
    'TipAndRateTip_cover_transaction_cost_hover':'Check this box if you want to cover the 7.9% transaction fee for the tip.',

    //TipAndRateListCollapsable
    'TipAndRateListCollapsable_choose_employee':'Choose employee',
    'TipAndRateListCollapsable_staff_list_button_open':'🤔 Tip Employee 🔍',
    'TipAndRateListCollapsable_staff_list_button_close':'Close',

    //TipAndRateSplitTipCollapsable
    'TipAndRateSplitTipCollapsable_tip_box_button_open':'😊 Tip all employees 🤝',
    'TipAndRateSplitTipCollapsable_tip_box_button_close':'Close',

    //TipAndRateMenu
    'TipAndRateMenu_view_menu_button':'📋 Menu 🍽️',

    //TipAndRateProfile
    'TipAndRateProfile_profile_reviews':'Reviews',

    //TipAndRateEasyTip
    'TipAndRateEasyTip_tip_button':'😎 Tip 👌',

    //ThankYou
    'ThankYou_thank_you_text':'🙌 Thank you for tipping! 💚',
    
    //ThankYouCard
    'ThankYouCard_google_review_button':'Leave a Google review',
  }
export default english_translations;