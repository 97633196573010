import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Divider, Stack, Typography } from "@material-ui/core";
import { getBusinessProfileSettings, editBusinessProfileSettings } from "./scripts";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES } from "../../../../config/systemMessages";

class BusinessProfileSettingsModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      business_id:props.business_id,
      open: true,
    };
  }

  componentDidMount () {
    this.refreshData();
  }
  
  refreshData () {
    getBusinessProfileSettings(this.state.business_id, this.handleRefresh, this.props.handleFailure);
  }
  
  submitSettingsInfo = (id, data) => {
    editBusinessProfileSettings(id, data, this.handleRefresh, this.props.handleFailure);
    }

    handleSuccesfulSubmit = (res) => {
      this.props.handleSuccess(SUCCESS_RESPONSES.BUSINESS_PROFILE_SETTINGS_UPDATED);
      this.refreshData(res);
    }
    
  handleRefresh = (res) => {
    this.setState({
      business_settings_info: res.data,
    });
  } 

  handleChange = (e) => {
    let { value, name } = e.target;
    
    const prevItem = this.state.business_settings_info;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
        business_settings_info: activeItem,
    });
  };
  
  

  render = ()=> {

  const { toggleModal} = this.props;
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Business Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Configure the settings for your business.
          </DialogContentText>
          <Divider></Divider>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="body">
                <b>Default Tip Amount.</b>
              </Typography>
              <Typography>
              If left empty, the default tip amount will be 5.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                id="business-profile-settings-default-tip-amount"
                name="default_tip_amount"
                type="number"
                value={(this.state.business_settings_info) ? (this.state.business_settings_info.default_tip_amount) : null}
                onChange={this.handleChange}
                placeholder="Enter the Default Tip Amount"
                fullWidth
                />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick=
          { () =>
            {
              this.submitSettingsInfo(this.state.business_id, this.state.business_settings_info);
              toggleModal();
            }
          }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
export default withErrorHandling(BusinessProfileSettingsModal);