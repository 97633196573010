import React, { Component } from "react";
import { Navigate, Link as RouterLink } from 'react-router-dom';
import { Button, Box, Typography, Avatar, Stack, CircularProgress } from '@material-ui/core';
import {checkLogin} from "../../components/authentication/login/scripts"
import LanguageToggle from "../../utils/languageToggle"
import withErrorHandling from "../../components/hocs/withErrorHandling";
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
class AccountPopover extends Component {

  constructor (props) {
    super(props);

    this.state = {
      username: "",
      redirect:"",
    }
  }

    componentDidMount() {
      checkLogin(this.handleLogin,this.handleFailedLogin)    
    }

    handleLogin = (res) => {
      this.setState({
        username:  res.data.first_name
      });
    }

    handleFailedLogin= (err)=> {
      console.log(err)
      this.setState({
        redirect: "/login"
      });
    }


  render () {
    if (this.state.redirect){
      return <Navigate to={this.state.redirect} />
    }
  return (
    <>
    <Stack spacing={2} direction='row' alignItems="center">
        {/* <LanguageToggle></LanguageToggle> */}
        <Stack direction="column">
          <Avatar src='/static/avatar_default.png' variant ='rounded'/>
          <Typography align='center' variant="subtitle2" color='primary' noWrap>
                {(this.state.username)
                ? this.state.username
                :<CircularProgress/>}
          </Typography>
        </Stack>
        <Button size='small' component={RouterLink} to="/mtip/"  color="primary" variant="outlined" onClick = {()=> this.props.handleLogout() }>
          Logout
        </Button>
      </Stack>
    </>
  );
}
}

export default withErrorHandling(AccountPopover);
