import { useParams } from "react-router";

import React from "react";
// material
import { Container } from '@material-ui/core';
// components
import Page from '../Page';
import TipAndRatePage from '../../components/mtip_components/tipAndRate/tipAndRate/TipAndRatePage';


// ----------------------------------------------------------------------


export default function MTipRate() {
  let id = useParams();  
  return (
    <Page title="MARTIPIA | Tip and Rate">
      <Container maxWidth="xl">
        <TipAndRatePage id = {id}/>
      </Container>
    </Page>
  );
}
