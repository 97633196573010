

import React from "react"
import PropTypes from 'prop-types';
import { Stack, Card, Typography, CardContent, Link, CardMedia, Container} from '@material-ui/core';
import {Link as RouterLink} from "react-router-dom";


ImageCard.propTypes = {
  pictureInfo: PropTypes.object.isRequired,
  name: PropTypes.object.isRequired,
  bio: PropTypes.object.isRequired,
  link: PropTypes.object,
};

export default function ImageCard (pictureInfo, name, bio, link) {

  const _render = (
    <Container>
        <Stack direction = "column" alignItems = "center" justifyContent = "space-evenly">
          <Card>
          {(link)
          ?
            (
                <Link to={link} color="inherit" underline="hover" component={RouterLink}>
                  <CardMedia 
                  sx={{ 
                    width: 140, 
                    height: 150 
                  }}
                  image={pictureInfo}/>
                </Link>
            )
          :<CardMedia sx={{ width: 140, height: 150 }} image={pictureInfo}/>

          // :<CardMedia sx={{ width: 120, height: 160 }} image={pictureInfo}/>
          }
          </Card>
          <Typography variant='body'> 
            {name}
          </Typography>
          <Typography variant="body">
            <i>{bio}</i>
          </Typography>
        </Stack>
    </Container>  
  )
  return _render;
}