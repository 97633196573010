import { Link as RouterLink } from 'react-router-dom';
// material
import { useParams } from "react-router";
import { Box, Grid, Card, Stack, Link, Typography, CardContent } from '@material-ui/core';
// components
import Page from '../Page';
import ResetPasswordForm from '../../components/authentication/forgotPassword/ResetPasswordForm';

export default function Login() {  
  
  let {uid, token} = useParams();  
  return (
    <Page title="MARTIPIA | Reset Password">
      <Stack alignItems="center" justifyContent="center">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
              <Card>
                <CardContent>
                  <Stack  alignItems="center" justifyContent="center">
                    <Typography variant="body2">
                      Need an account? &nbsp;
                      <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
                        Register
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack  alignItems="center" justifyContent="center">
                    <Typography variant="body2">
                      Have an account? &nbsp;
                      <Link underline="none" variant="subtitle2" component={RouterLink} to="/login">
                        Login
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack  alignItems="center" justifyContent="center">
                    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                      MARTIPIA
                    </Typography>
                    <Box component="img" src="/static/Martipia_logo.png" sx={{ width: 330, height: 350}} />
                      <Typography variant="h4" gutterBottom>
                        Reset Password
                      </Typography>
                  </Stack>
                  <ResetPasswordForm uid={uid} token={token}/>
                  <Stack direction="column">
                    <Typography variant="caption">Support email: <font color="green">Davitk@martipia.com</font></Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Page>
  );
}
