import {
  Navigate
} from "react-router-dom";

import React from "react";
import {InputAdornment,IconButton, Button, Stack, TextField, Box, CircularProgress} from '@material-ui/core';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {login,} from "./scripts"
import withErrorHandling from "../../hocs/withErrorHandling";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            username: "",
            password: "",
            showPassword:false,
            redirect:"",
            password_provided:false,
            username_provided:false,
        }
      }

    
    handleLogin = (res) => {
      localStorage.setItem('token',res.data.access);
      localStorage.setItem('refreshToken',res.data.refresh);
      this.setState({
        redirect:"/mtip",
      })
      this.props.handleSuccess(res)
    }


    checkEmpty = () => {
      if (this.state.username && this.state.password) {
        this.setState({
          data_provided:true
        })
      }
      else {
        this.setState({
          data_provided:false
        })
      }
    }

    handleChange = e => {
        const name = e.target.name
        const value = e.target.value
        this.state[name]=value
        this.checkEmpty();
    }

    showPassword = () => {
        this.setState({
            showPassword:!this.state.showPassword
        })

    }

    render() {
        if (this.state.redirect) {
          return <Navigate to={this.state.redirect} />
        }
        return (
            
            <form onSubmit = { e => {
              this.props.setLoadingTrue()
              login(e, this.state,this.handleLogin, this.props.handleFailure)
              }
            }>
                <Stack direction = 'column'>
                    <TextField
                      margin="dense"
                      id="user-username"
                      label="Username"
                      name="username"
                      type="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      error = {this.props.error}
                      color = {this.props.error ? "error" : "primary"}
                      helperText={this.props.error ? "Incorrect credentials provided." : ""}
                      placeholder="Enter your username"
                      required="true"
                      />
                    <TextField
                      margin="dense"
                      id="user-password"
                      label="Password"
                      name="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={this.state.password}
                      onChange={this.handleChange}
                      error = {this.props.error}
                      color = {this.props.error ? "error" : "primary"}
                      helperText={this.props.error ? "Incorrect credentials provided." : ""}
                      placeholder="Enter your password"
                      required="true"
                      InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={this.showPassword} edge="end">
                                <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}/>
                </Stack>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <FormControlLabel
                        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                        label="Remember me"
                    />
                </Stack> */}
                
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button 
                    fullWidth
                    type='submit'
                    variant="contained" 
                    color="primary" 
                    disabled = {this.props.loading || !this.state.data_provided}
                    >
                    Submit
                </Button>
                {this.props.loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "primary",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />)}
            </Box>
            </form>
        );
    }
}

export default withErrorHandling(LoginForm);