import {Navigate} from "react-router-dom";
import React, { Component } from 'react';
import MessageModal from '../misc/modals/MessageModal';

// This is the HOC
function withMessageSuccessErrorModal(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        message_modal: false,
        message_modal_message: '',
        message_modal_header: '',
        
        success_modal: false,
        success_modal_message: '',
        success_modal_header: '',
        
        error_modal: false,
        error_modal_message: '',
        error_modal_header: '',

        loading_modal: false,
        loading_modal_message: '',
        loading_modal_header: '',
        
      };
      
      this.openMessageModalSuper = null;
      this.closeMessageModalSuper = null;

      this.openSuccessModalSuper = null;
      this.closeSuccessModalSuper = null;

      this.openErrorModalSuper = null;
      this.closeErrorModalSuper = null;

      this.openLoadingModalSuper = null;
      this.closeLoadingModalSuper = null;
    }
    // Message modal
    openMessageModal = () => {
      if (this.openMessageModalSuper) {
        this.openMessageModalSuper();
      }
      this.setState({
        message_modal: true,
      });
    };
    setMessageModalHeader = (header) => {
      this.setState({ message_modal_header: header });
    };
    setMessageModalMessage = (message) => {
      this.setState({ message_modal_message: message });
    };
    closeMessageModal = () => {
      if (this.closeMessageModalSuper) {
        this.closeMessageModalSuper();
      }
      this.setState({
        message_modal: false,
        message_modal_header: '',
        message_modal_message: '',
      });
    };
    registerCloseMessageModalSuper = (superFunction) => {
      this.closeMessageModalSuper = superFunction;
    };
    registerOpenMessageModalSuper = (superFunction) => {
      this.openMessageModalSuper = superFunction;
    };
        
    // Success modal
    openSuccessModal = () => {
      if (this.openSuccessModalSuper) {
        this.openSuccessModalSuper();
      }
      this.setState({
        success_modal: true,
      });
    };
    setSuccessModalHeader = (header) => {
      this.setState({ success_modal_header: header });
    };
    setSuccessModalMessage = (message) => {
      this.setState({ success_modal_message: message });
    };
    closeSuccessModal = () => {
      if (this.closeSuccessModalSuper) {
        this.closeSuccessModalSuper();
      } 
      this.setState({
        success_modal: false,
        success_modal_header: '',
        success_modal_message: '',
      });
      }
    registerCloseSuccessModalSuper = (superFunction) => {
      this.closeSuccessModalSuper = superFunction;
    };
    registerOpenSuccessModalSuper = (superFunction) => {
      this.openSuccessModalSuper = superFunction;
    };

    // Error modal
    openErrorModal = () => {
      if (this.openErrorModalSuper) {
        this.openErrorModalSuper();
      }
      this.setState({
        error_modal: true,
      });
    };
    setErrorModalHeader = (header) => {
      this.setState({ error_modal_header: header });
    };
    setErrorModalMessage = (message) => {
      this.setState({ error_modal_message: message });
    };
    closeErrorModal = () => {
      if (this.closeErrorModalSuper) {
        this.closeErrorModalSuper();
      }
      this.setState({
        error_modal: false,
        error_modal_header: '',
        error_modal_message: '',
        });
    };
    registerCloseErrorModalSuper = (superFunction) => {
      this.closeErrorModalSuper = superFunction;
    }
    registerOpenErrorModalSuper = (superFunction) => {
      this.openErrorModalSuper = superFunction;
    }

    // Loading modal
    openLoadingModal = () => {
      if (this.openLoadingModalSuper) {
        this.openLoadingModalSuper();
      }
      this.setState({
        loading_modal: true,
      });
    };
    setLoadingModalHeader = (header) => {
      this.setState({ loading_modal_header: header });
    };
    setLoadingModalMessage = (message) => {
      this.setState({ loading_modal_message: message });
    };
    closeLoadingModal = () => {
      if (this.closeLoadingModalSuper) {
        this.closeLoadingModalSuper();
      }
      this.setState({
        loading_modal: false,
        loading_modal_header: '',
        loading_modal_message: '',
      });
    }
    registerCloseLoadingModalSuper = (superFunction) => {
      this.closeLoadingModalSuper = superFunction;
    }
    registerOpenLoadingModalSuper = (superFunction) => {
      this.openLoadingModalSuper = superFunction;
    }

    // Session expired modal
    openSessionExpiredModal = (err) => {
      this.setState({
        session_expired_modal: true,
        session_expired_message: err.detail,
        session_expired_header: err.header,
      });
    }
    closeSessionExpiredModal = () => {
      this.setState({
        session_expired_modal: false,
        session_expired_header: '',
        session_expired_message: '',
        session_expired_redirect: '/login',
      });
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            // message modal
            openMessageModal={this.openMessageModal}
            setMessageModalHeader={this.setMessageModalHeader}
            setMessageModalMessage={this.setMessageModalMessage}
            closeMessageModal={this.closeMessageModal}
            // success modal
            openSuccessModal={this.openSuccessModal}
            setSuccessModalHeader={this.setSuccessModalHeader}
            setSuccessModalMessage={this.setSuccessModalMessage}
            closeSuccessModal={this.closeSuccessModal}            
            // error modal
            openErrorModal={this.openErrorModal}
            setErrorModalHeader={this.setErrorModalHeader}
            setErrorModalMessage={this.setErrorModalMessage}
            closeErrorModal={this.closeErrorModal}
            // loading modal
            openLoadingModal={this.openLoadingModal}
            setLoadingModalHeader={this.setLoadingModalHeader}
            setLoadingModalMessage={this.setLoadingModalMessage}
            closeLoadingModal={this.closeLoadingModal}
            // session expired modal
            openSessionExpiredModal={this.openSessionExpiredModal}
            closeSessionExpiredModal={this.closeSessionExpiredModal}
            // register functions
            registerCloseMessageModalSuper={this.registerCloseMessageModalSuper}
            registerOpenMessageModalSuper={this.registerOpenMessageModalSuper}
            registerCloseSuccessModalSuper={this.registerCloseSuccessModalSuper}
            registerOpenSuccessModalSuper={this.registerOpenSuccessModalSuper}
            registerCloseErrorModalSuper={this.registerCloseErrorModalSuper}
            registerOpenErrorModalSuper={this.registerOpenErrorModalSuper}
            registerCloseLoadingModalSuper={this.registerCloseLoadingModalSuper}
            registerOpenLoadingModalSuper={this.registerOpenLoadingModalSuper}
          />
          
          {this.state.message_modal && <MessageModal type="message" close={this.closeMessageModal} message={this.state.message_modal_message} header={this.state.message_modal_header}/>}
          {this.state.success_modal && <MessageModal type="success" close={this.closeSuccessModal} message={this.state.success_modal_message} header={this.state.success_modal_header}/>}
          {this.state.error_modal && <MessageModal type="error" close={this.closeErrorModal} message={this.state.error_modal_message} header={this.state.error_modal_header}/>}
          {this.state.loading_modal && <MessageModal type="loading" close={this.closeLoadingModal} message={this.state.loading_modal_message} header={this.state.loading_modal_header}/>}
          {this.state.session_expired_modal && <MessageModal type="error" close={this.closeSessionExpiredModal} message={this.state.session_expired_message} header={this.state.session_expired_header}/>}
          {this.state.session_expired_redirect && <Navigate to={this.state.session_expired_redirect} />}
        </>
      );
    }
  };
}

export default withMessageSuccessErrorModal;
