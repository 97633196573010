import React, { Component } from "react";
import { Typography, Stack, Container, Divider, Card, CardHeader, CardContent, Button } from '@material-ui/core';
import {getMartpiaPlusSubscriptionInfo, getBusinessData, enableMartipiaPlusLicense, removeMartipiaPlusLicense, getThankyouDataBusiness, createThankyouData} from "./scripts"; 
import BusinessProfileThankyouCardsModal from "../../businessProfile/businessProfileThankyouCards/BusinessProfileThankyouCardsModal";
import ActionCheckModal from "../../../misc/modals/ActionCheckModal";
import withErrorHandling from "../../../hocs/withErrorHandling";


class BusinessProfileMartipiaPlus extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      id:props.id.id,
      message_modal:false,
      thankyou_data_modal:false,
      thankyou_data:"",
    };
  }

  componentDidMount ()
  {
      this.refreshData();
  }
  
  handleSuccessfulRefresh = (res) => {
    this.setState({
      martipia_plus_subscription_enabled: res.data['martipia_plus_subscription_enabled'],
    });
  }

  
  handleSuccessfulSubscriptionRefresh = (res) => {
    this.setState({
      martipia_subscription_info: res.data,
    });
  }
  

  refreshData = () =>
  {
    getMartpiaPlusSubscriptionInfo(this.handleSuccessfulSubscriptionRefresh, this.props.handleFailure)
    getBusinessData(this.state.id, this.handleSuccessfulRefresh, this.props.handleFailure)
  };

  enableLicense = () => {
    enableMartipiaPlusLicense(this.state.id, this.refreshData, this.props.handleFailure)
  }
  removeLicense = () => {
    this.removeLicenseCheck()
    removeMartipiaPlusLicense(this.state.id, this.refreshData, this.props.handleFailure)
  }
  
  removeLicenseCheck = () => {
    const prevModal = this.state.disable_license_modal;
    this.setState({
      disable_license_modal:!prevModal,
    })
  }

  toggleThankyouDataModal = () =>
  {
    const prevModal = this.state.thankyou_data_modal;
    this.setState({ 
      thankyou_data_modal: !prevModal,
    });
  };

  viewThankyouData = () => {
    getThankyouDataBusiness(this.state.id, this.handleSuccessfulThankyouDataGet, this.props.handleFailure)
   };

   handleSuccessfulThankyouDataGet = (res) => {
     this.setState({
       thankyou_data: res.data,
      })
    this.toggleThankyouDataModal()
  }

  submitThankyouData = (thankyou_card_type, thankyou_card_data) => {
    createThankyouData(this.state.id, thankyou_card_type, thankyou_card_data, this.handleSuccessfulThankyouDataGet, this.props.handleFailure)
  }

  render() 
  {
    return  (
      <Container>
        <Card >
          <CardHeader title="⚙️ Martipia Plus ➕" />
          <Divider />
          <CardContent>
          {(this.state.disable_license_modal) ? <ActionCheckModal toggle={this.removeLicenseCheck} message = "Are you sure you want to disable the license for this business?" action={this.removeLicense} /> : ""}
          {(this.state.thankyou_data_modal) ? <BusinessProfileThankyouCardsModal thankyou_data = {this.state.thankyou_data} submitThankyouData = {this.submitThankyouData} toggleModal = {this.toggleThankyouDataModal}/> : null}  

            <Stack alignItems="center" spacing={2}>
              <Typography align="center" variant="h5"> Premium features for Martipia Plus subscribers. </Typography>
              {this.state.martipia_subscription_info
                ?(
                  this.state.martipia_plus_subscription_enabled
                  ? (
                    <Stack spacing={1}>
                      <Typography variant="body"> Martipia Plus Subscription Enabled! 🎉</Typography>
                      <Button 
                        variant="outlined"
                        color="secondary"
                        onClick= { () => this.viewThankyouData()}
                        >
                          Thank-you Card Settings
                      </Button>
                      <Button 
                        onClick={ () => {this.removeLicenseCheck()}}
                        variant="outlined"
                        color="error"
                        >Disable Martipia Plus License
                      </Button>
                    </Stack>
                  )
                  :(
                    <Stack spacing={1}>
                      <Typography variant="body"> No subscription licenses are currently enabled for this business, but {this.state.martipia_subscription_info.licenses} licenses are available. </Typography> 
                      <Button 
                        onClick={ () => {this.enableLicense()}}
                        variant="outlined"
                        >Enable Martipia Plus License
                      </Button>
                  </Stack>
                  )
                )
                :<Typography variant="body"> No subscription licenses are currently enabled for this business. Please contact davitk@martipia.com if interested. </Typography>
              }
            </Stack>
          </CardContent>
        </Card>
      </Container>

    );
  }
}
export default withErrorHandling(BusinessProfileMartipiaPlus);