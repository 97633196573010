import React, { Component } from "react";
import {  Grid, Stack, Typography } from '@material-ui/core';
import MartipiaMenuCategoryButton from "./MartipiaMenuCategoryButton";
import { withTranslation } from 'react-i18next';


class MartipiaMenuCategories extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      categories:props.categories,
    };
    
  }
    
  render = ()=> {
    const {i18n, filterItems } = this.props;
    
    return (
      <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 4 }} justifyContent="space-between" alignItems="center">
        {this.state.categories.map((categoryObj) => (
          <Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={categoryObj.id}>
          <Stack direction={'column'} alignItems="center" justifyContent='center'>
            <MartipiaMenuCategoryButton doFunction={filterItems} categoryObj={categoryObj}/>
            <Typography align='center' variant="body1">
              <b>
                {categoryObj.name_translations && categoryObj.name_translations[i18n.language]
                ? categoryObj.name_translations[i18n.language]
                : categoryObj.name_default}
              </b>
            </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default withTranslation()(MartipiaMenuCategories);