import { Navigate } from "react-router-dom";
import React, { Component } from "react";
import {Button, Grid, } from '@material-ui/core';
import { withTranslation } from 'react-i18next';


class TipAndRateMenu extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      redirect:false,
      menu_link:props.menu_link,
      menu_type:props.menu_type,
      shared_qr_code:props.shared_qr_code,
    };
  }
  


  redirectToMenu = () => {
    const abs_link = this.state.menu_link.startsWith('http')  ? this.state.menu_link : 'https://' + this.state.menu_link;
    window.location.href = abs_link;
  }


  redirectToCustomMenu = () => {
    this.setState({
      redirect:`/menu/${this.state.shared_qr_code}`,
    })
  }


  render = ()=> {

  const { t } = this.props;
  const isComponentActive = this.props.activeComponent === 'menu_enabled';
  if (this.state.redirect) {
    return <Navigate to={this.state.redirect} />;
  }
    return (
      <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
        {(!(this.props.activeComponent) || !(this.props.activeComponent!=='menu_enabled')) &&(

          this.state.menu_type === 'link' && (
          <Button 
            onClick = {() => this.redirectToMenu()} 
            variant="contained"
            fullWidth>
            {t('TipAndRateMenu_view_menu_button')}
          </Button>
          )
        )}
        {(!(this.props.activeComponent) || !(this.props.activeComponent!=='menu_enabled')) &&(
            this.state.menu_type === 'custom' && (
            <Button 
              onClick = {() => this.redirectToCustomMenu()} 
              variant="contained"
              fullWidth>
              {t('TipAndRateMenu_view_menu_button')}
            </Button>
            )
            )}
      </Grid>
    );
  }

}

export default  withTranslation()(TipAndRateMenu);