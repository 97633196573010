import React, { Component,  } from "react";
import Button from '@material-ui/core/Button';
import {MenuItem} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class WithdrawalDestinationInfoModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      active_item: props.active_item,
      open: true,
      institution_choices: [
        {
          value: "Bank",
          label:" Bank",
        },
      ]
    };
  }

  handleChange = (e) => {
    let { value, name } = e.target;
    
    const prevItem = this.state.active_item;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
      active_item: activeItem,
    });
  };



  render = ()=> {

  const { toggleModal, submitWithdrawalInfo } = this.props;
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Withdrawal Destination Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your information.
          </DialogContentText>  
          <TextField
            autoFocus
            margin="dense"
            id="withdrawalDestination-destination_recipient_full_name"
            label="Full Name"
            name="destination_recipient_full_name"
            type="text"
            value={this.state.active_item.destination_recipient_full_name}
            onChange={this.handleChange}
            placeholder="Enter the legal Full Name"
            fullWidth
          />          
          <TextField
            autoFocus
            margin="dense"
            id="withdrawalDestination-destination_institution_type"
            label="Destination Institution Type"
            name="destination_institution_type"
            select
            value={this.state.active_item.destination_institution_type}
            onChange={this.handleChange}
            placeholder="Enter the Destination Institution Type. EX: Bank."
            fullWidth
          > 
            {this.state.institution_choices.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoFocus
            margin="dense"
            id="withdrawalDestination-destination_institution_code"
            label="Destination Institution Code"
            name="destination_institution_code"
            type="text"
            value={this.state.active_item.destination_institution_code}
            onChange={this.handleChange}
            placeholder="Destination Institution Code, if any. EX: BAGAGE22GTS for BoG."
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="withdrawalDestination-destination_recipient_institution_identifier"
            label="Destination Recipient Institution Identifier"
            name="destination_recipient_institution_identifier"
            type="text"
            value={this.state.active_item.destination_recipient_institution_identifier}
            onChange={this.handleChange}
            placeholder="Recipient Institution Identifier. EX: Account Number."
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="withdrawalDestination-destination_recipient_government_identifier"
            label="Destination Recipient Government Identifier"
            name="destination_recipient_government_identifier"
            type="text"
            value={this.state.active_item.destination_recipient_government_identifier}
            onChange={this.handleChange}
            placeholder="Recipient Government Identifier. EX: Tax Identifier Number (TIN)."
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick= 
          {() => {
            submitWithdrawalInfo(this.state.active_item);
            }
          }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
