const russian_translations = {
  'welcome': 'Welcome to our website',
  //TipAndRateTip
  'TipAndRateTip_tip_label':'Чаевые',
  'TipAndRateTip_tip_button':'Оставить чаевые',
  'TipAndRateTip_tip_redirect':'Перенаправление на страницу оплаты',    
  'TipAndRateTip_comment_label':'Комментарии',
  'TipAndRateTip_payment_options_card_label':'Карта',
  'TipAndRateTip_payment_options_label':'Способы оплаты',
  'TipAndRateTip_please_accept':'Пожалуйста, примите',
  'TipAndRateTip_toc':'условия использования',
  'TipAndRateTip_privacy_policy':'политика конфиденциальности',
  'TipAndRateTip_please_rate':'Пожалуйста, оцените',
  'TipAndRateTip_cover_transaction_cost_text':'Я хочу дополнительно оплатить комиссию сервиса.',
  'TipAndRateTip_cover_transaction_cost_hover':'Установите этот флажок, если хотите покрыть комиссию за транзакцию в размере 7,9% за чаевые.',
  
  //TipAndRateListCollapsable
  'TipAndRateListCollapsable_choose_employee':'🤔 Выберите сотрудника 🔍',
  'TipAndRateListCollapsable_staff_list_button_open':'🤔 Чаевые Сотруднику 🔍',
  'TipAndRateListCollapsable_staff_list_button_close':'Закрыть',


  //TipAndRateSplitTipCollapsable
  'TipAndRateSplitTipCollapsable_tip_box_button_open':'😊 Чаевые всем сотрудникам 🤝',
  'TipAndRateSplitTipCollapsable_tip_box_button_close':'Закрыть',

  //TipAndRateMenu
  'TipAndRateMenu_view_menu_button':'📋 Меню 🍽️',


  //TipAndRateProfile
  'TipAndRateProfile_profile_reviews':'Отзыв(а/ов)',
  //TipAndRateEasyTip
  'TipAndRateEasyTip_tip_button':'😎 Чаевые 👌',

  
  //ThankYou
  'ThankYou_thank_you_text':'🙌 Спасибо за чаевые! 💚',
  //ThankYouCard
  'ThankYouCard_google_review_button':'Оставить Google Review',
}
export default russian_translations;