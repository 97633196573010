import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import {CardMedia, Card, Switch, Stack, Typography, TextField,FormControl, Select,MenuItem, InputLabel, OutlinedInput, Chip  } from "@material-ui/core";
import {deleteMenuItem, copyMenuItem, changeMenuItem, uploadItemPicture } from "./menu_api";
import DeleteModal from "../../../misc/modals/DeleteModal";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES} from "../../../../config/systemMessages";
import ActionCheckModal from "../../../misc/modals/ActionCheckModal";


class MartipiaMenuItemModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      item:props.item,
      categories:props.categories,
      tags:props.tags,
      business_id:props.business_id,
      menu_id:props.menu_id
    };
  }


  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  
  handleHideChange = () => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        hidden: !prevState.item.hidden
      }
    }));
  };
  
  uploadItemPicture = (event) => {
    const myFile = event.target.files[0]
    const formData = new FormData()
    formData.append("file", myFile)
    formData.append("business",this.state.business_id)
    formData.append("menu_id",this.state.menu_id)
    formData.append("item_id",this.state.item.id)

    if (!this.state.item.martipiamenuitempicture)  
    { 
      this.props.handleLoading()
      uploadItemPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
    }
    else
    {
      formData.append("id",this.state.item.martipiamenuitempicture.id)
      this.props.handleLoading()
      uploadItemPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
    }
  };

  handleSuccessfulPictureUpload = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_ITEM_PICTURE_UPLOAD_SUCCESS);
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        martipiamenuitempicture: res.data,
      },
    }));
  }

  handleSelectCategoryChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value,
      },
    }));
  };

    handleSelectTagChange = (event) => {
      const { value } = event.target;
      // Assuming `name` is fixed and known, as your component structure suggests using `tag`
      const name = "tags";
    
      // Adjusting to handle an array. If `value` is a string (from a single select scenario), it converts it to an array.
      // For multiple select, `value` should already be an array.
      const selectedValues = typeof value === 'string' ? value.split(',') : value;
    
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: selectedValues, // Now `tag` will store an array of selected tags
        },
      }));
    };
    
  handleChangeTranslation = (e) => {
    const { name, value } = e.target;
    // Splitting based on the period as a delimiter
    const [translationField, languageCode] = name.split('.');
  
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [translationField]: {
          ...prevState.item[translationField],
          [languageCode]: value,
        },
      },
    }));
  };
    
  changeMenuItem = () => {
    changeMenuItem(this.state.business_id, this.state.menu_id, this.state.item, this.handleSuccessfulSubmit, this.props.handleFailure);
  };
  
  handleSuccessfulSubmit = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_ITEM_EDIT_SUCCESS);
    this.props.refreshData()
    this.setState({
      item: res.data
    })
  }


  handleSuccessfulItemCopy = (res) => {
    this.props.handleSuccessfulItemCopy();
    this.props.refreshData();
    this.props.toggleModal();
  }
  
  deleteCheck = (item) => {
    const prevModal = this.state.delete_modal;
    this.setState({
      delete_modal:!prevModal,
      item_to_delete:item,
      delete_message: "Are you sure you want to delete this item?"
    })
  }

  copyCheck = (item) => {
    const prevModal = this.state.copy_check_modal;
    this.setState({
      copy_check_modal:!prevModal,
      copy_item_id:item,
      action_message: "Are you sure you want to create a copy of this item? It will be added as a new item to the menu."
    })
  }

  copyMenuItem = () => {
    copyMenuItem(this.state.business_id, this.state.menu_id, this.state.copy_item_id, this.handleSuccessfulItemCopy, this.props.handleFailure)
  }



  deleteMenuItem = () => {
    deleteMenuItem(this.state.business_id, this.state.menu_id, this.state.item_to_delete, this.handleSuccesfulDelete, this.props.handleFailure)
    this.deleteCheck("")
  };

  handleSuccesfulDelete = () => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_ITEM_DELETE_SUCCESS);
    this.props.refreshData()
    this.props.toggleModal()
  }

  render = ()=> {

  const { toggleModal} = this.props;

  return (
    
    <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Item</DialogTitle>
      <DialogContent>
      {(this.state.delete_modal) ? <DeleteModal toggle={this.deleteCheck} deleteAction={this.deleteMenuItem} message={this.state.delete_message} /> : ""}
      {(this.state.copy_check_modal) && <ActionCheckModal toggle={this.copyCheck} message = {this.state.action_message} action={this.copyMenuItem} /> }


        <Stack direction="column" spacing={3} alignItems="center">
          <DialogContentText>
          Edit your item. You may provide a name, picture, price, category, and tags for the item. You can upload the picture after you save the item for the first time.
          </DialogContentText>
          <Stack justifyContent={"flex-start"} spacing={1}>

            <Typography align={"left"} variant='subtitle'>          
              Hide item from the menu.
            </Typography>
              <Switch
              checked={this.state.item.hidden || false}
              onChange={this.handleHideChange}
              name="hidden"
              color="primary"  
            />
            <Typography align={"left"} variant="body2">
            Please enter the item name. If you don't have a translation, it will be used as the default.
            </Typography>
              <TextField
              margin="dense"
              fullWidth
              label="Item Name"
              name="name_default"
              type="text"
              value={this.state.item.name_default || ""}
              onChange={this.handleChange}
              placeholder="Item Name"
            >
            </TextField>

            
            <Typography align={"left"} variant="body2">
              Please enter the translations for the item name.
            </Typography>
            <TextField
              margin="dense"
              fullWidth
              label="Georgian Item Name"
              name="name_translations.ka"
              type="text"
              value={this.state.item.name_translations?.ka || ""}
              onChange={this.handleChangeTranslation }
              placeholder="Georgian translation"
            />
            <TextField
              margin="dense"
              fullWidth
              label="English Item Name"
              name="name_translations.en" // Notice the dot notation
              type="text"
              value={this.state.item.name_translations?.en || ""}
              onChange={this.handleChangeTranslation}
              placeholder="English translation"
            />
            <TextField
              margin="dense"
              fullWidth
              label="Russian Item Name"
              name="name_translations.ru"
              type="text"
              value={this.state.item.name_translations?.ru || ""}
              onChange={this.handleChangeTranslation }
              placeholder="Russian translation"
            />

            <Typography align={"left"} variant="body2">
              Please enter the item position. 
            </Typography>
            <Typography align={"left"} variant="body2">
            <b>1</b> means it will be displayed first, <b>2</b> - second, etc.
            </Typography>
            <Stack direction = 'row' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="Item Position"
                name="order_position"
                type="number"
                value={this.state.item.order_position || ""}
                onChange={this.handleChange}
                placeholder="Item Position"
              >
              </TextField>
            </Stack>
            
            {this.state.item.id && (

              <Stack>
                <Typography align={"left"} variant='subtitle'>
                  Upload item image.
                </Typography>
                <Button
                variant="outlined"
                component="label"
                >
                  Upload
                  <input
                    type="file"
                    hidden
                    onChange = {(e) => this.uploadItemPicture(e)}
                    />
                </Button>
              
                {this.state.item.martipiamenuitempicture && <Card 
                    sx={{ maxWidth: 600,}}>
                  <CardMedia
                    component="img"
                    height="280"
                    image={this.state.item.martipiamenuitempicture.file}
                    alt={this.state.item.name_default}
                    />
                  </Card>
                }
            </Stack>
            )}

            
          <Typography align={"left"} variant="body2">
              Please enter the item price.
            </Typography>
              <TextField
                margin="dense"
                fullWidth
                label="Item Price"
                name="price"
                type="number"
                value={this.state.item.price || ""}
                onChange={this.handleChange}
                placeholder="Item Price"
              >
              </TextField>

            <Typography align={"left"} variant="body2">
              Please enter the item description. If you don't have a translation, it will be used as the default.
            </Typography>
              <TextField
                margin="dense"
                fullWidth
                label="Item Description"
                name="description_default"
                type="text"
                value={this.state.item.description_default || ""}
                onChange={this.handleChange}
                placeholder="Item Description"
              >
              </TextField>
            
            <Typography align={"left"} variant="body2">
              Please enter the translations for the item description.
            </Typography>
            <TextField
              margin="dense"
              fullWidth
              label="Georgian Item Description"
              name="description_translations.ka"
              type="text"
              value={this.state.item.description_translations?.ka || ""}
              onChange={this.handleChangeTranslation }
              placeholder="Georgian translation"
            />
            <TextField
              margin="dense"
              fullWidth
              label="English Item Description"
              name="description_translations.en" // Notice the dot notation
              type="text"
              value={this.state.item.description_translations?.en || ""}
              onChange={this.handleChangeTranslation}
              placeholder="English translation"
            />
            <TextField
              margin="dense"
              fullWidth
              label="Russian Item Description"
              name="description_translations.ru"
              type="text"
              value={this.state.item.description_translations?.ru || ""}
              onChange={this.handleChangeTranslation }
              placeholder="Russian translation"
            />

            <Typography align={"left"} variant="body2">
              Please enter the item category.
            </Typography>
            <FormControl fullWidth>
              <Select
                value={this.state.item.category}
                onChange={this.handleSelectCategoryChange}
                name="category"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {this.state.categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name_default}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography align={"left"} variant="body2">
              Please enter the item tag.
            </Typography>
              <FormControl fullWidth>
                <InputLabel id="multiple-tag-label">Tag</InputLabel>
                <Select
                  labelId="multiple-tag-label"
                  multiple
                  value={this.state.item.tags || []}
                  onChange={this.handleSelectTagChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Tag" />}
                  renderValue={(selected) => (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {this.state.tags.map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

          </Stack>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="outlined"
            color="error" 
            disabled={!this.state.item.id}
            onClick= { () => this.deleteCheck(this.state.item)}
            >
              Delete Item
          </Button>
          <Button 
            variant="outlined"
            color="inherit" 
            disabled={!this.state.item.id}
            onClick= { () => this.copyCheck(this.state.item)}
            >
              Create a Copy
          </Button>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button 
            type='submit'
            variant="text" 
            color="secondary" 
            onClick={() => this.changeMenuItem()}
            >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withErrorHandling(MartipiaMenuItemModal);