import {InputAdornment,IconButton} from '@material-ui/core';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class UserPasswordModal extends Component {
  
  

  constructor(props) {
    super(props);

    this.state = {
      active_item:"",
      open: true,
      showPassword:false,
      old_password: "",
      old_confirmed_password:"",
      new_password: "",
      new_confirmed_password:"",
      old_passwords_correct:true,
      new_passwords_correct:true,
      confirms_correct:true,
    };
  }

  showPassword = () => {
    this.setState({
        showPassword:!this.state.showPassword
    })
  }


  checkEmpty() {
    if (this.state.old_password && this.state.old_confirmed_password && this.state.new_password && this.state.new_confirmed_password) {
      this.setState({
        data_provided:true
      })
    }
    else {
      this.setState({
        data_provided:false
      })
    }
  }

  checkConfirms() {
    if ((this.state.old_password!==this.state.old_confirmed_password)) {
      this.setState({
        old_passwords_correct:false,
        confirms_correct : false,
      })
    }
    else {
      this.setState({
        old_passwords_correct:true,
      })
    }

    if ((this.state.new_password!==this.state.new_confirmed_password)) {
      this.setState({
        new_passwords_correct:false,
        confirms_correct : false,
      })
    }
    else {
      this.setState({
        new_passwords_correct:true,
      })
    }
    
    if ((this.state.old_password===this.state.old_confirmed_password) && (this.state.new_password===this.state.new_confirmed_password)) {
      this.setState({
        confirms_correct : true,
        })
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.state[name]=value
    this.checkEmpty()
    this.checkConfirms()
  }



  render = ()=> {

  const { togglePasswordModal, submitPassword } = this.props;
  return (
    <div>
      <Dialog open={this.state.open} onClose={togglePasswordModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">User Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your information.
          </DialogContentText>  
          <TextField
            autoFocus
            margin="dense"
            id="user-old_password"
            label="Old Password"
            name="old_password"
            error = {!this.state.old_passwords_correct ? true : false}
            helperText={(!this.state.old_passwords_correct) ? "Old passwords don't match." : ""}
            value={this.state.old_password}
            onChange={this.handleChange}
            placeholder="Enter your old password"
            fullWidth
            type={this.state.showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={this.showPassword} edge="end">
                    <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />    
          <TextField
            autoFocus
            margin="dense"
            id="user-old_confirmed_password"
            label="Confirm Old Password"
            name="old_confirmed_password"
            error = {!this.state.old_passwords_correct ? true : false}
            helperText={(!this.state.old_passwords_correct) ? "Old passwords don't match." : ""}
            value={this.state.old_confirmed_password}
            onChange={this.handleChange}
            placeholder="Confirm your old password"
            fullWidth
            type={this.state.showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={this.showPassword} edge="end">
                    <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />  
          <TextField
            autoFocus
            margin="dense"
            id="user-new_password"
            label="New Password"
            name="new_password"
            error = {!this.state.new_passwords_correct ? true : false}
            helperText={(!this.state.new_passwords_correct) ? "New passwords don't match." : ""}
            value={this.state.new_password}
            onChange={this.handleChange}
            placeholder="Enter your new password"
            fullWidth
            type={this.state.showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={this.showPassword} edge="end">
                    <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />  
          <TextField
            autoFocus
            margin="dense"
            id="user-new_confirmed_password"
            label="Confirmed New Password"
            name="new_confirmed_password"
            error = {!this.state.new_passwords_correct ? true : false}
            helperText={(!this.state.new_passwords_correct) ? "New passwords don't match." : ""}
            value={this.state.new_confirmed_password}
            onChange={this.handleChange}
            placeholder="Confirm your new password"
            fullWidth
            type={this.state.showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={this.showPassword} edge="end">
                    <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          /> 
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={togglePasswordModal}
            color="primary"
            >
            Cancel
          </Button>
          <Button 
            color="secondary"
            disabled = {!this.state.data_provided || !this.state.confirms_correct}
            onClick= 
            {() => {
              submitPassword(this.state);
              }
          }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
