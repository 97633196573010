import React from "react";


import PropTypes from 'prop-types';
import {Divider, Container, Card, CardHeader, CardContent, Stack, Button,} from '@material-ui/core';


import ServiceProfessionalProfile from "../serviceProfessionalProfile/ServiceProfessionalProfile";
import RegisterWithBusinessForm from "./RegisterWithBusinessForm";

ServiceProfessionalProfile_wallet.propTypes = {
  serviceProfessionalProfileInfo: PropTypes.object.isRequired,
  registerWithBusiness: PropTypes.func.isRequired,
  editServiceProfessionalProfile: PropTypes.func.isRequired,
  deleteServiceProfessionalProfile: PropTypes.func.isRequired,
  quitBusiness: PropTypes.func.isRequired,
  uploadServiceProfessionalPicture: PropTypes.func.isRequired,
  viewIntegrations: PropTypes.func.isRequired,
};


export default function ServiceProfessionalProfile_wallet (serviceProfessionalProfileInfo, registerWithBusiness, editServiceProfessionalProfile, deleteServiceProfessionalProfile, quitBusiness, uploadServiceProfessionalPicture, viewIntegrations) {
  const bItem = (
    <Container>
      <Card >
      <CardHeader title="🌰 Martipia Profile 🐿️" />
        <Divider />
        <CardContent>
          <Stack direction = "column" spacing = {1}>
            {ServiceProfessionalProfile(
              serviceProfessionalProfileInfo
            )}
            <Stack spacing={3} alignItems="center">
              <Stack direction="column" spacing={1} alignItems="center">
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    component="label"
                    >
                    Upload Picture
                    <input
                      type="file"
                      hidden
                      onChange = {(e) => uploadServiceProfessionalPicture(e, serviceProfessionalProfileInfo.id, serviceProfessionalProfileInfo.serviceprofessionalpicture)}
                      />
                  </Button>
                  <Button  variant="outlined" color="secondary" onClick= { () => editServiceProfessionalProfile(serviceProfessionalProfileInfo)} >
                    Edit
                  </Button>
                </Stack>
                <Button 
                  variant="outlined"
                  color="secondary"
                  onClick= { () => viewIntegrations()}
                  >
                    Integrations
                </Button>
                {(serviceProfessionalProfileInfo.business)
                ?(
                  <Button  variant="outlined" color="error" onClick= { () => quitBusiness()} >
                    Quit business
                  </Button>
                )
                :null}
              </Stack>
                <Button  variant="outlined" color="error" onClick= { () => deleteServiceProfessionalProfile(serviceProfessionalProfileInfo)} >
                  Delete Profile
                </Button>
              <RegisterWithBusinessForm registerWithBusiness = {registerWithBusiness}/>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
  return bItem;
}

