import { makeApiPostRequestAnonymous, makeApiGetRequestAuthenticated } from "../../../utils/apiRequest";

// Authentication API
export function login(e, input_data, handleSuccess, hanndleFailure) {
    // Event handling.
    e.preventDefault();
    const data = {}
    data['username']=input_data.username
    data['password']=input_data.password
  makeApiPostRequestAnonymous({
    url: '/api/token-auth/',
    headers: {
      "Content-Type" : 'application/json',
    },
    data: data,
  }, handleSuccess, hanndleFailure);
}

export function login_no_event( input_data, handleSuccess, hanndleFailure) {
  // Event handling.
  const data = {}
  data['username']=input_data.username
  data['password']=input_data.password
makeApiPostRequestAnonymous({
  url: '/api/token-auth/',
  headers: {
    "Content-Type" : 'application/json',
  },
  data: data,
}, handleSuccess, hanndleFailure);
}

export function checkLogin (handleSuccess, hanndleFailure) {
  makeApiGetRequestAuthenticated({
    url: '/api/current_user/',
  }, handleSuccess, hanndleFailure);
}
