// LanguageContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from './i18n'; // make sure to import your i18n configuration

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    // Listen for language changes in i18next
    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup the listener when the component is unmounted
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};