import {
  useParams
} from "react-router";

import React from "react";
// material

import {Grid,  Stack, Box, Typography, Card, CardContent, CardHeader} from '@material-ui/core';
// components
import Page from '../Page';

import ContactForm from "../../components/misc/modals/ContactForm";


// ----------------------------------------------------------------------


export default function ContactRequest() {
  let code = useParams();  
  return (
    <Page title="MARTIPIA | Contact us">
      <Stack alignItems="center" justifyContent="center">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <Card>
              <CardHeader title="Contact us" />
                <CardContent>
                  <Stack alignItems="center" justifyContent="center">
                    <Typography variant="h4" >
                    {/* <Typography variant="h4" sx={{ px: 5, mt: 10, mb: 0 }}> */}
                      <a href='https://martipia.com'>MARTIPIA</a>
                    </Typography>
                    <Typography variant="h6">
                      Contact & Registration request form.
                    </Typography>
                    <Box component="img" src="/static/Martipia_logo.png" sx={{ width: 70, height: 70}} />
                  </Stack>
                  <Stack  alignItems="center" justifyContent="center">
                    <ContactForm code = {code}/> 
                  </Stack>
                  <Stack direction="column">
                    <Typography variant="caption">Support email: <font color="green">Davitk@martipia.com</font></Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Page>
  );
}
