import {makeApiPostRequestAuthenticated, makeApiGetRequestAuthenticated} from '../../../utils/apiRequest';

// Service Professional API
export function getProfileInfo (handleSuccess,handleFailure) {
  makeApiGetRequestAuthenticated({
    url : '/api/get_service_professional_info/',
  }, handleSuccess, handleFailure);
}


export function submitProfileInfo(item, handleSuccess, handleFailure)  {
  if (item.id){
    makeApiPostRequestAuthenticated({
      url: "/api/update_service_professional/",
      data: item,
    }, handleSuccess, handleFailure);
  }
  else{
    makeApiPostRequestAuthenticated({
      url: "/api/create_service_professional/",
      data: item,
    }, handleSuccess, handleFailure);
  }
}

export function deleteServiceProfessionalProfile (item, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: "/api/delete_service_professional/",
    data: item,
  }, handleSuccess, handleFailure);
}

export function uploadServiceProfessionalPicture (formData, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: "/api/upload_service_professional_picture/",
    data: formData,
  }, handleSuccess, handleFailure);
}


// Withdrawal API

export function getWithdrawalDestinationInfo (handleSuccess, handleFailure) {
  makeApiGetRequestAuthenticated({
    url : '/api/get_withdrawal_info/',
  }, handleSuccess, handleFailure);
}

export function submitWithdrawalDestinationInfo (item, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: "/api/update_withdrawal_info/",
    data: item,
  }, handleSuccess, handleFailure);
}

export function deleteWithdrawalDestinationInfo (item, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: "/api/delete_withdrawal_info/",
    data: item,
  }, handleSuccess, handleFailure);
}

export function withdrawTips (amount, handleSuccess, handleFailure)  {
  makeApiPostRequestAuthenticated({
    url: `/api/request_withdrawal/`,
    data: {
      "amount":amount
    },
  }, handleSuccess, handleFailure);
}

export function toggleAutoWithdrawal ( handleSuccess, handleFailure)  {
  makeApiPostRequestAuthenticated({
    url: `/api/toggle_auto_withdrawal/`,
  }, handleSuccess, handleFailure);
}

// Reviews API

export function getReviewsData( handleSuccess, handleFailure) {
  makeApiGetRequestAuthenticated({
    url : `/api/get_last_10_service_professional_reviews/`,
  }, handleSuccess, handleFailure);
}

export function getServiceProfessionalHighestTip(handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: `/api/get_service_professional_highest_tip/`,
  }, handleSuccess, handleFailure);
}

// Integrations API

export function getIntegrationsInfo (id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url : `/api/get_service_professional_integrations/`,
    data: {
      id:id
    },
  }, handleSuccess, handleFailure);
}

export function submitIntegrationsInfo (service_professional_id, platform, item, handleSuccess, handleFailure) {
  const {id, ...restOfItem } = item;
  makeApiPostRequestAuthenticated({
    url : `/api/update_service_professional_integrations/`,
    data: {
      "data":restOfItem,
      "id":id,
      "service_professional":service_professional_id,
      "integrated_platform":platform,
    },
  }, handleSuccess, handleFailure);
}

export function quitBusiness (handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: "/api/service_professional_quit_business/",
  }, handleSuccess, handleFailure);
}



//Misc API

export function registerWithBusiness (data, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/register_with_business/',
    data: data,
  }, handleSuccess, handleFailure);
}