
import React from "react";

import PropTypes from 'prop-types';
// material
import { Button, Stack, Typography,} from '@material-ui/core';


UserProfile.propTypes = {
  userInfo: PropTypes.object.isRequired,
  editUserInfo: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

export default function UserProfile (userInfo,editUserInfo,changePassword) {
  const pItem = (
        <Stack direction = "row" spacing={10}>
          <Stack direction = "column" spacing={1}>
           <Stack direction="column" alignItem="center" spacing={1}>
             <Typography variant="subtitle2" >
                First Name:
              </Typography>  
              <Typography variant="body2" >
                {userInfo.first_name}
               </Typography>
            </Stack>
            <Stack direction="column" alignItem="center" spacing={1}>
              <Typography variant="subtitle2" >
                Last Name:
              </Typography>  
              <Typography variant="body2" >
                {userInfo.last_name}
              </Typography>
            </Stack>
            <Stack direction="column" alignItem="center" spacing={1}>
              <Typography variant="subtitle2" >
                Username:
              </Typography>  
              <Typography variant="body2" >
                {userInfo.username}
              </Typography>
            </Stack>
            <Stack direction="column" alignItem="center" spacing={1}>
              <Typography variant="subtitle2" >
                Email:
              </Typography>  
              <Typography variant="body2" >
                {userInfo.email}
              </Typography>
            </Stack>
            <Button 
            variant="outlined"
            onClick={editUserInfo}
            fullWidth={false}>
              Change User Info
            </Button>
          </Stack> 
          <Stack direction="column" alignItem="flex-end" spacing={1}>
            <Typography variant="subtitle2" >
              Password
            </Typography>  
            <Button 
            variant="outlined"
            onClick={changePassword}>
              Change Password
            </Button>
          </Stack>
        </Stack>  
    );
    return pItem;
  }