import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class ServiceProfessionalProfileModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      active_item: props.active_item,
      open: true,
      accepted_needed:props.accepted_needed,   
    };
  }

  handleChange = (e) => {
    let { value, name } = e.target;
    
    const prevItem = this.state.active_item;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
      active_item: activeItem,
    });
  };

  
  
  handleCheckbox = e => {
    const name = e.target.name
    const value = e.target.checked

    const prevItem = this.state.active_item;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
      active_item: activeItem,
    });
}

  render = ()=> {

  const { toggleModal, submitProfileInfo } = this.props;
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Martipia Pro Profile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your information.
          </DialogContentText>  
          <TextField
            autoFocus
            margin="dense"
            id="serviceProfessionalProfile-name"
            label="Name"
            name="name"
            type="text"
            value={this.state.active_item.name}
            onChange={this.handleChange}
            placeholder="Enter the name you'd like your customers to see"
            fullWidth
          />  
          <TextField
            autoFocus
            margin="dense"
            id="serviceProfessionalProfile-role"
            label="Role"
            name="role"
            type="text"
            value={this.state.active_item.role}
            onChange={this.handleChange}
            placeholder="Enter the role"
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="serviceProfessionalProfile-bio"
            label="Bio"
            name="bio"
            type="text"
            value={this.state.active_item.bio}
            onChange={this.handleChange}
            placeholder="Enter the bio"
            fullWidth
          />
          {this.state.accepted_needed
          ? (
            <Typography align="center">
              Please accept the <a href="https://www.martipia.com/agreement-with-clients-personnel" target="_blank">Service Professional Registration Agreement</a>.
              <Checkbox
              margin="dense"
              id="serviceProfessionalProfile-accepted"
              name="accepted"
              checked={this.state.active_item.accepted}
              onChange={this.handleCheckbox}
              required="true"
              />
            </Typography>
            )
          : ""}
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick= 
          {() => {
            submitProfileInfo(this.state.active_item);
            }
          }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
