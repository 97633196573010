import React, { Component } from "react";
// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../Page';

import {
  MTipUserView,
} from '../../components/mtip_components/mtipUser';


// ----------------------------------------------------------------------
export default class MTipUser extends Component {
  constructor(props)
  {
    super(props);
  };

  render () {
    return (
      <Page title="MARTIPIA | User Settings">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">User Settings</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
              <MTipUserView />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }

}
