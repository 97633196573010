
import React, { Component } from "react";
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CircularProgress, Stack, Typography } from "@material-ui/core";

export default class MessageModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      type:props.type
    }
  };
  
  render = ()=> {

  const { close,open, message, header, type} = this.props;
  // Backup header
  let backup_header = "Message";
  if (type === "error") {
    backup_header = "Error";
  }
  else if (type === "success") {
    backup_header = "Success";
  }
  else if (type === "message") {
    backup_header = "Info";
  }
  else if (type === "loading") {
    backup_header = "Loading";
  }
  else {
    backup_header = "Message";
  }

  // Backup message
  let backup_message = "";
  if (type === "error") {
    backup_message = "An error occurred. Please try again later."
  }
  else if (type === "success") {
    // Probably not needed
    // backup_message = "Success!"
    backup_message = ""
  }
  else if (type === "message") {
    backup_message = "Info"
  }
  else if (type === "loading") {
    backup_message = "Please wait a moment."
  }
  else {
    backup_message = ""
  }
  // Backup color
  let color = 'primary';
  if (type === "error") {
    color = 'error';
  }
  else if (type === "success") {
    color = 'success';
  }
  else if (type === "message") {
    color = 'info';
  }
  else {
    color = 'primary';
  }

  return (
    <div>
      <Dialog open={this.state.open} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{header ? header :backup_header}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack direction='column' alignItems={'center'} spacing={1}>
              <Typography>
                {message ? message : backup_message}
              </Typography>
              {type === "loading" && <CircularProgress size={24} sx={{color: "primary"}}/>}
            </Stack>
          </DialogContentText>          
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
            <Button onClick={close} color={color}>
              OK
            </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }

}