import React from "react";
// material
import { Box,Container,} from '@material-ui/core';
// components
import Page from "../Page";

import DemoProfile from '../../components/mtip_components/tipAndRate/demo/DemoProfile';



export default function MTipDemo() {
  return (
    <Page title="MARTIPIA | Martipia Demo">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
        </Box>
        <DemoProfile/>
      </Container>
    </Page>
  );
}

