import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class UserProfileModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      active_item: props.active_item,
      open: true,
    };
  }

  handleChange = (e) => {
    let { value, name } = e.target;
    
    const prevItem = this.state.active_item;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
      active_item: activeItem,
    });
  };


  render = ()=> {

  const { toggleModal, submitUserInfo } = this.props;
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">User Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your information.
          </DialogContentText>  
          <TextField
            autoFocus
            margin="dense"
            id="user-first_name"
            label="First Name"
            name="first_name"
            type="text"
            value={this.state.active_item.first_name}
            onChange={this.handleChange}
            placeholder="Enter your First Name"
            fullWidth
          />  
          <TextField
            autoFocus
            margin="dense"
            id="user-last_name"
            label="Last Name"
            name="last_name"
            type="text"
            value={this.state.active_item.last_name}
            onChange={this.handleChange}
            placeholder="Enter your Last Name"
            fullWidth
          />  
          <TextField
            autoFocus
            margin="dense"
            id="user-username"
            label="Username"
            name="username"
            type="text"
            value={this.state.active_item.username}
            onChange={this.handleChange}
            placeholder="Enter your Username"
            fullWidth
          />  
          <TextField
            autoFocus
            margin="dense"
            id="user-email"
            label="Email"
            name="email"
            type="email"
            value={this.state.active_item.email}
            onChange={this.handleChange}
            placeholder="Enter your Email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick= 
          {() => {
            submitUserInfo(this.state.active_item);
            }
          }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
