import React from "react";
import QRCode from "react-qr-code";


import PropTypes from 'prop-types';
// material
import {  Stack,  Typography, } from '@material-ui/core';

import BusinessProfileImage from "./BusinessProfileImage";

BusinessProfile.propTypes = {
  businesssInfo: PropTypes.object.isRequired,
};



export default function BusinessProfile (businessInfo) {

  const bItem = (
    <Stack direction="column" spacing={1} alignItems="center">
      <BusinessProfileImage businessInfo={businessInfo}/>
      <Stack direction = "column" justifyContent="flex-start"> 
      
        <Stack direction="column">
          <Typography variant="subtitle2" >
          Business Code:
          </Typography>  
          <Typography variant="caption" >
            {businessInfo.id}
          </Typography>
        </Stack>
        <Stack direction="row" alignItem="center" spacing={1}>
          <Typography variant="subtitle2" >
            Name:
          </Typography>  
          <Typography variant="subtitle2" >
            {businessInfo.name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItem="center" spacing={1}>
          <Typography variant="body2" >
            Type:
          </Typography>  
          <Typography variant="body2" sx={{ color: 'text.secondary' }} >
            {businessInfo.business_type}
          </Typography>
        </Stack>
        <Stack direction="row" alignItem="center" spacing={1}>
          <Typography variant="body2" >
            Number of employees:
          </Typography>  
          <Typography variant="body2" sx={{ color: 'text.secondary' }} >
            {businessInfo.number_of_employees}
          </Typography>
        </Stack>
        <Stack direction="row" alignItem="center" spacing={1} >
          <Typography variant="body2" >
            Description:
          </Typography>  
          <Typography variant="body2" sx={{ color: 'text.secondary' }} >
            {businessInfo.description}
          </Typography>
        </Stack>
        <Stack direction="row" alignItem="center" spacing={1}>
          <Typography variant="body2" >
            Location:
          </Typography>  
          <Typography variant="body2" sx={{ color: 'text.secondary' }} >
            {businessInfo.location}
          </Typography>
        </Stack>
        <Stack direction="row" alignItem="center" spacing={1}>
          <Typography variant="body2" >
            Rating:
          </Typography>  
          <Typography variant="body2" sx={{ color: 'text.secondary' }} >
            {parseFloat(businessInfo.rating).toFixed(2)}
          </Typography>
        </Stack>
      </Stack>  
    </Stack>
  );
  return bItem;
}

