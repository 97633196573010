import React from "react";
import PropTypes from 'prop-types';
import { Rating, Stack, Typography} from '@material-ui/core';
import { fDateTimeSuffix } from '../../../utils/formatTime';


  MTipReview.propTypes = {
    item: PropTypes.object
  };
  
  

  export default function MTipReview(item) {
      return (
        <Stack direction="column">
          <Stack direction="row" spacing={2}>
            <Rating
              size="medium"
              name="rating"
              readOnly
              value={item.item.rating}
            />
            <Typography variant="h6">
              ₾{item.item.tip_amount}
            </Typography>
          </Stack>
          
          <Stack direction="row" spacing={1} alignItems='center'>
            <Typography variant="body2"> 
              {item.item.service_professional_name}
            </Typography>
            <Typography variant="body2"> 
              @
            </Typography>
            <Typography variant="body2">
              {item.item.business_name}
            </Typography>
            <Typography variant="caption">
              {item.item.shared ? "(Shared)" : ""}
            </Typography>
          </Stack>
            <Typography variant="body">
              <i>"{item.item.comment}"</i>
            </Typography>
            <Typography variant="body" fontSize="0.7rem" >
              {fDateTimeSuffix(item.item.review_time)} 
            </Typography>
      </Stack> 
      );
    }
