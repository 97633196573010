import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {russian_translations, georgian_translations, english_translations } from './translations';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: english_translations
      },
      ka: {
        translation: georgian_translations
      },
      ru: {
        translation: russian_translations
      },
      // ... other languages
    },
    fallbackLng: 'en', // Fallback language
    supportedLngs: ['en', 'ka','ru'], // or whitelist: ['en', 'ka'],
    detection: {
      order: ['localStorage','navigator', 'querystring', 'cookie', 'sessionStorage', 'path'],
      lookupLocalStorage: 'language', // Key in localStorage
      caches: ['localStorage'], // where to store the language preference
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
