import {makeApiPostRequestAuthenticated} from '../../../utils/apiRequest';

export function getServiceProfessionalReviewsReport(startDate,endDate, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_service_professional_reviews_report/',
    data: {
      start_date:startDate,
      end_date:endDate,
    },
  }, handleSuccess, handleFailure);
}

export function getBusinessReviewsReport(startDate,endDate,businessId, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_business_reviews_report/',
    data: {
      start_date:startDate,
      end_date:endDate,
      id:businessId,
    },
  }, handleSuccess, handleFailure);
}

