import { makeApiPostRequestAnonymous } from "../../utils/apiRequest";

export function submitInfo(data, handleSuccess, handleFailure) {
    makeApiPostRequestAnonymous({
      url: '/api/contact_request/',
      data: {
        code: data.code,
        name: data.name,
        email: data.email,
        phone_number:data.phone_number,
        number_of_employees: data.number_of_employees,
        comments: data.comments, 
      },
      headers: {
        "Content-Type" : 'application/json',
      }
    }, handleSuccess, handleFailure);
  }
  