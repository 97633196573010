import { makeApiPostRequestAuthenticated } from '../../../../utils/apiRequest';


export function getBusinessProfileSettings(id, handleSuccess, handleFailure) {
    makeApiPostRequestAuthenticated({
      url: '/api/get_business_profile_settings/',
      data: {
        business: id
      },
    }, handleSuccess, handleFailure);
  }
  
export function editBusinessProfileSettings(id, business_profile_settings, handleSuccess, handleFailure) {
    makeApiPostRequestAuthenticated({
      url: '/api/edit_business_profile_settings/',
      data: {
        business: id,
        business_profile_settings: business_profile_settings
      },
    }, handleSuccess, handleFailure);
  }