import React, { Component } from "react";
// material
import { Box, Grid, Stack, Container, Typography } from '@material-ui/core';
// components
import Page from '../Page';

import {
  MTipMyBusinesses,
  MTipRegisterBusinessProfile
} from '../../components/mtip_components/mtipManager';


// ----------------------------------------------------------------------

export default class MTipManager extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      updated: false,
    };
  }

  
  refreshState = () => {
    this.setState({
      updated:!this.state.updated
    })
  }

  render () {

    return (
      <Page title="MARTIPIA | Martipia Manager">
        <Container maxWidth="xl">
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Martipia Manager</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
              <Stack direction="column" spacing={3}>
                <MTipRegisterBusinessProfile refreshState = {this.refreshState} />
                <MTipMyBusinesses updated = {this.state.updated} refreshState = {this.refreshState}/>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Page>
    )
  }
}