
import QRCode from "react-qr-code";
import React from "react";

import PropTypes from 'prop-types';
// material
import { Stack, Typography,} from '@material-ui/core';

import ServiceProfessionalImage from "./ServiceProfessionalImage";

ServiceProfessionalProfile.propTypes = {
  serviceProfessionalProfileInfo: PropTypes.object.isRequired,
};






export default function ServiceProfessionalProfile (serviceProfessionalProfileInfo,) {
  const pItem = (
    <Stack direction="column" spacing={1} alignItems="center">
      <ServiceProfessionalImage serviceProfessionalProfileInfo={serviceProfessionalProfileInfo}/>
          {/* <Stack direction = "column">
            <Typography variant="subtitle2">
              QR Code:
            </Typography>
            <QRCode value={(serviceProfessionalProfileInfo) ? ( (process.env.REACT_APP_ENVIRONMENT==='DEV') ? `https://test.app.martipia.com/rate/${serviceProfessionalProfileInfo.id}` : `https://app.martipia.com/rate/${serviceProfessionalProfileInfo.id}`) : "N/A"} size={125} />
            <Typography variant="subtitle2">
              Service Professional Code:
            </Typography>
            <Typography variant="caption">
            { serviceProfessionalProfileInfo.id}
            </Typography>
          </Stack> */}
          <Stack direction = "column">
            <Stack direction = "column">
              <Typography variant="subtitle2">
                QR Code:
              </Typography>
              <QRCode value={(serviceProfessionalProfileInfo) ? ( (process.env.REACT_APP_ENVIRONMENT==='DEV') ? `https://test.app.martipia.com/rate/${serviceProfessionalProfileInfo.id}` : `https://app.martipia.com/rate/${serviceProfessionalProfileInfo.id}`) : "N/A"} size={125} />
              <Typography variant="subtitle2">
                Service Professional Code:
              </Typography>
              <Typography variant="caption">
              { serviceProfessionalProfileInfo.id}
              </Typography>
            </Stack> 
            <Stack direction="row" alignItem="center" spacing={1}>
              <Typography variant="subtitle2" >
                Name:
              </Typography>  
              <Typography variant="subtitle2" >
                {serviceProfessionalProfileInfo.name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItem="center" spacing={1}>
              <Typography variant="body2" >
                Role:
              </Typography>  
              <Typography variant="body2" sx={{ color: 'text.secondary' }} >
                {serviceProfessionalProfileInfo.role}
              </Typography>
            </Stack>
            <Stack direction="row" alignItem="center" spacing={1}>
              <Typography variant="body2" >
                Bio:
              </Typography>  
              <Typography variant="body2" sx={{ color: 'text.secondary' }} >
                {serviceProfessionalProfileInfo.bio}
              </Typography>
            </Stack>
            <Stack direction="row" alignItem="center" spacing={1}>
              <Typography variant="body2" >
                Business Name:
              </Typography>  
              <Typography variant="body2" sx={{ color: 'text.secondary' }} >
                {serviceProfessionalProfileInfo.business_name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItem="center" spacing={1}>
              <Typography variant="body2" >
                Number of Reviews:
              </Typography>  
              <Typography variant="body2" sx={{ color: 'text.secondary' }} >
                {serviceProfessionalProfileInfo.number_of_reviews}
              </Typography>
            </Stack>
            <Stack direction="row" alignItem="center" spacing={1}>
              <Typography variant="body2" >
                Rating:
              </Typography>  
              <Typography variant="body2" sx={{ color: 'text.secondary' }} >
                {parseFloat(serviceProfessionalProfileInfo.rating).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>  
    </Stack>
    );
    return pItem;
  }