import { Navigate } from "react-router-dom";

import React from "react";
import {Button, Stack, TextField, Box, CircularProgress, Typography,} from '@material-ui/core';
import {submitInfo} from "../scripts"

import withErrorHandling from "../../hocs/withErrorHandling";

class ContactForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            code:props.code.code,
            name: "",
            email:"",
            confirmed_email:"",
            comments:"",
            data_provided:false,
            confirms_correct:true,
            emails_correct:true,
            number_of_employees:"",
            phone_number:"",
        }   
      }

    componentDidMount = () =>
    {
        window.addEventListener('load', function () {
            window.top.postMessage({ hideSpinner: true }, '*')
            }, false);
    }
    

    checkEmpty() {
      if (this.state.name && this.state.email) {
        this.setState({
          data_provided:true
        })
      }
      else {
        this.setState({
          data_provided:false
        })
      }
    }



    handleChange = e => {
      const name = e.target.name
      const value = e.target.value
      this.state[name]=value
      this.checkEmpty()
  }

  submitInfo = () => {
    this.props.setLoadingTrue()
    submitInfo(this.state, this.props.handleSuccess, this.props.handleFailure)
  }



    render() {
        if (this.state.redirect) {
          return <Navigate to={this.state.redirect} />
        }
        return (
                <Stack direction = 'column'>

                    <TextField
                    margin="dense"
                    id="user-name"
                    label="Name"
                    name="name"
                    type="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    error = {this.state.error}
                    color = {this.state.error ? "error" : "primary"}
                    placeholder="Enter your name"
                    required="true"
                    />
                    <TextField
                    margin="dense"
                    id="user-email"
                    label="Email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error = {this.state.error}
                    color = {this.state.error ? "error" : "primary"}
                    placeholder="Enter your email"
                    required="true"
                    />
                    <TextField
                    margin="dense"
                    id="user-number-of-employees"
                    label="Number of employees"
                    name="number_of_employees"
                    type="number"
                    value={this.state.number_of_employees}
                    onChange={this.handleChange}
                    error = {this.state.error}
                    color = {this.state.error ? "error" : "primary"}
                    placeholder="Enter the approximate number of employees."
                    />
                    <TextField
                    margin="dense"
                    id="user-phone-number"
                    label="Mobile phone number"
                    name="phone_number"
                    type="number"
                    value={this.state.phone_number}
                    onChange={this.handleChange}
                    error = {this.state.error}
                    color = {this.state.error ? "error" : "primary"}
                    placeholder="Enter your mobile phone number."
                    />
                    <Typography>
                      Describe your business & the best way to contact.
                    </Typography>
                    <TextField
                    margin="dense"
                    id="user-comments"
                    name="comments"
                    type="text"
                    value={this.state.comments}
                    onChange={this.handleChange}
                    error = {this.state.error}
                    color = {this.state.error ? "error" : "primary"}
                    placeholder="And any other comments!"
                    />
                    
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button 
                            fullWidth
                            type='submit'
                            variant="contained" 
                            color="primary" 
                            disabled = {this.state.loading || !this.state.data_provided || !this.state.confirms_correct}
                            onClick= {() => { this.submitInfo()}}
                            >
                            Submit
                        </Button>
                        {this.state.loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "primary",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />)}
                        {this.state.loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "primary",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />)}
                    </Box>
                </Stack>
                
        );
    }
}

export default withErrorHandling(ContactForm);