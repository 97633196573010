
import React, { Component, createRef } from "react";
import {CircularProgress,   CardContent, Box,  Stack, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';


import MartipiaMenuItems from './MartipiaMenuItems'
import MartipiaMenuCategoryButton from './MartipiaMenuCategoryButton'
import withErrorHandling from '../../../hocs/withErrorHandling'
import { getMartipiaMenu } from "./external_menu_api";
import MenuNavbar from "./MenuNavbar";
import MartipiaMenuFrontPage from "./MartipiaMenuFrontPage";

const ALL_CATEGORY_TRANSLATION_NAMES = {
  en: 'All',
  ka: 'ყველა',
  ru: 'Все',
}

class MartipiaMenu extends Component {


  constructor(props) {
    
    super(props);
    this.state = {
      drawer_height: 0,
      navbar_open:false,
      active_category:'',
      business_info: {},
      menu_info: {},
      menu_items: [],
      menu_categories: [],
      show_categories: true, // Whether to show categories or not
    };
    this.itemsRef = createRef(); // Create a ref for scrolling purpose
    this.frontPageRef = createRef(); // Create a ref for scrolling purpose
  
}
  componentDidMount() {
    this.props.handleLoading();
    getMartipiaMenu(this.props.shared_qr_id.id, this.handleSuccessfulMenuFetch, this.props.handleFailure);
  }

  handleSuccessfulMenuFetch = (response) => {
    const { i18n } = this.props;
    //sort the categories from response.data.categories by category.order_position first and then alphabetically. save that to a new object called menu_categories
    const menu_categories_sorted = response.data.categories.sort((a, b) => {
    // Assign a large order_position value to categories without one
    const orderA = a.order_position || Number.MAX_SAFE_INTEGER;
    const orderB = b.order_position || Number.MAX_SAFE_INTEGER;
  
    // Compare by the possibly adjusted order_position first
    if (orderA !== orderB) {
      return orderA - orderB;
    }
    
    // If order_position is the same or missing in both, compare by name
    // Check first if the objects contain name_translations and if they match the currently chosen language
    if (a.name_translations && a.name_translations[i18n.language] && b.name_translations && b.name_translations[i18n.language]) {
      return a.name_translations[i18n.language].localeCompare(b.name_translations[i18n.language], { sensitivity: 'variant' });
    }
    else {
      return a.name_default.localeCompare(b.name_default, [i18n.language, 'en'], { sensitivity: 'variant' });
    }
    });
    
    
    this.setState({
      business_info: response.data.business_info,
      menu_info: response.data.menu,
      menu_items: response.data.items,
      menu_categories: [
        {
          id: 'all_category_no_id',
          name_default:'All',
          name_translations: ALL_CATEGORY_TRANSLATION_NAMES,
          emoji: '🍽️',
        },
        ...menu_categories_sorted, // Spread the existing categories here
      ],
    })
    this.props.handleSuccessNoMessage();
  }


  
  updateDrawerHeight = (height) => {
    this.setState({ drawer_height: height+10});
  };

  filterItems = (category) => {
    let newItems = [];
    if (category.id === 'all_category_no_id') {
      newItems = this.state.menu_items;
    } else {
      newItems = this.state.menu_items.filter((item) => item.category === category.id);
    }
    this.setState({
      active_items: newItems,
      active_category: category,
      show_categories: false, // Hide categories after selection
    }, () => {
      this.itemsRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to items section
    });
  };

  toggleCategories = () => {
    this.setState((prevState) => ({ show_categories: !prevState.show_categories }), () => {
      // Ensure DOM updates have occurred (the setTimeout approach can be combined here if needed)
      this.frontPageRef.current.scrollIntoView({ behavior: 'smooth' });
    });
  };
  
  toggleBanner = () => {
    this.setState((prevState) => ({ banner_open: !prevState.banner_open }));
  };


  render = ()=> {
  const { t, i18n } = this.props;

  if (this.props.loading) {
    return(
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <CircularProgress size={100} thickness={20} />
      </Stack>
      )
  }

  return (
    <div>
      
      <MenuNavbar 
        closeNavbar={this.toggleCategories}
        navbar_open={!this.state.show_categories}
        filterItems={this.filterItems}
        categories={this.state.menu_categories}
        active_category={this.state.active_category}
        updateDrawerHeight={this.updateDrawerHeight}
        business_info={this.state.business_info}
        menu_info={this.state.menu_info}/>
        
      <div ref={this.frontPageRef} >
        {this.state.show_categories && (
          <MartipiaMenuFrontPage 
            menu_info={this.state.menu_info}
            categories={this.state.menu_categories}
            business_info={this.state.business_info}
            filterItems={this.filterItems}/>
          )}
      </div>

      <div ref={this.itemsRef} >
        {!this.state.show_categories && 
        <Box container sx={{paddingTop:`${this.state.drawer_height}px`}}>
        <Stack direction={'column'} alignItems="center" justifyContent='center'>
          <MartipiaMenuCategoryButton doFunction={this.toggleCategories} categoryObj={this.state.active_category} contained={true}/>
          <Typography align='center' variant="body1">
            <b>
              {this.state.active_category.name_translations && this.state.active_category.name_translations[i18n.language]
              ? this.state.active_category.name_translations[i18n.language]
              : this.state.active_category.name_default}
            </b>
          </Typography>
        </Stack>
        <CardContent>
          {this.state.active_items && <MartipiaMenuItems items={this.state.active_items} categories = {this.state.menu_categories}/>}
        </CardContent>
        </Box>}
      </div>
    </div>

  )
  }

}
export default withErrorHandling(withTranslation()(MartipiaMenu));
