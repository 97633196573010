import {Navigate} from "react-router-dom"
import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// components
import MHidden from '../../components/misc/navigation/MHidden';
//
import AccountPopover from './AccountPopover';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------





class DashboardNavbar extends Component 
{
  constructor(props) 
  {
    super(props);
    
    this.state = {
      redirect:"",
      logged_in: localStorage.getItem('token') ? true: false,
    };
  };


  handleLogout = () => {
    localStorage.removeItem('token');    
    this.setState({
      logged_in: false,
      redirect:"/login"
      });
  };



  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect}/>
    }
      return (
        <RootStyle>
          <ToolbarStyle>
            <MHidden width="lgUp">
              <IconButton onClick={this.props.onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                <Icon icon={menu2Fill} />
              </IconButton>
            </MHidden>
            <Box sx={{ flexGrow: 1 }} />
    
            <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>
              {(this.state.logged_in) ? <AccountPopover handleLogout = {this.handleLogout}/> : <Navigate to="/login"/> } 

            </Stack>
          </ToolbarStyle>
        </RootStyle>
      );
    };
}

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func.isRequired
  };

export default DashboardNavbar;