import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import PosterIntegrationModalDetails from "./PosterIntegrationModalDetails";
import IikoIntegrationModalDetails from "./IikoIntegrationModalDetails";

export default class ServiceProfessionalIntegrationsPlatformIntegrationsModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      platform: props.platform,
      platform_info: props.platform_info,
      open: true,
    };
  }

  render = ()=> {
    const { toggleModal, submitIntegrationsInfo } = this.props;
    return (  
      <div>
        <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Service Professional Platforms Integrations</DialogTitle>
            {(this.state.platform==='poster') ? <PosterIntegrationModalDetails platform_info = {this.state.platform_info} submitIntegrationsInfo = {submitIntegrationsInfo} toggleModal={toggleModal} /> : null}           
            {(this.state.platform==='iiko') ? <IikoIntegrationModalDetails platform_info={this.state.platform_info} submitIntegrationsInfo = {submitIntegrationsInfo} toggleModal = {toggleModal} /> : null}                      
        </Dialog>
      </div> 
    );
  }
}
