import React, { Component } from "react";

import { Stack } from '@material-ui/core';
import ImageCard from "../../misc/ImageCard";

export default class BusinessProfileImage extends Component {
  
  constructor(props) {
    super(props);
  }


  render = ()=> {

  return (
    <Stack direction="row" justifyContent="flex-start" spacing = {1}>
    {
      (this.props.businessInfo.businessprofilepicture)
      ? ImageCard(
        this.props.businessInfo.businessprofilepicture.file,
        this.props.businessInfo.name,
        this.props.businessInfo.description,
        (this.props.link) ? this.props.link :""
        )
      : ImageCard(
        "/static/no_picture_business.png",
        this.props.businessInfo.name,
        this.props.businessInfo.description,
        (this.props.link) ? this.props.link :""
        )
    } 
  </Stack>
    );
  }
}
