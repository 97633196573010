import React, { Component } from "react";
import {Button, Box, Grid, Stack, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import MessageModal from '../../../misc/modals/MessageModal';
import ServiceProfessionalImage from '../../serviceProfessionalProfile/ServiceProfessionalImage';

class TipAndRateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      employees_info:props.employees_info,
      redirect:false,
      redirect_id:"",
    };
  }

  handleFailure = (err) => {
    const prevModal = this.state.message_modal;
    this.setState({
      success:false,
      loading:false,
      error:true,
      modal_message: JSON.stringify(err).slice(1,JSON.stringify(err).length-1),
      message_modal: !prevModal,
      tip_loading:false,
    });
  }

  toggleMessageModal = () => {
    const prevModal = this.state.message_modal;
    this.setState({ 
      message_modal: !prevModal,
    });
  };

  renderEmployees = () => {
    const renderedProfiles = [];  
    this.state.employees_info.forEach((employeeInfo) => {
      renderedProfiles.push(this.serviceProfessionalImage(employeeInfo))
      })
    return (renderedProfiles);
  }

  serviceProfessionalImage = (employeeInfo) => {
    const spItem = (
      
      <Button variant="outlined"
      
      onClick = {() => this.toggleRedirect(employeeInfo.id)}> 
        <Box sx={{ marginTop: 2 }}>
          <ServiceProfessionalImage serviceProfessionalProfileInfo={employeeInfo}/>
        </Box>
      </Button>
    )
    return spItem;
  }

  toggleRedirect = (id) => {
    this.setState({
      redirect:true,
      redirect_id:id,
    })
  }

  render = ()=> {
  const { t } = this.props;
  const { redirectToServiceProfessional} = this.props;
    return (
      <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
        <Stack spacing={1} alignItems="center" justifyContent="center">
          {(this.state.redirect) ? redirectToServiceProfessional(this.state.redirect_id) : ""}
          {(this.state.message_modal) ? <MessageModal toggle={this.toggleMessageModal} message={this.state.modal_message} /> : ""}     
            <Typography align="center">
              {t('TipAndRateListCollapsable_choose_employee')}
            </Typography>
            {this.state.employees_info
            ? (this.renderEmployees())
            :"No employees are currently registered with this business."}
        </Stack>
      </Grid>
    );
  }

}

export default  withTranslation()(TipAndRateList);