import React, { Component } from "react";
import {  Stack, CircularProgress } from '@material-ui/core';
import { getProfileInfoPublic } from "../scripts";
import TipAndRateSharedCard from "../shared/TipAndRateSharedCard";
import TipAndRateCard from "./TipAndRateCard";
import withErrorHandling from "../../../hocs/withErrorHandling";

class TipAndRatePage extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      profile_info: '',
      modal:false,
      id:props.id.id,
      profile_loading:true,
    };
  }
  

  componentDidMount () {
    this.refreshData();
    window.addEventListener('pageshow', this.handlePageShow);
  }

  componentWillUnmount() {
    window.removeEventListener('pageshow', this.handlePageShow);
  }

  handlePageShow = (event) => {
    if (event.persisted) {
      // The page was restored from the cache
      window.location.reload();
    }
  };

  handleSuccessfulProfileRefresh = (res) => {
    if (res.data.shared_qr) {
      if (res.data.redirect) {
        this.setState({
          shared_qr:true,
          redirect: res.data.redirect,
          redirect_id:res.data.service_professional,
        });
      }
      else {
        if (res.data.shared_qr_info.list_enabled) {
          this.setState({
            service_professional_list:res.data.sp_list,
          });
        }
        
        this.setState({
          shared_qr:true,
          shared_qr_info:res.data.shared_qr_info,
          business_info:res.data.business_info,
          profile_loading:false,
          default_tip_info:res.data.default_tip_info,
        });
      }
    }
    else {
      this.setState({
        profile_info: res.data.service_professional_info,
        profile_loading:false,
        default_tip_info:res.data.default_tip_info,
      });
    }
  }
  
  
  redirectToServiceProfessional =(redirect_id) => {
    this.setState({
      redirect:false,
      profile_loading:true, 
    })
    window.history.pushState({ page: 'new' }, '');

    window.location.href = `/rate/${redirect_id}`;
  }

  refreshData = () =>
  {
    getProfileInfoPublic(this.state.id, this.handleSuccessfulProfileRefresh, this.props.handleFailure)
  };


   
  render = () => {
    if (this.state.shared_qr){
      if (this.state.redirect) return (this.redirectToServiceProfessional(this.state.redirect_id))

      return (
        <Stack spacing={3} justifyContent="center" alignItems="center">
          {this.state.profile_loading
          ? <CircularProgress size={100} thickness={20} />
          : <TipAndRateSharedCard 
              default_tip_info={this.state.default_tip_info}
              business_info={this.state.business_info}
              shared_qr_info={this.state.shared_qr_info}
              service_professional_list={this.state.service_professional_list} 
              id={this.state.id}
              redirectToServiceProfessional={this.redirectToServiceProfessional}
              is_service_professional={false}
              shared={true}
              />
          }
        </Stack>
      )
    }
    else return  (
      <Stack spacing={3} justifyContent="center" alignItems="center">
        
        
        {this.state.profile_loading
          ? <CircularProgress size={100} thickness={20} />
          : <TipAndRateCard 
              active_item={this.state.profile_info}
              id={this.state.id}
              shared={false}
              is_service_professional={true}
              default_tip_info={this.state.default_tip_info}
              />
        }

      </Stack>
    );
  }
}
export default withErrorHandling(TipAndRatePage);