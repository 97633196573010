import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

MTipLogo.propTypes = {
  sx: PropTypes.object
};

export default function MTipLogo({ sx }) {
  return <Box component="img" src="/static/Martipia_logo.png" sx={{ width: 60, height: 60, ...sx }} />;
}
