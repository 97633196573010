import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Checkbox, Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Stack } from "@mui/material";


export default class GoogleReviewThankyouCardModalDetails extends Component {
  
  constructor(props) {
    super(props);
    (props.thankyou_data.id 
      ?(
        this.state = {
          thankyou_data: props.thankyou_data.thankyou_data,
        }
      )
      :(
        this.state = {
          thankyou_data: ""
        }
      )
    )
    
  }

  handleChange = (e) => {
    let { value, name } = e.target; 
    const prevItem = this.state.thankyou_data;
    const thankyouData = { ...prevItem, [name]: value };
    this.setState({
      thankyou_data: thankyouData,
    });
  };

  
  handleCheckbox = e => {
    const name = e.target.name
    const value = e.target.checked
    const prevItem = this.state.thankyou_data;
    const thankyouData = { ...prevItem, [name]: value };
    this.setState({
      thankyou_data: thankyouData,
    });
}


  render = ()=> {  
    const { toggleModal, submitThankyouData } = this.props;
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            <Typography align="center" variant='h4'>
              Google Reviews
            </Typography>
          </DialogContentText>
          <Stack>
            <TextField
              margin="dense"
              id="businessProfile-thankyou_card-google_reviews_link"
              label="Google Reviews Link"
              name="google_reviews_link"
              type="text"
              value={this.state.thankyou_data.google_reviews_link}
              onChange={this.handleChange}
              placeholder="Enter the Google Reviews Link"
              fullWidth
            />
            <Typography align="center" variant="body2">
              <i>Please use <a target="_blank" href="https://support.google.com/business/answer/3474122?hl=en">this link</a> to find the link to your Google Reviews.</i>
            </Typography>
            <Typography align="center">
                Check this box to show a Google Place of your establishment.
                <Checkbox
                margin="dense"
                id="businessProfile-thankyou_card-google_reviews_map_enabled"
                name="google_reviews_map_enabled"
                checked={this.state.thankyou_data.google_reviews_map_enabled}
                onChange={this.handleCheckbox}
                required="true"
              />
            </Typography>
            <TextField
              margin="dense"
              id="businessProfile-thankyou_card-google_reviews_location_id"
              label="Google Reviews Location ID"
              name="google_reviews_location_ID"
              type="text"
              value={this.state.thankyou_data.google_reviews_location_ID}
              onChange={this.handleChange}
              placeholder="Enter the Google Place ID"
              fullWidth
            />
            <Typography align="center" variant="body2">
              <i>Please use <a target="_blank" href="https://developers.google.com/maps/documentation/places/web-service/place-id">this link</a> to find the Google Place ID of your establishment.</i>
            </Typography>
          </Stack>
        </DialogContent>        
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick=
          { () =>
            {
            submitThankyouData("google_review", this.state.thankyou_data);
            toggleModal();
            }
          }>
            Save
          </Button>
        </DialogActions>
      </div>
      );
  }
}
