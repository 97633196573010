import {Card} from '@material-ui/core';
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import { Grid, Stack, CardContent} from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import TipAndRateTip from "../tipAndRate/TipAndRateTip";
import MessageModal from '../../../misc/modals/MessageModal';


class TipAndRateSplitTipCollapsable extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id:props.id,
      default_tip_info:props.default_tip_info,
    }
  }


  handleTipBoxToggle = () => {
    this.setState(prevState => ({
      isStaffListOpen: !prevState.isStaffListOpen
    }));
    this.props.toggleComponent('split_enabled'); 
  };


  render = ()=> {
    const { t } = this.props;
    const isComponentActive = this.props.activeComponent === 'split_enabled';
    return (
      <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
        {(this.state.message_modal) ? <MessageModal toggle={this.toggleMessageModal} message={this.state.modal_message} /> : ""}
        <Stack spacing={1} alignItems="center" justifyContent="center">
          {(!(this.props.activeComponent) || !(this.props.activeComponent!=='split_enabled')) &&(
            <Button variant="contained" color="primary" onClick={() => this.handleTipBoxToggle()}>
              {(isComponentActive) ? t('TipAndRateSplitTipCollapsable_tip_box_button_close') : t('TipAndRateSplitTipCollapsable_tip_box_button_open')}
            </Button>
          )}
          {(isComponentActive) && (  
            <Card>
              <CardContent>
                <TipAndRateTip 
                  id={this.state.id}
                  shared={true}
                  default_tip_info={this.state.default_tip_info}
                  />
              </CardContent>
            </Card>
          )}
        </Stack>
      </Grid>
    );
  }
}

export default withTranslation()(TipAndRateSplitTipCollapsable);