import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@material-ui/core/styles';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
// --
import { background_details_useStyles } from './background_details';
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};


export default function ThemeConfig({ children }) {

  const classes = background_details_useStyles();
  const themeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      breakpoints,
      shadows,
      customShadows
    }),
    []
  );
  
  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
    
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={classes.root_class}>
          <CssBaseline />
          {/* <GlobalStyles /> */}
          {children}
        
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

