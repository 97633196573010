
import React, { Component } from "react";
import { Box, Grid, Stack , } from '@material-ui/core';
import Scrollbar from '../../../misc/navigation/Scrollbar';
// components

import {
  BusinessProfileDetailsDashboard,
  BusinessProfileEmployeesDashboard,
  BusinessProfileReviewsDashboard,
  BusinessProfileMartipiaPlus,
} from './';


// ----------------------------------------------------------------------


export default class BusinessDashboard extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      updated: false,
      id: props.id
    };
  }
  
  refreshState = () => {
    this.setState({
      updated:!this.state.updated
    })
  }

  render () {
    return  (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <Scrollbar>
              <Stack alignItems="center" direction="column" spacing={3} >
                <BusinessProfileDetailsDashboard id={this.state.id} updated = {this.state.updated} refreshState = {this.refreshState}/>
                <BusinessProfileEmployeesDashboard id={this.state.id} updated = {this.state.updated} refreshState = {this.refreshState}/>
                <BusinessProfileReviewsDashboard id={this.state.id} updated = {this.state.updated} refreshState = {this.refreshState}/>
                {/* <BusinessProfileMartipiaPlus id={this.state.id} updated = {this.state.updated} refreshState = {this.refreshState}/> */}
              </Stack>
            </Scrollbar>
          </Grid>
        </Grid>
      </Box>
    );
  }
}