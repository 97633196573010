import { useParams } from "react-router";

import React from "react";
// material
import { Container } from '@material-ui/core';
// components
import Page from '../Page';
import TipAndRatePage from '../../components/mtip_components/tipAndRate/tipAndRate/TipAndRatePage';
import MartipiaMenu from '../../components/mtip_components/martipiaMenu/external/MartipiaMenu';


// ----------------------------------------------------------------------


export default function MTipMenu() {
  let id = useParams();  
  return (
    <Page title="MARTIPIA | Menu">
      <Container>
        { <MartipiaMenu shared_qr_id = {id}/> }
      </Container>
    </Page>
  );
}
