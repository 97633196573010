const SUCCESS_RESPONSES = {
    MENU_ITEM_COPY_SUCCESS: {
        header: 'Success',
        detail: 'Menu item copied.'
    },
    MENU_COPIED: {
        header: 'Success',
        detail: 'Menu copied.'
    },
    EASY_TIP_UPDATED: {
        header: 'Success',
        detail: 'Easy tip profile updated.'
    },
    MENU_LINK_UPDATED: {
        header: 'Success',
        detail: 'Menu link updated.'
    },
    MENU_TYPE_UPDATED: {
        header: 'Success',
        detail: 'Menu type updated.'
    },
    MENU_TAGS_UPDATED: {
        header: 'Success',
        detail: 'Menu tags updated.'
    },
    MENU_GREETINGS_SUBTEXT_UPDATED: {
        header: 'Success',
        detail: 'Menu greetings subtext updated.'
    },
    MENU_GREETINGS_TEXT_UPDATED: {
        header: 'Success',
        detail: 'Menu greetings text updated.'
    },
    MENU_BANNER_PICTURE_UPDATED: {
        header: 'Success',
        detail: 'Menu banner picture updated.'
    },
    MENU_CATEGORY_PICTURE_UPLOAD_SUCCESS : {
        header: 'Success',
        detail: 'Menu category picture uploaded.'
    },
    MENU_CATEGORY_EDIT_SUCCESS: {
        header: 'Success',
        detail: 'Menu category saved.'
    },
    MENU_ITEM_PICTURE_UPLOAD_SUCCESS: {
        header: 'Success',
        detail: 'Menu item picture uploaded.'
    },
    MENU_ITEM_EDIT_SUCCESS: {
        header: 'Success',
        detail: 'Menu item saved.'
    },
    MENU_CATEGORY_DELETE_SUCCESS: {
        header: 'Success',
        detail: 'Menu category deleted.'
    },
    MENU_ITEM_DELETE_SUCCESS: {
        header: 'Success',
        detail: 'Menu item deleted.'
    },
    BUSINESS_PROFILE_UPDATED: {
        header: 'Success',
        detail: 'Business profile updated.'
    },
    BUSINESS_PROFILE_PICTURE_UPDATED: {
        header: 'Success',
        detail: 'Business profile picture updated.'
    },
    BUSINESS_INTEGRATIONS_UPDATED: {
        header: 'Success',
        detail: 'Business integrations updated.'
    },
    THANKYOU_DATA_UPDATED: {
        header: 'Success',
        detail: 'Thank you data updated.'
    },
    BUSINESS_PROFILE_SETTINGS_UPDATED: {
        header: 'Success',
        detail: 'Business profile settings updated.'
    },
    EMPLOYEE_FIRED: {
        header: 'Success',
        detail: 'Employee removed from the business.'
    },
    USER_PROFILE_UPDATED: {
        header: 'Success',
        detail: 'User profile updated.'
    },
    SERVICE_PROFESSIONAL_PROFILE_DELETED: {
        header: 'Success',
        detail: 'Service professional profile deleted.'
    },
    SERVICE_PROFESSIONAL_PROFILE_UPDATED: {
        header: 'Success',
        detail: 'Service professional profile updated.'
    },
    SERVICE_PROFESSIONAL_PROFILE_PICTURE_UPDATED: {
        header: 'Success',
        detail: 'Service professional profile picture updated.'
    },
    SERVICE_PROFESSIONAL_INTEGRATIONS_UPDATED: {
        header: 'Success',
        detail: 'Service professional integrations updated.'
    },
    WITHDRAWAL_DESTINATION_INFO_UPDATED: {
        header: 'Success',
        detail: 'Withdrawal destination info updated.'
    },
}
export default SUCCESS_RESPONSES;