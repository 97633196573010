import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import { Switch, Stack, Typography } from "@material-ui/core";
import BusinessProfileThankyouCardsDetailModal from "./BusinessProfileThankyouCardsDetailModal";


export default class BusinessProfileThankyouCardsModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      thankyou_data: props.thankyou_data,
      open: true,
      thankyou_card_details_modal:false,
      thankyou_card_type:"",
    };
  }

   openThankyouCardsDetailsModal = (e) => {
    this.state["thankyou_card_type"]=e
    this.toggleThankyouCardDetailsModal()
  }

  
toggleThankyouData = () => {
  const prevItem = this.state.thankyou_data;
  const thankyouData = { ...prevItem, enabled: !prevItem.enabled };
  this.setState({
    thankyou_data: thankyouData,
  });
  this.props.toggleThankyouData()
}

  toggleThankyouCardDetailsModal = () =>  {
    const prevModal = this.state.thankyou_card_details_modal;
    this.setState({ 
      thankyou_card_details_modal: !prevModal,
    });
  };

  render = ()=> {

  const { toggleModal, submitThankyouData} = this.props;
  return (
    <div>
      {(this.state.thankyou_card_details_modal) ? <BusinessProfileThankyouCardsDetailModal type = {this.state.thankyou_card_type} thankyou_data = {this.state.thankyou_data} submitThankyouData = {submitThankyouData} toggleModal = {this.toggleThankyouCardDetailsModal}/> : null}  
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Thank-you Card Settings</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3} alignItems="center">
          <DialogContentText>
            Configure what customers will see after they tip.
          </DialogContentText>
            <Stack>
              <Button onClick={ () => {this.openThankyouCardsDetailsModal("google_reviews")}} color="primary"  variant="outlined">
                <Typography align="center" variant='h4'>      
                  Google Places & Reviews
                </Typography>
              </Button>
              <Typography align="center" variant='subtitle'>          
                This option allows you to direct users to your Google Reviews page, and show your establishment on the map.
              </Typography>
            </Stack>
            <Stack>
              <Button disabled="true" onClick={ () => {this.openThankyouCardsDetailsModal("other_link")}} color="primary"  variant="outlined">
                <Typography align="center" variant='h4'>      
                  Custom Link
                </Typography>
              </Button>
              <Typography align="center" variant='subtitle'>          
                Coming soon! This option allows you to direct users to custom links with custom text.
              </Typography>
            </Stack>
            <Stack direction='row'>
              <Typography alignItems={"baseline"} justifyContent={"center"} variant='subtitle'>
                Enable thank you cards at the end of tipping.
                <Switch
                checked={(this.state.thankyou_data.enabled)}
                onChange={() => this.toggleThankyouData()}
                name="thankyou_data_enabled"
                color="primary"  
              />
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
