import React, { Component } from 'react';
import {Stack, Card, CardMedia, Button, CardContent, Typography, Modal, Box, CardActionArea, Grid, Chip  } from '@mui/material';
import { withTranslation } from 'react-i18next';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


const imageContainerStyle = {
  position: 'relative', // Needed for absolute positioning of the close button
  width: 'auto', // Allow the container to expand to the image size
  height: 'auto', // Allow the container to expand to the image size
  maxHeight: '100vh', // Ensure the container does not exceed the viewport height
  maxWidth: '100vw', // Ensure the container does not exceed the viewport width
  overflow: 'auto', // Enable scrolling within the container
};


// Adjusted Button styles for visibility and placement
const closeButtonStyle = {
  position: 'fixed', // Position relative to the viewport
  top: '2vh', // Slightly down from the top of the viewport
  right: '5vw', // Slightly in from the right edge of the viewport
  zIndex: 10, // Ensure it's above other content
  color: '#fff', // Text color for visibility
  backgroundColor: '#1976d2', // Use theme color or a visible color
  '&:hover': {
    backgroundColor: '#115293', // Darken on hover for feedback
  },
};

const imageModalStyle = {
  position: 'fixed', // Cover the entire screen
  top: 0,
  left: 0,
  width: '100vw', // Full viewport width
  height: '100vh', // Full viewport height
  bgcolor: 'rgba(0, 0, 0, 0.7)', // Optional: semi-transparent background for better focus on the image
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  overflow: 'hidden', // Hide overflow
};

class MartipiaMenuItems extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      item_page_open: false,
      item_image_open: false,
      selected_item:"",
    };
    
  }

  handleOpen = (item) => {
    this.setState({selected_item:item, item_page_open:true});
  };

  handleClose = () => {
    this.setState({selected_item:"",item_page_open:false});
  };

  

  handleOpenItemImage = (item) => {
    if (item.martipiamenuitempicture)
    {
      this.setState((prevState) => ({ item_image_open: !prevState.item_image_open, selected_image: item.martipiamenuitempicture.file }));
    }
    else 
    {
      this.setState((prevState) => ({ item_image_open: !prevState.item_image_open, selected_image: "/static/no_picture_menu_item.jpg" }));
    }
  };

  handleCloseItemImage = (item) => {
    this.setState((prevState) => ({ item_image_open: !prevState.item_image_open, selected_image: "" }));
  };
  
  render = ()=> {
    const { i18n, items, categories } = this.props;
    // Step 1: Map categories by id for efficient access
    const categoriesMap = categories.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {});

    // Step 2: Sort items by category position, then by item order_position and name
    const items_sorted = items.sort((a, b) => {
      // Access each item's category
      const categoryA = categoriesMap[a.category];
      const categoryB = categoriesMap[b.category];

      // Assign a large order_position value to categories without one
      const categoryOrderA = categoryA ? (categoryA.order_position || Number.MAX_SAFE_INTEGER) : Number.MAX_SAFE_INTEGER;
      const categoryOrderB = categoryB ? (categoryB.order_position || Number.MAX_SAFE_INTEGER) : Number.MAX_SAFE_INTEGER;

      // Compare by category order_position first
      if (categoryOrderA !== categoryOrderB) {
        return categoryOrderA - categoryOrderB;
      }
      
      // Within the same category, compare by item's order_position
      const orderA = a.order_position || Number.MAX_SAFE_INTEGER;
      const orderB = b.order_position || Number.MAX_SAFE_INTEGER;
      if (orderA !== orderB) {
        return orderA - orderB;
      }
      
      if (a.name_translations && a.name_translations[i18n.language] && b.name_translations && b.name_translations[i18n.language]) {
        return a.name_translations[i18n.language].localeCompare(b.name_translations[i18n.language], { sensitivity: 'variant' });
      }
      else {
        return a.name_default.localeCompare(b.name_default, [i18n.language, 'en'], { sensitivity: 'variant' });
      }
    }
  );
  return (
    <Grid container spacing={2} justifyContent={'center'}>
      {items_sorted.map((menuItem) => {
        const { id, name_default,name_translations, martipiamenuitempicture, price, tags, hidden } = menuItem;
        if (hidden) {
          return null;
        }
        return (
          <Grid item xs={12} sm={6} md={3} lg={2} key={id}>
            <Card sx={{ maxWidth: 600,}}>
              <CardActionArea onClick={() => this.handleOpen(menuItem)}>
                <CardMedia
                  component="img"
                  height="280"
                  image={martipiamenuitempicture ? martipiamenuitempicture.file : "/static/no_picture_menu_item.jpg"}
                  alt={name_translations && name_translations[i18n.language] ? name_translations[i18n.language] : name_default}
                />
                <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="baseline">
                  <Typography variant="h6" component="div" >
                  {name_translations && name_translations[i18n.language] ? name_translations[i18n.language] : name_default}
                  </Typography>
                  <Typography variant="h6" component="div" style={{ flexShrink: 0 }}>
                    ₾{price}
                  </Typography>
                </Box>
                  
                  <Stack direction="row" spacing={0.5} mt={2} flexWrap="wrap">
                    {tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        variant="filled"
                        color="primary" 
                        size='small'
                        sx={{
                          mt: index === 0 ? 0 : '4px',
                          ml: index === 0 ? 0 : '4px',
                          mr: index === 0 ? 0 : '4px',
                          mb: '4px',
                        }}
                      />
                    ))}
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}

      {this.state.item_page_open &&
        <Modal
        open={this.state.item_page_open}
        onClose={this.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            {this.state.selected_item && (
              <>
                <CardActionArea onClick={() => this.handleOpenItemImage(this.state.selected_item)}>
                <Box>
                  <CardMedia
                    component="img"
                    height="350"
                    image={this.state.selected_item.martipiamenuitempicture ? this.state.selected_item.martipiamenuitempicture.file : "/static/no_picture_menu_item.jpg"}
                    alt={this.state.selected_item.name_translations && this.state.selected_item.name_translations[i18n.language] ? this.state.selected_item.name_translations[i18n.language] : this.state.selected_item.name_default}
                    />
                  </Box>
                </CardActionArea>
                <CardContent >
                  <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h5">
                      {this.state.selected_item.name_translations && this.state.selected_item.name_translations[i18n.language] ? this.state.selected_item.name_translations[i18n.language] : this.state.selected_item.name_default}
                    </Typography>
                  </Stack>
                  <Typography variant="h6" >
                    ₾{this.state.selected_item.price}
                  </Typography>
                  <Box style={{ overflow: 'auto', maxHeight: '200px' }}>
                    <Typography variant="body2" color="text.secondary">
                      {this.state.selected_item.description_translations && this.state.selected_item.description_translations[i18n.language] ? this.state.selected_item.description_translations[i18n.language] : this.state.selected_item.description_default}
                    </Typography>
                    <Stack direction="row" spacing={0.5} mt={2} flexWrap="wrap">
                      {this.state.selected_item.tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag}
                          variant="filled"
                          color="primary" 
                          size='small'
                          sx={{
                            mt: index === 0 ? 0 : '4px',
                            ml: index === 0 ? 0 : '4px',
                            mr: index === 0 ? 0 : '4px',
                            mb: '4px',
                          }}
                        />
                      ))}
                    </Stack>
                  </Box>
                  <CardActionArea onClick={() => this.handleClose()}>
                    <Stack alignItems={'center'}>
                        <Button variant='text'>
                          Close
                        </Button>
                    </Stack>
                  </CardActionArea>
                </CardContent>
              </>
            )}
          </Box>
        </Modal>
      }

      {this.state.item_image_open && 
        <Modal
        open={this.state.item_image_open}
        onClose={this.handleCloseItemImage}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        >
          {this.state.item_image_open && (
            <Box sx={imageModalStyle}>
              <Box sx={imageContainerStyle}>
                <img
                  src={this.state.selected_item.martipiamenuitempicture ? this.state.selected_item.martipiamenuitempicture.file : "/static/no_picture_menu_item.jpg"}
                  alt={this.state.selected_item.name_translations && this.state.selected_item.name_translations[i18n.language] ? this.state.selected_item.name_translations[i18n.language] + ' picture.' : this.state.selected_item.name_default + ' picture.'}
                  style={{
                    maxHeight: '90vh', // Max 80% of the viewport height
                    maxWidth: '90vw', // Max 80% of the viewport width
                    objectFit: 'contain', // Ensure the image is scaled to fit while maintaining its aspect ratio
                  }}
                  />
                <Button
                  onClick={this.handleCloseItemImage}
                  sx={closeButtonStyle}
                  variant="contained"
                  >
                  Close
                </Button>
              </Box>
            </Box>
          )}
          </Modal>
        }
  </Grid>
  );
}
}

export default  withTranslation()(MartipiaMenuItems);