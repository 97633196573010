import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import {Stack, FormControl, Select,MenuItem, } from "@material-ui/core";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES} from "../../../../config/systemMessages";
import { refreshBusinessProfileList } from "../../mtipManager/scripts";
import ActionCheckModal from "../../../misc/modals/ActionCheckModal";
import { copyMenuFromAnotherBusiness } from "./menu_api";

class MartipiaMenuCopyModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      menu_id: props.menu_id,
      business_id:props.business_id,
      chosen_business: "",
      business_profile_list: [],
    };
  }
  componentDidMount() {
    this.refreshData()
  }
    

  
  handleSuccessfulRefresh = (res) => {
    // remove the current business from the list
    const res_data = res.data.filter(business => business.id !== this.state.business_id)
    this.setState({
      business_profile_list: res_data,
      chosen_business:res_data[0].id,
    });
  }
  
  refreshData = () =>
  {
    refreshBusinessProfileList(this.handleSuccessfulRefresh,this.props.handleFailure)
  };



  handleSelectBusinessChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
        ...prevState,
        [name]: value,
    }));
  };
  
  handleSuccessfulSubmit = (res) => {
    this.copyCheck();
    this.props.refreshData();
    this.props.toggleModal();
    this.props.handleSuccessfulMenuCopy();
  }
  
  copyCheck = () => {
    const prevModal = this.state.copy_check_modal;
    this.setState({
      copy_check_modal:!prevModal,
      business_profile_menu_source_id:this.state.chosen_business,
      action_message: "Are you sure you want to copy the menu from this business? It will overwrite your current menu."
    })
  }

  copyMenu = () => {
    copyMenuFromAnotherBusiness(this.state.business_id, this.state.menu_id, this.state.business_profile_menu_source_id, this.handleSuccessfulSubmit, this.props.handleFailure)
  }


  render = ()=> {

  const { toggleModal} = this.props;

  return (
    
    <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Copy Menu</DialogTitle>
      <DialogContent>
      {(this.state.copy_check_modal) && <ActionCheckModal toggle={this.copyCheck} message = {this.state.action_message} action={this.copyMenu} /> }

        <Stack direction="column" spacing={3} alignItems="center">
          <DialogContentText>
          Copy your menu from another business on the list. Note: This will overwrite your current menu.
          </DialogContentText>
          <Stack justifyContent={"flex-start"} spacing={1}>
            <FormControl fullWidth>
                <Select
                  value={this.state.chosen_business}
                  onChange={this.handleSelectBusinessChange}
                  name="chosen_business"
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {this.state.business_profile_list.map((business) => (
                    <MenuItem key={business.id} value={business.id}>
                      {business.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Stack>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button 
            variant="contained"
            color="secondary" 
            onClick= { () => this.copyCheck()}
            >
              Copy Menu
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withErrorHandling(MartipiaMenuCopyModal);