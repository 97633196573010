import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// components
import MTipLogo from '../../components/misc/MTipLogo';
import {Stack, Typography} from "@material-ui/core"

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function DemoLogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
      <Stack direction="row">
        <Stack alignItems="center" justifyContent="flex-start">
        <Typography variant="h6">MARTIPIA</Typography>
          <RouterLink to="/demo">
              <MTipLogo sx ={{width:45, height:45}}/>
          </RouterLink>
        </Stack>
      </Stack>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
