import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
// import outlineRateReview from '@iconify/icons-ic/outline-rate-review';
import outlineAccountBalanceWallet from '@iconify/icons-ic/outline-account-balance-wallet';

import person from '@iconify/icons-ic/person';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Martipia Manager',
    path: '/mtip/manager',
    icon: getIcon(homeFill)
  },
  {
    title: 'Martipia Wallet',
    path: '/mtip/wallet',
    icon: getIcon(outlineAccountBalanceWallet)
  },
  // {
  //   title: 'Martipia Tip And Rate',
  //   path: '/mtip/tip-and-rate',
  //   icon: getIcon(outlineRateReview)
  // },
  {
    title: 'User Settings',
    path: '/mtip/user',
    icon: getIcon(person)
  }
];

export default sidebarConfig;
