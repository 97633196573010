import { Component } from 'react';
import withMessageSuccessErrorModal from './withMessageSuccessErrorModal';

function withErrorHandling(WrappedComponent){
  class WithErrorHandling extends Component {
    constructor(props) {
      super(props);

      this.state = {
      };
    }

    handleFailure = (error) => {
        console.log(error);
        this.setErrorTrue();
        this.setLoadingFalse();
        this.props.closeLoadingModal();
        this.setSuccessFalse();
        if (error.header==='Session Expired') {
          this.props.openSessionExpiredModal(error);
        }
        else {

          if (error.header) {
            this.props.setErrorModalHeader(error.header);
          }
          if (error.detail) {
            this.props.setErrorModalMessage(error.detail);
          }
          this.props.openErrorModal();
        }
    }

    handleSuccessNoMessage = () => {
      this.setSuccessTrue();
      this.setLoadingFalse();
      this.props.closeLoadingModal();
      this.setErrorFalse();
    }

    handleSuccess = (res) => {
      const message = res.data ? res.data : res;
      this.setSuccessTrue();
      this.setLoadingFalse();
      this.props.closeLoadingModal();
      this.setErrorFalse();
      if (message.header) {
        this.props.setSuccessModalHeader(message.header);
      }
      if (message.detail) {
        this.props.setSuccessModalMessage(message.detail);
      }
      this.props.openSuccessModal();
    }

    handleMessage = (res) => {
      const message = res.data ? res.data : res;
      this.setSuccessFalse();
      this.setLoadingFalse();
      this.props.closeLoadingModal();
      this.setErrorFalse();

      if (message.header) {
        this.props.setMessageModalHeader(message.header);
      }
      if (message.detail) {
        this.props.setMessageModalMessage(message.detail);
      }
      this.props.openMessageModal();
    }
    
    handleLoading = (_message) => {
      const message = _message ? _message : {};
      this.setSuccessFalse();
      this.setLoadingTrue();
      this.setErrorFalse();
      if (message.header) {
        this.props.setLoadingModalHeader(message.header);
      }
      if (message.detail) {
        this.props.setLoadingModalMessage(message.detail);
      }
      this.props.openLoadingModal();
    }

    setLoadingTrue = () => this.setState({ loading: true });
    setLoadingFalse = () => this.setState({ loading: false });

    setSuccessTrue = () => this.setState({ success: true });
    setSuccessFalse = () => this.setState({ success: false });

    setErrorTrue = () => this.setState({ error: true });
    setErrorFalse = () => this.setState({ error: false });

    render() {
      // When there's no error, render children components
      return <WrappedComponent 
        {...this.props}
        handleFailure={this.handleFailure}
        handleSuccess={this.handleSuccess}
        handleMessage={this.handleMessage}
        handleSuccessNoMessage={this.handleSuccessNoMessage}
        handleLoading={this.handleLoading}

        setLoadingTrue={this.setLoadingTrue}
        setLoadingFalse={this.setLoadingFalse}
        setSuccessTrue={this.setSuccessTrue}
        setSuccessFalse={this.setSuccessFalse}
        setErrorTrue={this.setErrorTrue}
        setErrorFalse={this.setErrorFalse}

        loading={this.state.loading}
        success={this.state.success}
        error={this.state.error}
        />;
    }
  }
  return withMessageSuccessErrorModal(WithErrorHandling);
};

export default withErrorHandling;
