import React, { Component } from "react";
// material
import { Stack, Button, Typography, } from '@material-ui/core';

import WithdrawalDestinationInfoModal from './WithdrawalDestinationInfoModal';

import {getWithdrawalDestinationInfo, submitWithdrawalDestinationInfo, deleteWithdrawalDestinationInfo} from "./scripts";
import DeleteModal from "../../misc/modals/DeleteModal";
import withErrorHandling from "../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES } from "../../../config/systemMessages";

class ServiceProfessionalWithdrawalDestination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_item: '',
      withdrawal_destination_info: '',
      modal:"",
    };
  }
  
  
  componentDidMount () {
    this.refreshData();
  }

  handleSuccessfulInfoRefresh = (res) => {
    this.setState({
      withdrawal_destination_info: res.data,
    });

  }
  

  refreshData = () =>
  {
    getWithdrawalDestinationInfo(this.handleSuccessfulInfoRefresh, this.props.handleFailure)
  };

  
  submitWithdrawalDestinationInfo = (item) => {
    submitWithdrawalDestinationInfo(item, this.handleSuccesfulSubmit, this.props.handleFailure) 
  }

  handleSuccesfulSubmit = (res) => {
    this.toggleModal();
    this.props.handleSuccess(SUCCESS_RESPONSES.WITHDRAWAL_DESTINATION_INFO_UPDATED);
    this.handleSuccessfulInfoRefresh(res)
  }

  deleteCheck = (item) => {
    const prevModal = this.state.delete_modal;
    this.setState({
      delete_modal:!prevModal,
      item_to_delete:item,
    })
  }

  deleteWithdrawalDestinationInfo = (item) => {
    deleteWithdrawalDestinationInfo(this.state.item_to_delete, this.handleSuccessfulInfoRefresh, this.props.handleFailure)
    this.deleteCheck("")
  };

  editWithdrawalDestinationInfo = (item) => {
    const prevModal = this.state.modal;
      this.setState({ 
        active_item: item,
        modal: !prevModal,
      });
    };

  createWithdrawalDestinationInfo = () => {
  //  Update the state to have the input item as the active item and turn on the modal with this input item.
    this.setState({ 
      active_item: "",
    });
    this.toggleModal();
  };

  toggleModal = () => {
    const prevModal = this.state.modal;
    this.setState({ 
      modal: !prevModal,
    });
  };

  renderWithdrawalInfo = () => {
    
    const render = (
      <Stack spacing={3}>
        <Button variant="outlined" color="secondary" onClick= { () => this.editWithdrawalDestinationInfo(this.state.withdrawal_destination_info)} >
          Check or Edit
      </Button>
        <Button variant="outlined" color="error" onClick= { () => this.deleteCheck(this.state.withdrawal_destination_info)} >
          Delete
        </Button>
      </Stack>
    )
    return render;
  }

  renderNoWithdrawalInfo = () =>  {
    const render = (
      <Stack spacing={1}>
        <Typography variant="body2" >
        You don't have any withdrawal information set up.
        </Typography>
        <Button
            onClick = { () => this.createWithdrawalDestinationInfo()}
            size="small"
            color="primary"
            variant =  "outlined"
          >
            Provide Withdrawal Information
        </Button>
      </Stack>
    );
    return render;
  }

  render () {
    return  (
      <Stack direction="column" alignItems="center" spacing={1}>
        
        <Stack direction="column" alignItems="flex-start">
          <Typography variant="body2">Withdrawal Information</Typography>
        </Stack>
          {(this.state.delete_modal) && <DeleteModal toggle={this.deleteCheck} deleteAction={this.deleteWithdrawalDestinationInfo} 
          message="Are you sure you want to delete your withdrawal information?"/>}
          {this.state.withdrawal_destination_info 
          ? this.renderWithdrawalInfo()
          : this.renderNoWithdrawalInfo()
          }
          {this.state.modal 
            ? ( 
              <WithdrawalDestinationInfoModal
              active_item = {this.state.active_item}
              submitWithdrawalInfo = {this.submitWithdrawalDestinationInfo}
              toggleModal = {this.toggleModal}/>
              ) 
            : null}
      </Stack>
    );
  }
}
export default withErrorHandling(ServiceProfessionalWithdrawalDestination);