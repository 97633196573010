import { makeApiPostRequestAuthenticated, makeApiGetRequestAuthenticated } from "../../../../utils/apiRequest";

// Business Profile API

export function submitProfileInfo (item, handleSuccess, handleFailure) {
  if (item.id) 
  {
    makeApiPostRequestAuthenticated({
      url: '/api/update_business/',
      data: item,
    }, handleSuccess, handleFailure);
  }
  else
  {
    makeApiPostRequestAuthenticated({
      url: '/api/create_business/',
      data: item,
    }, handleSuccess, handleFailure);
  }
}
export function getBusinessData(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_business_details/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}
export function deleteBusinessProfile(item, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/delete_business/',
    data: item,
  }, handleSuccess, handleFailure);
}


export function uploadBusinessProfilePicture(formData, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/upload_business_picture/',
    data: formData,
  }, handleSuccess, handleFailure);
}

export function getEmployeeData(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_business_employees/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}



// Reviews API
  
export function getBusinessReviewsData(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_last_10_business_reviews/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}


export function getBusinessHighestTip(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_business_highest_tip/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}


// Integrations API
    
export function getIntegrationsInfo (id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_business_integrations/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}


export function submitIntegrationsInfo (business, platform, item,  handleSuccess, handleFailure) {
  const { override_code, override_integrations, id, ...restOfItem } = item;
  makeApiPostRequestAuthenticated({
    url: '/api/update_business_integrations/',
    data: {
      "data":restOfItem,
      "id":id,
      "override_code":override_code,
      "override_integrations":override_integrations,
      "business":business,
      "integrated_platform":platform,
    },
  }, handleSuccess, handleFailure);
}

// Thankyou API

export function getThankyouDataBusiness (id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_thankyou_data_business/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}

export function createThankyouData (id, card_type, card_data,handleSuccess,handleFailure) {
  var data = {
    "id":id,
    "type":card_type,
    "thankyou_data":card_data,
  }
  makeApiPostRequestAuthenticated({
    url : `/api/create_thankyou_data_business/`,
    data: data,
  }, handleSuccess, handleFailure);
}

export function toggleThankyouData(id, handleSuccess, handleFailure) {
    makeApiPostRequestAuthenticated({
      url: '/api/toggle_thankyou_card/',
      data: {
        id: id
      },
    }, handleSuccess, handleFailure);
  }


// Shared QR API

export function toggleSharedQRBusiness(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/toggle_shared_qr_business/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}

export function toggleSharedQRMenu(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/toggle_shared_qr_menu/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}
export function changeQRMenu(id, menu_data, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_shared_qr_menu/',
    data: {
      id: id,
      menu_data: menu_data
    },
  }, handleSuccess, handleFailure);
}

export function changeQREasyTip(id, easy_tip_profile, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_shared_qr_easy_tip_profile/',
    data: {
      id: id,
      easy_tip_profile: easy_tip_profile
    },
  }, handleSuccess, handleFailure);
}


export function toggleSharedQRSplit(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/toggle_shared_qr_split/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}

export function toggleSharedQRList(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/toggle_shared_qr_list/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}


export function toggleSharedQREasyTip(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/toggle_shared_qr_easy_tip_profile/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}

export function getSharedQRBusiness(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_shared_qr_business/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}

// Misc API

export function fireEmployee (business_id, service_professional_id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/business_fire_employee/',
    data: {
      "service_professional_id":service_professional_id,
      "id":business_id,
    },
  }, handleSuccess, handleFailure);
}


// Martipia Plus API
export function getMartpiaPlusSubscriptionInfo(id, handleSuccess, handleFailure) {
  makeApiGetRequestAuthenticated({
    url: '/api/get_martipia_plus_subscription_info/',
  }, handleSuccess, handleFailure);
}

export function enableMartipiaPlusLicense(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/enable_martipia_plus_license/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}

export function removeMartipiaPlusLicense(id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/remove_martipia_plus_license/',
    data: {
      id: id
    },
  }, handleSuccess, handleFailure);
}