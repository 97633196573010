import React, { Component } from "react";
import {Container, Stack, Card, CardHeader, CardContent, Divider, Button } from '@material-ui/core';
import ServiceProfessionalProfile from '../../serviceProfessionalProfile/ServiceProfessionalProfile';
import { getEmployeeData, fireEmployee} from "./scripts"; 
import ActionCheckModal from "../../../misc/modals/ActionCheckModal";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES } from "../../../../config/systemMessages";

class BusinessProfileEmployeesDashboard extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      id:props.id.id,
      message_modal:false,
      employees_info:"",
    };
  }

  componentDidMount ()
  {
      this.refreshData();
  }
  handleSuccessfulEmployeeRefresh = (res) => {
    this.setState({
      employees_info: res.data,
    });
  }
  

  refreshData = () =>
  {
    getEmployeeData(this.state.id, this.handleSuccessfulEmployeeRefresh, this.props.handleFailure)
  };


  fireEmployeeCheck = (business_id,service_professional_id) => {
    const prevModal = this.state.fire_employee_modal;
    this.setState({
      fire_employee_modal:!prevModal,
      employee_to_fire:{
        business_id:business_id,
        service_professional_id:service_professional_id,
      }
    })
  }
  
  fireEmployee = () => {
    fireEmployee(this.state.employee_to_fire.business_id, this.state.employee_to_fire.service_professional_id,this.handleFireEmployee, this.handleFailure)
    this.fireEmployeeCheck("","")
  };

  handleFireEmployee = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.EMPLOYEE_FIRED)
    this.refreshData()
  }

  fireEmployeeButton = (business_id,service_professional_id) => {
    return(
      <Button  variant="outlined" color="error" onClick= { () => this.fireEmployeeCheck(business_id, service_professional_id)} >
        Fire employee
      </Button>
    ) 
  }

renderEmployees = () => {
  const renderedProfiles = [];  
  this.state.employees_info.forEach((employeeInfo) => {
    renderedProfiles.push(ServiceProfessionalProfile(employeeInfo))
    renderedProfiles.push(this.fireEmployeeButton(employeeInfo.business, employeeInfo.id))
    })
  return (renderedProfiles);
}


  render() 
  {
    return  (
      <Container>
        <Card >
          <CardHeader title="🌰 Employees 🐿️" />
          <Divider />
          <CardContent>
            {(this.state.fire_employee_modal) ? <ActionCheckModal toggle={this.fireEmployeeCheck} message = "Are you sure you want to fire this employee?" action={this.fireEmployee} /> : ""}
            <Stack spacing={3} alignItems="center" justifyContent="center">
              {this.state.employees_info.length
              ? (this.renderEmployees())
              :"No employees are currently registered with this business."}
            </Stack>
          </CardContent>
          </Card>
      </Container>
    );
  }
}


export default withErrorHandling(BusinessProfileEmployeesDashboard);