
import React, { Component } from "react";

import { TextField, Stack, Button, Typography } from '@material-ui/core';



export default class RegisterWithBusinessForm extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      businessCode: "",
      serviceProfessionalProfileID: "",
    };
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    
    this.setState(prev_state=> {
        const new_state = {...prev_state};
        new_state[name] = value;
        return new_state;
    });
  };




  render = () => {
  const form = (
    <form onSubmit = { e => this.props.registerWithBusiness(e, this.state.businessCode)}>
      <Stack direction = 'column'>
        <Typography variant="body2">
          Enter the Business Code to register with your associated business.
        </Typography>
          <TextField
            margin="dense"
            label="Business Code"
            name="businessCode"
            type="text"
            value={this.state.businessCode}
            onChange={this.handleChange}
            placeholder="Business Code"
          >
          </TextField>
          <Button 
            type='submit'
            variant="contained" 
            color="inherit" 
            >
            Submit
          </Button>
      </Stack>
    </form>
    );
    return form;
  }
}
