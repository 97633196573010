
import PropTypes from 'prop-types';
// material
import { Stack, Typography,  } from '@material-ui/core';

import React, { Component } from "react";
import { Link as RouterLink} from 'react-router-dom';
import MTipLogo from './MTipLogo';
import LanguageToggle from '../../utils/languageToggle';
// ----------------------------------------------------------------------

MTipLogo.propTypes = {
  sx: PropTypes.object
};
const SmartLink = ({ to, children, ...props }) => {
  // Check if the link is external
  const isInternal = to.startsWith('/');

  if (isInternal) {
    return (
      <RouterLink to={to} {...props}>
        {children}
      </RouterLink>
    );
  }

  return (
    <a href={to} {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};



export default class MTipLogoAndModule extends Component {
  
    constructor(props) {
      super(props);
  
      this.state = {
        module_name:props.module_name,
        logo_link:props.logo_link,
      };
    }
    
   
  render = () => {
    return  (
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant='h6'> 
                MARTIPIA: {this.state.module_name}
            </Typography>
            <Stack direction="row" spacing={1}>
              <SmartLink to={this.state.logo_link}>
                  <MTipLogo sx ={{width:30, height:30}}/>
              </SmartLink>
              <LanguageToggle></LanguageToggle>
            </Stack>
        </Stack>
    );
  }
}
