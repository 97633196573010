import { makeApiPostRequestAuthenticated, makeApiGetRequestAuthenticated } from "../../../utils/apiRequest";

export function refreshBusinessProfileList ( handleSuccess, handleFailure) {
    makeApiGetRequestAuthenticated({
      url: '/api/get_businesses/',
    }, handleSuccess, handleFailure);
}

export function submitProfileInfo (item, handleSuccess, handleFailure) {
  if (item.id) 
  {
    makeApiPostRequestAuthenticated({
      url: '/api/update_business/',
      data: item,
    }, handleSuccess, handleFailure);
  }
  else
  {
    makeApiPostRequestAuthenticated({
      url: '/api/create_business/',
      data: item,
    }, handleSuccess, handleFailure);
  }
}