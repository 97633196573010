import React, { Component } from "react";
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Box, Grid, Stack , Typography, Button, CircularProgress} from '@material-ui/core';
import Scrollbar from '../../misc/navigation/Scrollbar';
import ServiceProfessionalProfileModal from '../serviceProfessionalProfile/ServiceProfessionalProfileModal';
import ServiceProfessionalReviews from './ServiceProfessionalReviews';
import ServiceProfessionalMartipiaAccount from './ServiceProfessionalMartipiaAccount';
import ServiceProfessionalProfile_wallet from "./ServiceProfessionalProfile_wallet";
import ServiceProfessionalIntegrationsModal from "../serviceProfessionalProfile/serviceProfessionalIntegrations/ServiceProfessionalIntegrationsModal";
import {registerWithBusiness, deleteServiceProfessionalProfile, getProfileInfo, submitProfileInfo, uploadServiceProfessionalPicture, getIntegrationsInfo, submitIntegrationsInfo, quitBusiness} from "./scripts"; 
import DeleteModal from "../../misc/modals/DeleteModal";
import ActionCheckModal from "../../misc/modals/ActionCheckModal";
import withErrorHandling from "../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES } from "../../../config/systemMessages";

class MTipWalletView extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      active_item: '',
      profile_info: '',
      modal:"",
      accepted_needed: true, 
      integrations_modal:false,
      integrations_info:"",
    };
  }
  
  componentDidMount () {
    this.refreshData();
  }
  refreshData = () =>
  {
    this.props.setLoadingTrue();
    getProfileInfo(this.handleSuccessfulProfileRefresh, this.props.handleFailure)
  };
  handleSuccessfulProfileRefresh = (res) => {
    this.setState({
      profile_info: res.data,
   });
   this.props.setLoadingFalse();;
  }
  
  submitProfileInfo = (item) => {
    delete item.serviceprofessionalpicture
    submitProfileInfo(item, this.handleSuccesfulProfileSubmit, this.props.handleFailure)
  };
  handleSuccesfulProfileSubmit = (res) => {
    this.toggleModal();
    this.props.handleSuccess(SUCCESS_RESPONSES.SERVICE_PROFESSIONAL_PROFILE_UPDATED);
    this.setState({
      profile_info: res.data,
   });
  }

  deleteServiceProfessionalProfile = (item) => {
    deleteServiceProfessionalProfile(this.state.item_to_delete, this.handleSuccesfulDeleteProfile, this.props.handleFailure)
};
  handleSuccesfulDeleteProfile = (res) => {
    this.setState({
      accepted_needed:true,
    })
    this.deleteCheck("")
    this.props.handleSuccess(SUCCESS_RESPONSES.SERVICE_PROFESSIONAL_PROFILE_DELETED);
    this.refreshData();
  }

  editServiceProfessionalProfile = (item) => {
    const prevModal = this.state.modal;
      this.setState({ 
        active_item: item,
        modal: !prevModal,
        accepted_needed: false,
      });
    };
  createServiceProfessionalProfile = () => {
    this.setState({ 
      active_item: "",
    });
    this.toggleModal();
  };

  quitBusiness = () => {
    quitBusiness(this.handleSuccesfulQuitBusiness, this.props.handleFailure)
  };
  handleSuccesfulQuitBusiness = (res) => {
    this.props.handleSuccess(res);
    this.quitBusinessCheck()
    this.refreshData();
  }


  deleteCheck = (item) => {
    const prevModal = this.state.delete_modal;
    this.setState({
      delete_modal:!prevModal,
      item_to_delete:item,
    })
  }
 
  quitBusinessCheck = () => {
    const prevModal = this.state.quit_modal;
    this.setState({
      quit_modal:!prevModal,
    })
  }
  




  registerWithBusiness = (e, business_id) => {
      var data= {
        "business":business_id,
      }
      registerWithBusiness(data, this.handleSuccesfulRegisterWithBusiness, this.props.handleFailure) 
      e.preventDefault();
  }
  handleSuccesfulRegisterWithBusiness = (res) => {
    this.props.handleSuccess(res);
    this.refreshData();
  }

  uploadServiceProfessionalPicture = (event, id, picture) => {
    
    const myFile = event.target.files[0]
    const formData = new FormData()
    formData.append("file", myFile)
    formData.append("service_professional",id)

    if (!picture)  
    {  
      this.props.handleLoading()
      uploadServiceProfessionalPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
    }
    else
    {
      formData.append("id",picture.id)
      this.props.handleLoading()
      uploadServiceProfessionalPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
      };
    }
    
  handleSuccessfulPictureUpload = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.SERVICE_PROFESSIONAL_PROFILE_PICTURE_UPDATED)
    this.refreshData(res)
  }


  toggleModal = () => {
    const prevModal = this.state.modal;
    this.setState({ 
      modal: !prevModal,
    });
  };


  renderProfileInfo = () => {
    var item = (
      <Stack alignItems="center" spacing={3}>
        {ServiceProfessionalProfile_wallet(
          this.state.profile_info,
          this.registerWithBusiness,
          this.editServiceProfessionalProfile,
          this.deleteCheck,
          this.quitBusinessCheck,
          this.uploadServiceProfessionalPicture,
          this.viewIntegrations)}
        <ServiceProfessionalMartipiaAccount autoWithdrawalEnabled={this.state.profile_info.auto_withdrawal_enabled} tipInfo = {this.state.profile_info["tip_balance"]} pendingWithdrawalInfo = {this.state.profile_info['tip_pending_withdrawal']}/>
        <ServiceProfessionalReviews/>
      </Stack>
    )
    return item
  }

  renderNoProfileInfo = () =>  {
    const render = (
    <Stack direction="column" alignItems="left" spacing={2}>
      <Box>      
        <Typography variant="body2" noWrap>
        You don't have a Martipia Profile. Please create one.
        </Typography>
      </Box>
      <Box>
        <Button
            onClick = { () => this.createServiceProfessionalProfile()}
            size="small"
            color="primary"
            variant =  "contained"
            fullWidth={false}
            endIcon={<Icon icon={arrowIosForwardFill} />}
          >
            Create Profile
        </Button>
      </Box>
    </Stack>
    );
    return render;
  }
  
  
  submitIntegrationsInfo = (platform, item) =>
  {
    submitIntegrationsInfo(this.state.profile_info.id, platform, item, this.handleSuccesfulIntegrationsSubmit, this.props.handleFailure);
  };

  handleSuccesfulIntegrationsSubmit = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.SERVICE_PROFESSIONAL_INTEGRATIONS_UPDATED)
    this.handleSuccessfulServiceProfessionalIntegrationsGet(res)
  }

  viewIntegrations = () => {
    getIntegrationsInfo(this.state.profile_info.id, this.handleSuccessfulServiceProfessionalIntegrationsGet, this.props.handleFailure)
   };

  handleSuccessfulServiceProfessionalIntegrationsGet = (res) => {
    this.setState({
      integrations_info: res.data,
    })
    this.toggleIntegrationsModal()
  }
  
  toggleIntegrationsModal = () =>
  {
    const prevModal = this.state.integrations_modal;
    this.setState({ 
      integrations_modal: !prevModal,
    });
  };

  render = () => {
    return  (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <Scrollbar>
              {(this.state.integrations_modal) ? <ServiceProfessionalIntegrationsModal integrations_info = {this.state.integrations_info} submitIntegrationsInfo = {this.submitIntegrationsInfo} toggleModal = {this.toggleIntegrationsModal}/> : null}  
              {(this.state.delete_modal) ? <DeleteModal toggle={this.deleteCheck} deleteAction={this.deleteServiceProfessionalProfile} /> : ""}
              {(this.state.quit_modal) ? <ActionCheckModal toggle={this.quitBusinessCheck} message = "Are you sure you want to quit this business?" action={this.quitBusiness} /> : ""}
              

              {this.props.loading &&<Stack direction="column" alignItems="center" ><Typography>Your information is loading... </Typography><CircularProgress/> </Stack>}
              {!this.props.loading && ( this.state.profile_info.id  ? this.renderProfileInfo() : this.renderNoProfileInfo())}
              {this.state.modal 
              ? ( 
              <ServiceProfessionalProfileModal
              active_item = {this.state.active_item}
              submitProfileInfo = {this.submitProfileInfo}
              toggleModal = {this.toggleModal}
              accepted_needed={this.state.accepted_needed}
              />
              ) 
              : null}
            </Scrollbar>
          </Grid>
        </Grid>
      </Box>
          
      );
    }
  }

  export default withErrorHandling(MTipWalletView);