import { makeApiPostRequestAuthenticated } from "../../../../utils/apiRequest";

// Menu Settings

export function getMartipiaMenuSettings(business_id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_martipia_menu_settings/',
    data: {
      business: business_id,
    },
  }, handleSuccess, handleFailure);
}

export function changeMenuType(id, menu_type, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_shared_qr_menu_type/',
    data: {
      id: id,
      menu_type: menu_type
    },
  }, handleSuccess, handleFailure);
}

export function toggleMenuBanner(business_id, menu_id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/toggle_menu_banner/',
    data: {
      business: business_id,
      id: menu_id,
    },
  }, handleSuccess, handleFailure);
}

export function uploadBannerPicture(formData, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/upload_menu_banner_picture/',
    data: formData,
  }, handleSuccess, handleFailure);
}

export function changeMenuTags(business_id, menu_id, tags, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_tags/',
    data: {
      business: business_id,
      id: menu_id,
      tags: tags,
    },
  }, handleSuccess, handleFailure);
}

export function changeGreetingText(business_id, menu_id, greeting_text_default, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_greeting_text/',
    data: {
      business: business_id,
      id: menu_id,
      greeting_text_default: greeting_text_default,
    },
  }, handleSuccess, handleFailure);
}

export function changeGreetingTextTranslations(business_id, menu_id, greeting_text_translations, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_greeting_text_translations/',
    data: {
      business: business_id,
      id: menu_id,
      greeting_text_translations: greeting_text_translations,
    },
  }, handleSuccess, handleFailure);
}

export function changeSubgreetingText(business_id, menu_id, greeting_subtext_default, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_greeting_subtext/',
    data: {
      business: business_id,
      id: menu_id,
      greeting_subtext_default: greeting_subtext_default,
    },
  }, handleSuccess, handleFailure);
}

export function changeSubgreetingTextTranslations(business_id, menu_id, greeting_subtext_translations, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_greeting_subtext_translations/',
    data: {
      business: business_id,
      id: menu_id,
      greeting_subtext_translations: greeting_subtext_translations,
    },
  }, handleSuccess, handleFailure);
}


export function copyMenuFromAnotherBusiness(business_id, menu_id, business_profile_menu_source_id, handleSuccess, handleFailure) {
  console.log(business_id, menu_id, business_profile_menu_source_id)
  makeApiPostRequestAuthenticated({
    url: '/api/copy_menu_from_another_business/',
    data: {
      business: business_id,
      id: menu_id,
      business_profile_menu_source_id: business_profile_menu_source_id,
    },
  }, handleSuccess, handleFailure);
}

// Menu Categories Setting

export function getMenuCategories(business_id, menu_id, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/get_menu_categories/',
    data: {
      business: business_id,
      id: menu_id,
    },
  }, handleSuccess, handleFailure);
}

export function changeMenuCategoryName(business_id, menu_id, category, handleSuccess, handleFailure) {
  if (category.id) 
  {
    makeApiPostRequestAuthenticated({
      url: '/api/change_menu_category_name/',
      data: 
      {
        business: business_id,
        menu_id: menu_id,
        name_default: category.name_default,
        id: category.id,
      },
    }, handleSuccess, handleFailure);
  }
  else
  {
    makeApiPostRequestAuthenticated({
      url: '/api/create_menu_category/',
      data: 
      {
        business: business_id,
        menu_id: menu_id,
        name_default: category.name_default,
      },
    }, handleSuccess, handleFailure);
  }
}

export function changeMenuCategoryNameTranslations(business_id, menu_id, category, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_category_name_translations/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      name_translations: category.name_translations,
      id: category.id,
    },
  }, handleSuccess, handleFailure);
}

export function changeMenuCategoryOrderPosition(business_id, menu_id, category, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_category_order_position/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      order_position: category.order_position,
      id: category.id,
    },
  }, handleSuccess, handleFailure);
}

export function changeMenuCategoryDisplayType(business_id, menu_id, category,display_type, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_category_display_type/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      display_type: display_type,
      id: category.id,
    },
  }, handleSuccess, handleFailure);
}

export function changeMenuCategoryEmoji(business_id, menu_id, category, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_category_emoji/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      emoji: category.emoji,
      id: category.id,
    },
  }, handleSuccess, handleFailure);
}

export function uploadCategoryPicture(formData, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/upload_menu_category_picture/',
    data: formData,
  }, handleSuccess, handleFailure);
}

export function deleteMenuCategory(business_id, menu_id, category, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/delete_menu_category/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      id: category.id,
    },
  }, handleSuccess, handleFailure);
}
// Menu Items APIs

export function changeMenuItem(business_id, menu_id, item, handleSuccess, handleFailure) {
  
  const _data = {
    ...item, // Spread operator copies all properties from `item` into `_data`
    business: business_id, // Shorthand for business_id: business_id
    menu_id, // Shorthand for menu_id: menu_id
  };
  if (_data.hasOwnProperty('martipiamenuitempicture')) {
    // If _data has the property 'martipiamenuitempicture', you can extract it or simply delete it
    delete _data.martipiamenuitempicture; // Removes the property from the object
  }

  makeApiPostRequestAuthenticated({
    url: '/api/change_menu_item/',
    data:_data,
  }, handleSuccess, handleFailure);
}

export function uploadItemPicture(formData, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/upload_menu_item_picture/',
    data: formData,
  }, handleSuccess, handleFailure);
}

export function deleteMenuItem(business_id, menu_id, item, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/delete_menu_item/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      id: item.id,
    },
  }, handleSuccess, handleFailure);
}

export function copyMenuItem(business_id, menu_id, item, handleSuccess, handleFailure) {
  makeApiPostRequestAuthenticated({
    url: '/api/copy_menu_item/',
    data: 
    {
      business: business_id,
      menu_id: menu_id,
      item_id: item.id,
    },
  }, handleSuccess, handleFailure);
}