import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import { Typography, Select, MenuItem } from '@material-ui/core';

class LanguageToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [  
        { code: 'en', name: 'English', flag: '🇺🇸' },
        { code: 'ka', name: 'ქართული', flag: '🇬🇪' },
        { code: 'ru', name: 'Русский', flag: '🇷🇺' },
      ],
      selectedLanguage: props.i18n.language
    };
  }

  componentDidMount() {
    const { i18n } = this.props;
    this.setState({ selectedLanguage: i18n.language });
  }

  componentDidUpdate(prevProps) {
    const { i18n } = this.props;
    if (i18n.language !== prevProps.i18n.language) {
      this.setState({ selectedLanguage: i18n.language });
    }
  }

  handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    const { i18n } = this.props;
  
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage); // Save to localStorage
    this.setState({ selectedLanguage: newLanguage });
  };
  
  render() {

    return (
        <Select
          size='small'
          value={this.state.selectedLanguage}
          onChange={this.handleLanguageChange}
          style={{ fontSize: '0.75rem' }}
          renderValue={(selected) => this.state.languages.find(lang => lang.code === selected)?.flag}    
        >
          {this.state.languages.map((lang) => (
            <MenuItem key={lang.code} value={lang.code}>
              <Typography variant='caption'>
                {lang.name} {lang.flag}
              </Typography>
            </MenuItem>
          ))}
        </Select>
    );
  }
}

export default withTranslation()(LanguageToggle);
