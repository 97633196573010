import { Container,  Card, CardHeader, CardContent, Typography, }  from '@material-ui/core';
import React, { Component } from "react";
import {
  Stack,

} from '@material-ui/core';
import MTipReview from "./MTipReview"
import MTipReviewReport from './MTipReviewReport';


export default class MTipReviewAnalytics extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      reviews: props.reviews,
      is_business: (props.is_business) ? props.is_business : false,
      business_id:(props.business_id) ? props.business_id : "",
      highest_tip: props.highest_tip,
    };
  }

  
renderReviews = () => {
  const renderedReviews = [];
  this.state.reviews.forEach((item) => (
    renderedReviews.push(<MTipReview item={item}/>)
  ))
  return (renderedReviews);
}



render = () => {
  return (
    
    <Container>
        <Stack direction="column" spacing = {1}>
            
          <Card >
              <CardHeader title="Reports 📝" />
              <CardContent>
                <Typography>All tip sums are <b>after</b> processing fees have been deducted.</Typography>
                <MTipReviewReport is_business={this.state.is_business} business_id={this.state.business_id}/>
              </CardContent>
          </Card>
          {
          (this.state.highest_tip)
            ?(
          <Card >
              <CardHeader title="Highest tip ⭐" />
              <CardContent>
                <MTipReview item={this.state.highest_tip}/>
              </CardContent>
          </Card>
          ) : (
            ""
          )
        }

          {/* <Card>
              <CardHeader title="Time Analytics 📅" />
              <CardContent>
                  <MTipReviewAnalyticsChart/>
              </CardContent>
          </Card> */}

          <Card >
              <CardHeader title="Last 10 Tips 🤑" />
              <CardContent>
                <Stack direction="column" spacing = {2}>
                    {this.renderReviews()}
                </Stack>
              </CardContent>
          </Card>

        </Stack>
    </Container>
  );
  }
}