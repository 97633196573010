import { makeApiPostRequestAnonymous } from "../../../../utils/apiRequest";

export function getMartipiaMenu(shared_qr_id, handleSuccess, handleFailure) {
    makeApiPostRequestAnonymous({
    url: '/api/get_martipia_menu/',
    data: {
      shared_qr: shared_qr_id,
    },
  }, handleSuccess, handleFailure);
}
