// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/misc/navigation/ScrollToTop';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './config/i18n';
// ----------------------------------------------------------------------

import ReactGA from "react-ga4";

//
ReactGA.initialize(process.env.REACT_APP_GOOGLE_KEY);

export default function App() {
  const location = useLocation();

  useEffect(() => {
    // Log the initial page load
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeConfig>
        <ScrollToTop />
        <Router />
      </ThemeConfig>
    </LocalizationProvider>
  );
}
