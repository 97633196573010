import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import {CardMedia, Stack, Typography, TextField,ToggleButtonGroup, ToggleButton,  } from "@material-ui/core";
import {changeMenuCategoryNameTranslations, deleteMenuCategory, changeMenuCategoryOrderPosition, uploadCategoryPicture, changeMenuCategoryDisplayType, changeMenuCategoryEmoji, changeMenuCategoryName } from "./menu_api";
import DeleteModal from "../../../misc/modals/DeleteModal";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES} from "../../../../config/systemMessages";


class MartipiaMenuCategoryModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      category:props.category,
      menu_id:props.menu_id,
      business_id:props.business_id
    };
  }


  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState(prevState => ({
      category: {
        ...prevState.category,
        [name]: value
      }
    }));
  };
  
  
  handleChangeTranslation = (e) => {
    const { name, value } = e.target;
    // Splitting based on the period as a delimiter
    const [translationField, languageCode] = name.split('.');
  
    this.setState(prevState => ({
      category: {
        ...prevState.category,
        [translationField]: {
          ...prevState.category[translationField],
          [languageCode]: value,
        },
      },
    }));
  };

  changeMenuCategoryName = () => {
    changeMenuCategoryName(this.state.business_id, this.state.menu_id, this.state.category, this.handleSuccessfulSubmit, this.props.handleFailure);
  };
  
  changeMenuCategoryNameTranslations = () => {
    changeMenuCategoryNameTranslations(this.state.business_id, this.state.menu_id, this.state.category, this.handleSuccessfulSubmit, this.props.handleFailure);
  };
  
  changeMenuCategoryOrderPosition = () => {
    changeMenuCategoryOrderPosition(this.state.business_id, this.state.menu_id, this.state.category, this.handleSuccessfulSubmit, this.props.handleFailure);
  };
  
  changeMenuCategoryEmoji = () => {
    changeMenuCategoryEmoji(this.state.business_id, this.state.menu_id, this.state.category, this.handleSuccessfulSubmit, this.props.handleFailure);
  };

  handleCategoryDisplayChoice = (e, newMenuTypeChoice) => {
    changeMenuCategoryDisplayType(this.state.business_id, this.state.menu_id, this.state.category, newMenuTypeChoice, this.handleSuccessfulSubmit, this.props.handleFailure);
  };

  handleSuccessfulSubmit = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_CATEGORY_EDIT_SUCCESS);
    this.props.refreshData()
    this.setState({
      category: res.data
    })
  }

  
  uploadCategoryPicture = (event) => {
    const myFile = event.target.files[0]
    const formData = new FormData()
    formData.append("file", myFile)
    formData.append("business",this.state.business_id)
    formData.append("menu_id",this.state.menu_id)
    formData.append("category_id",this.state.category.id)
    if (!this.state.category.martipiamenucategorypicture)  
    { 
      this.props.handleLoading()
      uploadCategoryPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
    }
    else
    {
      formData.append("id",this.state.category.martipiamenucategorypicture.id)
      this.props.handleLoading()
      uploadCategoryPicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
      };
    }


    handleSuccessfulPictureUpload = (res) => {
      this.props.handleSuccess(SUCCESS_RESPONSES.MENU_CATEGORY_PICTURE_UPLOAD_SUCCESS);
      //insert the new picture into the menu settings state value
      this.setState(prevState => ({
        category: {
          ...prevState.category,
          martipiamenucategorypicture: res.data,
        },
      }));
    }


  deleteCheck = (item) => {
    const prevModal = this.state.delete_modal;
    this.setState({
      delete_modal:!prevModal,
      item_to_delete:item,
      delete_message: "Are you sure you want to delete this category?"
    })
  }

  deleteMenuCategory = () => {
    deleteMenuCategory(this.state.business_id, this.state.menu_id, this.state.item_to_delete, this.handleSuccesfulDelete, this.props.handleFailure)
    this.deleteCheck("")
  };

  handleSuccesfulDelete = () => {
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_CATEGORY_DELETE_SUCCESS);
    this.props.refreshData()
    this.props.toggleModal()
  }



  render = ()=> {

  const { toggleModal} = this.props;

  return (
    
    <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Category</DialogTitle>
      <DialogContent>
      {(this.state.delete_modal) ? <DeleteModal toggle={this.deleteCheck} deleteAction={this.deleteMenuCategory} message={this.state.delete_message} /> : ""}
        <Stack direction="column" spacing={3} alignItems="center">
          <DialogContentText>
          Edit your category. You may provide a name, and a picture or an emoji to represent the category. You can edit the picture/emoji after you save the item for the first time.
          </DialogContentText>
          <Stack justifyContent={"flex-start"} spacing={1}>

          <Typography align={"left"} variant="body2">
              Please enter the category name. If you don't have a translation, it will be used as the default.
            </Typography>
            <Stack direction = 'row' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="Category Name"
                name="name_default"
                type="text"
                value={this.state.category.name_default || ""}
                onChange={this.handleChange}
                placeholder="Category Name"
              >
              </TextField>
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeMenuCategoryName()}
                >
                Save
              </Button>
            </Stack>

          {this.state.category.id && (
          <div>

            
          <Typography align={"left"} variant="body2">
              Please enter the translations for the category name.
            </Typography>
              <Stack direction='column' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="Georgian Category Name"
                name="name_translations.ka"
                type="text"
                value={this.state.category.name_translations?.ka || ""}
                onChange={this.handleChangeTranslation }
                placeholder="Georgian translation"
              />
              <TextField
                margin="dense"
                fullWidth
                label="English Category Name"
                name="name_translations.en" // Notice the dot notation
                type="text"
                value={this.state.category.name_translations?.en || ""}
                onChange={this.handleChangeTranslation}
                placeholder="English translation"
              />
              <TextField
                margin="dense"
                fullWidth
                label="Russian Category Name"
                name="name_translations.ru"
                type="text"
                value={this.state.category.name_translations?.ru || ""}
                onChange={this.handleChangeTranslation }
                placeholder="Russian translation"
              />
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeMenuCategoryNameTranslations()}
                >
                Save Translations
              </Button>
            </Stack>

            <Typography align={"left"} variant="body2">
              Please enter the category position. 
            </Typography>
            <Typography align={"left"} variant="body2">
            <b>1</b> means it will be displayed first, <b>2</b> - second, etc.
            </Typography>
            <Stack direction = 'row' spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="Category Position"
                name="order_position"
                type="number"
                value={this.state.category.order_position || ""}
                onChange={this.handleChange}
                placeholder="Category Position"
              >
              </TextField>
              <Button 
                type='submit'
                size="small"
                variant="text" 
                color="inherit" 
                onClick={() => this.changeMenuCategoryOrderPosition()}
                >
                Save
              </Button>
            </Stack>

            <Typography align={"left"} variant="subtitle">
              Choose category display type:
            </Typography>
            <ToggleButtonGroup
            color="primary"
            value={this.state.category.display_type}
            exclusive
            onChange={this.handleCategoryDisplayChoice}
            aria-label="category display type"
            >
              <ToggleButton value="picture">Picture</ToggleButton>
              <ToggleButton value="emoji">Emoji</ToggleButton>
            </ToggleButtonGroup>
            
            {this.state.category.display_type === 'picture' && (
              <Stack>
              <Typography align={"left"} variant='subtitle'>
                Upload category image.
              </Typography>
              <Button
              variant="outlined"
              component="label"
              >
                Upload
                <input
                  type="file"
                  hidden
                  onChange = {(e) => this.uploadCategoryPicture(e)}
                  />
              </Button>
            
              {this.state.category.martipiamenucategorypicture && <CardMedia
                component="img"
                height="140"
                image={this.state.category.martipiamenucategorypicture.file}
                alt={this.state.category.name}
                />
              }
            </Stack>
          )}

              {this.state.category.display_type === 'emoji' && (
                <div>
                  <Typography align={"left"} variant="body2">
                    Please enter the emoji you'd like to use for this category. You can find some here <a href="https://emojipedia.org/" target="_blank" rel="noreferrer">Emojipedia</a>
                  </Typography>
                  <Stack direction = 'row' spacing={2}>
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Emoji"
                      name="emoji"
                      type="text"
                      value={this.state.category.emoji || ""}
                      onChange={this.handleChange}
                      placeholder="Category Emoji"
                      >
                    </TextField>
                    <Button 
                      type='submit'
                      size="small"
                      variant="text" 
                      color="inherit" 
                      onClick={() => this.changeMenuCategoryEmoji()}
                      >
                      Save
                    </Button>
                  </Stack>
                </div>
              )}
          </div>
          )}

          </Stack>
        </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button 
            variant="outlined"
            color="error" 
            disabled={!this.state.category.id}
            onClick= { () => this.deleteCheck(this.state.category)}
            >
              Delete Category
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withErrorHandling(MartipiaMenuCategoryModal);