import { makeApiPostRequestAnonymous} from "../../../utils/apiRequest";

export  function signUp(e, input_data, handleSuccess, handleFailure) {
  // Event handling.
  e.preventDefault();
  const data = {}
  data['username']=input_data.username
  data['password']=input_data.password
  data['first_name']=input_data.first_name
  data['last_name']=input_data.last_name
  data['email']=input_data.email
  data['sign_up_code']=input_data.sign_up_code
  data['accepted']=input_data.terms_conditions_privacy_policy

  makeApiPostRequestAnonymous({
    url: '/api/create_user/',
    data: data,
    headers: {
      "Content-Type" : 'application/json',
    }
  }, handleSuccess, handleFailure);
}