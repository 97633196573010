import { makeApiPostRequestAnonymous } from '../../../utils/apiRequest';


export function requestResetPassword(e, input_data, handleResetRequest, handleFailedResetRequest) {
  // Event handling.
  e.preventDefault();
  makeApiPostRequestAnonymous({
    url: '/api/password_reset/',
    data: {
      email: input_data.email,
    },
    headers: {
      "Content-Type" : 'application/json',
    },
  }, handleResetRequest, handleFailedResetRequest);
}

export function resetPassword (e, input_data,handleReset, handleFailedReset) {
  // Event handling.
  e.preventDefault();
  makeApiPostRequestAnonymous({
    url: '/api/password_reset_confirm/',
    headers:
    {
      "Content-Type" : 'application/json',
    },
    data: 
    {
      uid: input_data.uid,
      token: input_data.token,
      new_password1: input_data.password,
      new_password2: input_data.confirmed_password,
    }
  }, handleReset, handleFailedReset);
}