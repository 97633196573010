
import Rating from '@material-ui/lab/Rating';
import { Icon } from '@iconify/react';
import MTipLogo from '../../../misc/MTipLogo';
import ImageCard from "../../../misc/ImageCard";

import question_circle from '@iconify/icons-ant-design/question-circle';
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, MenuItem, Stack, Typography,  InputAdornment, Grid,Checkbox } from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';



export default class DemoTipAndRateReviewModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      tip_loaded:false,
      tip_loading:false,
      open: true,
      id:props.id,
      active_item: props.active_item,
      terms_conditions_privacy_policy:true,
      cover_comission:true,

      
      payment_options: [
        {
          value:"card",
          label:"Card/ბარათი",
        },
        {
          value:"apple_pay",
          label:"Apple Pay. Coming soon!",
          disabled:true,
        }
        
      ],
      review: {
        comment: "",
        rating: "",
      },
      tip: {
        type:"card",
        amount:"3",
      }
    };
  }

  handleTipNumberChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const prevItem = this.state.tip;
    const newTip = { ...prevItem, [name]: value };
    const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        tip: newTip,
      });
    }
  }

  handleTipChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const prevItem = this.state.tip;
    const newTip = { ...prevItem, [name]: value };
    this.setState({
      tip: newTip,
    });
  };


  handleReviewChange = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const prevItem = this.state.review;
    const newReview = { ...prevItem, [name]: value };
    this.setState({
      review: newReview,
    });
  };


  handleCheckbox = e => {
    const value = e.target.checked
    this.setState({
      terms_conditions_privacy_policy: value,
    });
}

handleComissionCheckbox = e => {
  const value = e.target.checked
  this.setState({
    cover_comission: value,
  });
}


  
  render = ()=> {
    const { toggleModal, submitReview } = this.props;
    const longText = "Check this box if you want to cover the 7.9% transaction fee for the tip.";
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
            <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
                <Stack  direction="row" alignItems="flex-start" justifyContent="space-between">
                  <Typography variant='body'> 
                    MARTIPIA: Tip & Rate
                  </Typography>
                  <MTipLogo sx ={{width:30, height:30}}/>
                </Stack>
              </DialogTitle>
              <DialogContent >
                <Stack direction = "column" spacing = {1} alignItems="center">
                  {ImageCard(
                    this.state.active_item.serviceprofessionalpicture.file,
                    this.state.active_item.name,
                    this.state.active_item.bio,
                  )}
                  <Stack direction = "column" alignItems="center" justifyContent = "space-around" >
                    <Rating
                      size="large"
                      name="rating"
                      readOnly
                      value={this.state.active_item.rating}
                    />
                    <DialogContentText>
                      {this.state.active_item.number_of_reviews} reviews / მიმოხილვა​
                    </DialogContentText>
                    <Typography variant="body">
                      {this.state.active_item.role}
                    </Typography>
                    <Typography variant="body">
                      <b>@</b>
                    </Typography>
                    <Typography variant="body">
                      {this.state.active_item.business_name}
                    </Typography>
                  </Stack>
                </Stack>
              </DialogContent>
              <DialogActions >
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" spacing={2}>
                  <Stack alignItems="center" >
                    <TextField
                        margin="dense"
                        id="review-tip"
                        label="Tip / თიფი"
                        name="amount"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">₾</InputAdornment>,
                        }}
                        value={this.state.tip.amount}
                        onChange={this.handleTipNumberChange}
                      />                
                    <Rating
                        size="medium"
                        name="rating"
                        value={this.state.review.rating}
                        onChange={this.handleReviewChange}
                      />
                    <TextField
                      margin="dense"
                      id="review-comment"
                      label="Comments / კომენტარი​"
                      name="comment"
                      type="text"
                      value={this.state.review.comment}
                      onChange={this.handleReviewChange}
                      fullWidth="true"
                    />
                  </Stack>
                  <Stack direction ='column' alignItems="center" spacing={1} >
                    <Stack direction = "row">        
                      <TextField
                        margin="dense"
                        id="review-payment_type"
                        defaultValue="card"
                        label="Payment"
                        name="type"
                        select
                        value={this.state.tip.type}
                        onChange={this.handleTipChange}
                      > 
                        {this.state.payment_options.map((option) => (
                          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                            <Typography variant="body" fontSize="0.7rem" >
                              {option.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                    <Stack direction="row">
                      <Stack direction="column">
                        <Typography variant="caption" fontSize='0.7rem'>
                          Please accept:
                        </Typography>
                        <Typography variant="caption" fontSize='0.7rem'>
                          <a href="https://www.martipia.com/terms-and-conditions" target="_blank">Terms & Conditions</a>, <a href="https://www.martipia.com/privacy-policy" target="_blank">Privacy Policy</a> 
                        </Typography>
                      </Stack>
                      <Checkbox
                        defaultChecked="true"
                        name="terms_conditions_privacy_policy"
                        onChange={this.handleCheckbox}
                        />
                    </Stack>
                    <Button  disabled = {!this.state.terms_conditions_privacy_policy} variant = 'contained' color="primary"  onClick= {() => { submitReview()}}>
                      <Typography variant="body" fontSize="0.7rem" >
                        Tip / გადარიცხვა
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Box>
    );
  }
}