import {
  useParams
} from "react-router";

import React from "react";
// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../Page';
import {
  BusinessDashboard,
} from '../../components/mtip_components/mtipManager/mtipBusinessDashboard';

export default function MTipBusinessManager() {
  let id = useParams();  
  return (
    <Page title="MARTIPIA | Business Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Business Details</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <BusinessDashboard id ={id}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}