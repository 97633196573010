import React, { Component } from "react";
import { Box, Grid, Card, CardContent, Stack, Divider } from '@material-ui/core';

import MTipLogoAndModule from "../../../misc/MTipLogoAndModule";

import TipAndRateProfile from "../tipAndRate/TipAndRateProfile";
import TipAndRateTip from "../tipAndRate/TipAndRateTip";

import TipAndRateListCollapsable from "./TipAndRateListCollapsable";
import TipAndRateSplitTipCollapsable from "./TipAndRateSplitTipCollapsable";
import TipAndRateList from "./TipAndRateList";
import TipAndRateMenu from "./TipAndRateMenu";
import TipAndRateEasyTip from "./TipAndRateEasyTip";


export default class TipAndRateSharedCard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      default_tip_info:props.default_tip_info,
      business_info:props.business_info,
      shared_qr_info:props.shared_qr_info,
      service_professional_list:props.service_professional_list,
      id:props.id,
      active_component: false, 
    };
   }
  
   toggleHideList = () => {
    this.setState({
        hide_list:!this.state.hide_list,
    })
   }
    toggleHideListButton = () => {
        this.setState({
            hide_list_button:!this.state.hide_list_button,
        })
    }
    toggleHideSplit = () => {
        this.setState({
            hide_split:!this.state.hide_split,
        })
    }
    toggleHideSplitButton = () => {
        this.setState({
            hide_split_button:!this.state.hide_split_button,
        })
    }
    toggleHideMenu = () => {
     this.setState({
         hide_menu:!this.state.hide_menu,
     })
    }
    toggleHideMenuButton = () => {
        this.setState({
            hide_menu_button:!this.state.hide_menu_button,
        })
    }

    toggleComponent = (component_key) => {
      this.setState(prevState => ({
        active_component: prevState.active_component === component_key ? false : component_key
      }));
    };
  

  render = () => {
  // Count active features
  const featuresToCheck = [
    'menu_active',
    'easy_tip_profile_enabled',
    'split_enabled',
    'list_enabled',
  ];

  const activeFeaturesCount = featuresToCheck.reduce((count, key) => {
    return count + (Boolean(this.state.shared_qr_info[key]) ? 1 : 0);
  }, 0);
  
  // Mapping features to components when they are the only active feature
  const singleFeatureComponents = {

    split_enabled: (
      <TipAndRateTip 
        id={this.state.id}
        shared={true}
        default_tip_info={this.state.default_tip_info}
      />
    ),
    
    list_enabled: (
      <Grid item>
        <Divider></Divider>
        <TipAndRateList 
          employees_info = {this.state.service_professional_list} 
          redirectToServiceProfessional={this.props.redirectToServiceProfessional}
          />
      </Grid>
    ),
    easy_tip_profile_enabled: (
      <Stack alignItems="center">
        <TipAndRateEasyTip
          toggleComponent={this.toggleComponent}
          activeComponent={this.state.active_component}
          easy_tip_profile ={this.state.shared_qr_info.easy_tip_profile}
            />
      </Stack>
    ),
    menu_active: (
      <Stack alignItems="center">
        <TipAndRateMenu
          menu_link ={this.state.shared_qr_info.menu_data}
            />
      </Stack>
    ),

  };
  
  // Mapping features to components when they are one of many active features
  const multipleFeatureComponents = {
    split_enabled: (
      <TipAndRateSplitTipCollapsable
        toggleComponent={this.toggleComponent}
        activeComponent={this.state.active_component}
        default_tip_info={this.state.default_tip_info} 
        id={this.state.id}
        toggleHideList={this.toggleHideList}
        toggleHideSplitButton={this.toggleHideSplitButton}
      />
    ),
    list_enabled: (
      <TipAndRateListCollapsable  
        toggleComponent={this.toggleComponent}
        activeComponent={this.state.active_component}
        employees_info={this.state.service_professional_list}
        redirectToServiceProfessional={this.props.redirectToServiceProfessional}
        toggleHideSplit={this.toggleHideSplit}
        toggleHideListButton={this.toggleHideListButton}
      />
    ),
    easy_tip_profile_enabled: (
      <Stack alignItems="center">
        <TipAndRateEasyTip
          toggleComponent={this.toggleComponent}
          activeComponent={this.state.active_component}
          easy_tip_profile ={this.state.shared_qr_info.easy_tip_profile}
          />
      </Stack>
    ),
    menu_active: (
      <Stack alignItems="center">
        <TipAndRateMenu
          toggleComponent={this.toggleComponent}
          activeComponent={this.state.active_component}
          menu_type={this.state.shared_qr_info.menu_type}
          menu_link ={this.state.shared_qr_info.menu_data}
          shared_qr_code={this.state.shared_qr_info.id}
            />
      </Stack>
    ),
  };

  const renderFeatures = () => {
    if (activeFeaturesCount === 1) {
      const activeFeature = featuresToCheck.find(key => this.state.shared_qr_info[key]);
      return singleFeatureComponents[activeFeature];
    } else {
      return featuresToCheck.filter(key => this.state.shared_qr_info[key]).map(key => (
        <Stack spacing={"1"} alignItems="center">
          {multipleFeatureComponents[key]}
        </Stack>
      ));
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '89vh' }}
      >
        <Card style={{ overflowY: 'auto', maxHeight: '90vh', marginTop: '10px' }}>
          <CardContent>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" >
              <MTipLogoAndModule module_name="Tip & Rate" logo_link="/"></MTipLogoAndModule>
              <TipAndRateProfile active_item={this.state.business_info} is_service_professional={false}/>
            </Grid>
            <Stack spacing={0.5}>
              {renderFeatures()}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
  }
}