
import React, { Component } from "react";
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class ActionCheckModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    }
  };
  
  render = ()=> {

  const { toggle, message, action} = this.props;
  let msg = ""
  if (message){
    msg = message
  }
  else {
    msg = "Are you sure you want to perform this action?"
  }
    
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggle} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Message:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {msg}
          </DialogContentText>          
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="secondary">
            Close
          </Button>
          <Button onClick={action} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }

}