import React, { Component } from "react";
import { Icon } from '@iconify/react';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {Box, Grid, Card, Button, Typography, CardContent, Container} from '@material-ui/core';

import BusinessProfileModal from '../businessProfile/BusinessProfileModal';
import { submitProfileInfo } from "./scripts";
import { SUCCESS_RESPONSES } from "../../../config/systemMessages";
import withErrorHandling from "../../hocs/withErrorHandling";



class MTipRegisterBusinessProfile extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      active_item: '',
      modal: false,
      
    };
  }


  handleSuccesfulSubmit = (res) => {
    this.toggleModal()
    this.props.handleSuccess(SUCCESS_RESPONSES.BUSINESS_PROFILE_UPDATED)
    this.props.refreshState()
  }

  submitInfo = (item) =>
  {
    delete item.businessprofilepicture
    submitProfileInfo(item, this.handleSuccesfulSubmit, this.props.handleFailure)
  };

   createBusinessProfile = () =>
   {    
    this.toggleModal()
    this.setState({ 
      active_item: "",
    });
   };

  toggleModal = () =>
  {
    const prevModal = this.state.modal;
    this.setState({ 
      modal: !prevModal,
    });
  };

  render() 
  {
    
    return  (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <Container>
              <Card>
   
                {this.state.modal && <BusinessProfileModal
                  active_item = {this.state.active_item}
                  submitProfileInfo = {this.submitInfo}
                  toggleModal = {this.toggleModal}
                  code_needed={true}
                  />
                  }
                  
                <CardContent>
                  <Typography variant="body2">
                    Please click below to submit your business's details for registration.
                  </Typography>
                  <Button
                      onClick = { () => this.createBusinessProfile()}
                      size="small"
                      color="primary"
                      variant =  "contained"
                      endIcon={<Icon icon={arrowIosForwardFill} />}
                    >
                      Register
                  </Button>
                </CardContent>
              </Card>
            </Container>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withErrorHandling(MTipRegisterBusinessProfile);
