import React, { Component } from "react";
import {Grid, Button, Stack, Card,Typography,} from '@material-ui/core';
import DemoTipAndRateReviewModal from './DemoTipAndRateReviewModal';
import MessageModal from "../../../misc/modals/MessageModal";

import QRCode from "react-qr-code";


export default class DemoProfile extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      profile_info: '',
      modal:"",
      thank_you:"",
    };
  }
  
  
    componentDidMount () {
      this.setState({
        profile_info: {
          bio: "Friendly Neighborhood Waiter",
          business_name: "Main Street Steakhouse",
          name: "Johnny Johnson",
          number_of_reviews: 37,
          rating: 5,
          role: "Waiter",
          serviceprofessionalpicture: {
            file: "/static/g_person_photo.jpg",
            id: 1,
          }
        },
      })
      this.toggleModal()
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value

    this.setState(prev_state=> {
        const new_state = {...prev_state};
        new_state[name] = value;
        return new_state;
    });
  };

  toggleModal = () =>
  {
    const prevModal = this.state.modal;
    this.setState({ 
      modal: !prevModal,
    });
  };

  toggleThankYou = () =>
  {
    const prevModal = this.state.thank_you;
    this.setState({ 
      thank_you: !prevModal,
    });
  };
  
 
  submitReview = () => {
    this.toggleThankYou()
    this.toggleModal()
  };


  render = () => {
    return  (
      <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '75vh' }} >
        <Stack spacing={3} alignItems="center" justifyContent="flex-end" sx={{ p: 3, pr: 0 }}>
        <Card>
          <Stack alignItems="center">
            <Typography variant="caption" sx={{ color: 'text.secondary' }} noWrap>
              Scan me
            </Typography>
            <QRCode value={(process.env.REACT_APP_ENVIRONMENT==='DEV') ? "https://test.app.martipia.com/demo" : "https://app.martipia.com/demo"} size={160} />
          </Stack>
        </Card>
        <Button 
          size="large" 
          onClick={() => {
            this.toggleModal();
          }}
    >
            Or click here to try the demo again!
        </Button>
                {this.state.modal 
                  ? ( 
                  <DemoTipAndRateReviewModal
                  active_item = {this.state.profile_info}
                  submitReview = {this.submitReview}
                  toggleModal = {this.toggleModal}/>
                  ) 
                  : null}
          {this.state.thank_you 
                  ? ( 
                  <MessageModal toggle = {this.toggleThankYou} message = "Thank you for leaving a tip! :)"/>
                  ) 
                  : null}
        </Stack>
      </Grid>
    );
  }
}
