import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import {Switch, Stack, Typography} from '@material-ui/core';
import {toggleAutoWithdrawal} from "./scripts";
import withErrorHandling from "../../hocs/withErrorHandling";

class ServiceProfessionalTipBalance extends Component
{
  constructor (props)
  {
    super(props);
    this.state = 
    {
      tipInfo:props.tipInfo,
      pendingWithdrawalInfo:props.pendingWithdrawalInfo,
      autoWithdrawalEnabled:props.autoWithdrawalEnabled,
    };
  }



  handleAutoWithdrawalTogglefresh = (res) => {
    this.setState({
      autoWithdrawalEnabled:res.data.auto_withdrawal_enabled,
    });
  }

  toggleAutoWithdrawal= () => {
    toggleAutoWithdrawal(this.handleAutoWithdrawalTogglefresh,this.props.handleFailure)
  }


  
  
    
  render = ()=> {
    const { withdrawTips} = this.props;
    return(
      <Stack direction="column" alignItems="center" spacing={1}>
          <Stack direction="column" alignItems="flex-start">
            <Stack direction ="row" alignItems="center" spacing = {1}>
              <Typography variant="body2" noWrap>
                Balance:
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                ₾{this.state.tipInfo}
              </Typography>
            </Stack>
            <Stack direction ="row" alignItems="center" spacing = {1}>
              <Typography variant="body2" noWrap>
                Pending Withdrawal:
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                ₾{this.state.pendingWithdrawalInfo}
              </Typography>
            </Stack>
          </Stack>
          <Button size="medium" variant="contained"  disabled={((this.state.tipInfo<5) || (this.state.pendingWithdrawalInfo>0)) ? true : false}  color="primary" onClick= { () => withdrawTips(this.state.tipInfo)} >
              Withdraw Now
          </Button>
          <Stack direction={"row"}>
            <Typography variant="body2">
              Automatic Withdrawal Request
            </Typography>
            <Switch
              size="small"
              checked={this.state.autoWithdrawalEnabled}
              onChange={() => this.toggleAutoWithdrawal()}
            />
          </Stack>
            <Typography variant="caption"> 
              If enabled, your tip balance will be automatically requested for withdrawal Wednesday night and Sunday night if your balance is greater than 5 GEL, and you have provided withdrawal information.
            </Typography>
          {(this.state.tipInfo<5)
          ? <Typography variant="caption"> <i>* Only tip balances larger than 5 can be withdrawn </i></Typography>
          : null
          }
          <Typography variant="caption"> <i>* GEL transfer commission fee from the account. Bank of Georgia or TBC account - free; other banks - 0.07%, min. 1 GEL</i></Typography>
      </Stack>
    );
  }
}
export default withErrorHandling(ServiceProfessionalTipBalance);