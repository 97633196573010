import React, { Component } from "react";

import { Stack } from '@material-ui/core';

import ImageCard from "../../misc/ImageCard";
export default class ServiceProfessionalImage extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      serviceProfessionalProfileInfo: props.serviceProfessionalProfileInfo,  
    };
  }


  render = ()=> {

  return (
    <Stack direction="row" justifyContent="flex-start" spacing = {1}>
      {
        (this.props.serviceProfessionalProfileInfo.serviceprofessionalpicture)
        ? ImageCard(
          this.props.serviceProfessionalProfileInfo.serviceprofessionalpicture.file,
          this.props.serviceProfessionalProfileInfo.name,
          this.props.serviceProfessionalProfileInfo.bio,
          )
        : ImageCard(
          "/static/no_picture_person.png",
          this.props.serviceProfessionalProfileInfo.name,
          this.props.serviceProfessionalProfileInfo.bio,
          )
      } 
    </Stack>
    );
  }
}
