import {
  Navigate
} from "react-router-dom";
import React, { Component } from "react";
import { Box, Stack, Card , Typography, CardContent, Container, CircularProgress, Grid } from '@material-ui/core';
import Scrollbar from '../../misc/navigation/Scrollbar';
import UserProfileModal from "./UserProfileModal";
import UserProfile from "./UserProfile";
import UserPasswordModal from "./UserPasswordModal";
import { updateUserInfo, checkLogin, updatePassword } from "./scripts";
import withErrorHandling from "../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES } from "../../../config/systemMessages";

class MTipUserView extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      user_info:"",      
      modal:"",
      password_modal:"",
    };
  }
    

  toggleProfileModal = () =>
  {
    const prevModal = this.state.profile_modal;
    this.setState({ 
      profile_modal: !prevModal,
    });
  };
  togglePasswordModal = () =>
  {
    const prevModal = this.state.password_modal;
    this.setState({ 
      password_modal: !prevModal,
    });
  };

  componentDidMount() {
    checkLogin(this.handleSuccess,this.props.handleFailure) 
  }
  submitUserInfo = (data) => {
    updateUserInfo(data, this.handleSuccesfulProfileSubmit, this.props.handleFailure)
  }
  handleSuccesfulProfileSubmit = (res) => {
    this.toggleProfileModal()
    this.props.handleSuccess(SUCCESS_RESPONSES.USER_PROFILE_UPDATED)
    this.handleSuccess(res)
  }
  handleSuccess = (res) => {
    this.setState({
      user_info: res.data
    })
  }
  

  submitPassword = (data) => {
    updatePassword(data, this.handleSuccesfulPasswordSubmit, this.props.handleFailure)
  }
  handleSuccesfulPasswordSubmit = (res) => {
    this.togglePasswordModal()
    this.props.handleSuccess(res)
  }


  renderUserInfo = () => {
    return (
        <CardContent>
          {UserProfile(this.state.user_info, this.toggleProfileModal, this.togglePasswordModal)}
        </CardContent>
    )
  }

  render = () => {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return  (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}  md={12}  lg={12}  xl={12}  >
            <Scrollbar>
              <Container>
                <Card>

                  {this.state.user_info.first_name 
                  ? this.renderUserInfo()
                  : <Stack direction="column" alignItems="center" ><Typography>Your information is loading... </Typography><CircularProgress/> </Stack>
                  }

                  {this.state.profile_modal 
                  ? ( 
                  <UserProfileModal
                  active_item = {this.state.user_info}
                  submitUserInfo = {this.submitUserInfo}
                  toggleModal = {this.toggleProfileModal}/>
                  ) 
                  : null}

                  {this.state.password_modal 
                  ? ( 
                  <UserPasswordModal
                  submitPassword = {this.submitPassword}
                  togglePasswordModal = {this.togglePasswordModal}/>
                  ) 
                  : null}
                  </Card>
              </Container>
            </Scrollbar>    
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withErrorHandling(MTipUserView);