import axios from 'axios';

// Function to refresh the token
function refreshToken() {
  return axios.post('/api/token-refresh/', {
    refresh: localStorage.getItem('refreshToken')
  }).then(res => {
    localStorage.setItem('token', res.data.access);
    localStorage.setItem('refreshToken', res.data.refresh);
    return res.data.access;
  });
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  const originalRequest = error.config;

  // Check if we're dealing with a token refresh failure
  if (error.response && error.response.data && error.response.status === 401 && originalRequest.url === '/api/token-refresh/') {
    // Handle refresh token failure (e.g., logout the user, redirect to login page)
    console.error("Refresh token is invalid. Handling accordingly.");
    // Avoid retrying the failed token refresh
    return Promise.reject(error);
  }

  if (error && originalRequest.url === '/api/token-auth/') {
    // Handle refresh token failure (e.g., logout the user, redirect to login page)
    console.error("Refresh token is invalid. Handling accordingly.");
    // Avoid retrying the failed token refresh
    return Promise.reject(error);
  }
  // Proceed with the retry logic for other requests
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return refreshToken().then(newAccessToken => {
      originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
      return axios(originalRequest);
    }).catch(refreshError => {
      // Handle case where refreshToken fails (e.g., invalid refresh token)
      console.error("Unable to refresh token:", refreshError);
      // Here, you could trigger a logout or redirect to login
      return Promise.reject(refreshError);
    });
  }
  return Promise.reject(error);
});


function handleError(err, handleFailure) {
    if (err.response) {
      if (err.response.status === 404) {
        if (!err.response.data.header) {
          handleFailure({ 
            header: "Could not find the information.",
            detail: "The requested record was not found." 
          });
        }
        else handleFailure(err.response.data)
        console.log(err.response)
      } else if (err.response.data.code === 'token_not_valid') {       
        handleFailure(
          { 
            header: "Session Expired",
            detail: "Your session has expired. Please login again." 
          }
        )
        console.log(err.response)
      }
      else {       
        handleFailure(err.response.data)
        console.log(err.response)
        }
      }
    else handleFailure(err);
    console.log(err);
  }

export function makeApiPostRequestAuthenticated({ url, data, headers }, handleSuccess, handleFailure) {
    let requestHeaders = headers || {};
    requestHeaders["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
    axios({
      method: 'post',
      url: url,
      data: data,
      headers: requestHeaders
    })
    .then(res => handleSuccess(res))
    .catch((err) => handleError(err, handleFailure));
  }
  

export function makeApiPostRequestAnonymous({ url, data, headers }, handleSuccess, handleFailure) {
    let requestHeaders = headers || {};
    axios({
      method: 'post',
      url: url,
      data: data,
      headers: requestHeaders
    })
    .then(res => handleSuccess(res))
    .catch((err) => handleError(err, handleFailure));
  }
  

  export function makeApiGetRequestAuthenticated({ url, headers }, handleSuccess, handleFailure) {
    let requestHeaders = headers || {};
    requestHeaders["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
    axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })
    .then(res => handleSuccess(res))
    .catch((err) => handleError(err, handleFailure));
  }
  