import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Typography } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Checkbox} from "@material-ui/core";

export default class IikoIntegrationModalDetails extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      platform_info: props.platform_info,
      // shared_list_info:props.shared_list_info,
    };
  }

  handleChange = (e) => {
    let { value, name } = e.target; 
    const prevItem = this.state.platform_info;
    const platformInfo = { ...prevItem, [name]: value };
    this.setState({
      platform_info: platformInfo,
    });
  };

  handleCheckbox = (e) => {
    const name = e.target.name
    const value = e.target.checked
    const prevItem = this.state.platform_info;
    const platformInfo = { ...prevItem, [name]: value };
    this.setState({
      platform_info: platformInfo,
    });
  };

  render = ()=> {  
    const { toggleModal, submitIntegrationsInfo } = this.props;
    return (
      <div>
        <DialogContent>
          <DialogContentText>
            <Typography align="center" variant='h4'>
              iiko
            </Typography>
          </DialogContentText>
          <TextField
            margin="dense"
            id="businessProfile-integration_detail-iiko"
            label="Name"
            name="name"
            type="text"
            value={this.state.platform_info.name}
            onChange={this.handleChange}
            placeholder="Enter the Name of your establishment."
            fullWidth
          />
          <TextField
            margin="dense"
            id="businessProfile-integration_detail-iiko"
            label="Restaurant ID"
            name="restaurant_id"
            type="text"
            value={this.state.platform_info.restaurant_id}
            onChange={this.handleChange}
            placeholder="Enter the Restaurant ID"
            fullWidth
          />
          <Typography variant='body'>
            Override integration to always give a custom override code (optional):
          </Typography>
          <Checkbox
            margin="dense"
            id="businessProfile-integration_detail-poster"
            label="Override Integration"
            name="override_integrations"
            checked={this.state.platform_info.override_integrations}
            onChange={this.handleCheckbox}
            required="true"
          />
          <TextField
            margin="dense"
            id="businessProfile-integration_detail-poster"
            label="Custom Override Code"
            name="override_code"
            type="text"
            disabled = {!(this.state.platform_info.override_integrations)}
            value={this.state.platform_info.override_code}
            onChange={this.handleChange}
            placeholder="Leave empty unless shared QR code is enabled."
            fullWidth
          />
        </DialogContent>        
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick=
          { () =>
            {
              submitIntegrationsInfo("iiko", this.state.platform_info);
              toggleModal();
            }
          }>
            Save
          </Button>
        </DialogActions>
      </div> 
      );
  }
}
