import React, { Component } from "react";

import {Divider, Button, Typography, Container, Stack, Card, CardHeader, CardContent } from '@material-ui/core';
// utils
import MTipReviewAnalytics from '../martipiaReviews/MTipReviewAnalytics';
import { getReviewsData, getServiceProfessionalHighestTip} from "./scripts";
import withErrorHandling from "../../hocs/withErrorHandling";

class ServiceProfessionalReviews extends Component
{
  constructor (props)
  {
    super(props);
    this.state = 
    {
      reviews:"",
      highest_tip:"",
    };
  }
  componentDidMount ()
  {
      this.refreshData();
  }

  handleSuccessfulReviewsRefresh = (res) => {
    this.setState({
      reviews: res.data
    });
  }

  handleSuccessfulHighestTipRefresh = (res) => {
    this.setState({
      highest_tip: res.data
    });
  }
  
  refreshData = () =>
  {
    getReviewsData(this.handleSuccessfulReviewsRefresh, this.props.handleFailure)
    getServiceProfessionalHighestTip(this.handleSuccessfulHighestTipRefresh, this.props.handleFailure)
  };


  render() 
  {
    return  (
      <Container>
        <Card >
          <CardHeader title="📊 Review Analytics 📈" />
          <Divider />
          <CardContent>
            <Stack alignItems="center">
            {this.state.reviews && this.state.highest_tip.tip_amount
            ? <MTipReviewAnalytics reviews={this.state.reviews} highest_tip={this.state.highest_tip}/>
            : <Typography variant="body2" noWrap> No reviews yet. </Typography>}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    );
  }
}
export default withErrorHandling(ServiceProfessionalReviews);