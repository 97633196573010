import { makeApiPostRequestAnonymous } from "../../../utils/apiRequest";

export function getProfileInfoPublic  (code, handleSuccess, handleFailure) {
    makeApiPostRequestAnonymous({
        url : '/api/get_service_professional_by_code/',
      data: {
        id: code
      },
    }, handleSuccess, handleFailure);
  }

  export function getThankyouData  (code, handleSuccess, handleFailure) {
    makeApiPostRequestAnonymous({
        url : '/api/get_thankyou_data/',
      data: {
        id: code
      },
    }, handleSuccess, handleFailure);
  }


  
  export function  submitReview (review, tip, id, handleSuccess, handleFailure)  {
    let review_request = {
      review: review,
      tip: tip,
      id:id,
    }
    var _headers =  {
        "Content-Type" : 'application/json',
    }
    makeApiPostRequestAnonymous({
        url: '/api/submit_review/',
        headers: _headers,
        data: review_request,
    }, handleSuccess, handleFailure);
  }
  
  