import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Checkbox } from "@material-ui/core";


export default class BusinessProfileModal extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      active_item: props.active_item,
      open: true,
      code_needed:props.code_needed,      
    };
  }

  handleChange = (e) => {
    let { value, name } = e.target;
    
    const prevItem = this.state.active_item;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
      active_item: activeItem,
    });
  };
  
  handleCheckbox = e => {
    const name = e.target.name
    const value = e.target.checked

    const prevItem = this.state.active_item;
    const activeItem = { ...prevItem, [name]: value };

    this.setState({
      active_item: activeItem,
    });
}


  render = ()=> {

  const { toggleModal, submitProfileInfo } = this.props;
  return (
    <div>
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Martipia Business Profile Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the appropriate fields of this Business Profile.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="businessProfile-name"
            label="Business Name"
            name="name"
            type="text"
            value={this.state.active_item.name}
            onChange={this.handleChange}
            placeholder="Enter Business Profile Name"
            fullWidth
          />
          <TextField
            margin="dense"
            id="businessProfile-businessType"
            label="Business Type"
            name="business_type"
            type="text"
            value={this.state.active_item.business_type}
            onChange={this.handleChange}
            placeholder="Enter Business Profile Type"
            fullWidth
          />
          <TextField
            margin="dense"
            id="businessProfile-description"
            label="Description"
            name="description"
            type="text"
            value={this.state.active_item.description}
            onChange={this.handleChange}
            placeholder="Enter the Business Description"
            fullWidth
          />
          <TextField
            margin="dense"
            id="businessProfile-location"
            label="Location"
            name="location"
            type="text"
            value={this.state.active_item.location}
            onChange={this.handleChange}
            placeholder="Enter the Business Location"
            fullWidth
          />
          {this.state.code_needed
          ? (
              <TextField
              margin="dense"
              id="businessProfile-business_registration_code"
              label="Business Registration Code"
              name="business_registration_code"
              type="text"
              value={this.state.active_item.business_registration_code}
              onChange={this.handleChange}
              placeholder="Enter the Business Registration Code"
              fullWidth
              />
              
            )
          : ""}
          {this.state.code_needed
          ? (
            <Typography align="center">
              Please accept the <a href="https://www.martipia.com/agreement-with-client-business" target="_blank">Business Registration Agreement</a>.
              <Checkbox
              margin="dense"
              id="businessProfile-accepted"
              name="accepted"
              checked={this.state.active_item.accepted}
              onChange={this.handleCheckbox}
              required="true"
            />
            </Typography>
              
            )
          : ""}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick=
          { () =>
            {
            submitProfileInfo(this.state.active_item);
            }
          }>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
