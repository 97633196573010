import React from "react";
import PropTypes from 'prop-types';
import {Typography, Card, CardHeader, Divider, CardContent, Stack, Button} from '@material-ui/core';
import BusinessProfile from "../businessProfile/BusinessProfile";

BusinessProfile.propTypes = {
  businesssInfo: PropTypes.object.isRequired,
  editBusinessProfile: PropTypes.func.isRequired,
  deleteBusinessProfile: PropTypes.func.isRequired,
  uploadBusinessProfilePicture: PropTypes.func.isRequired,
  viewIntegrations: PropTypes.func.isRequired,
  viewThankyouData: PropTypes.func.isRequired,
  viewBusinessProfileQRSettings: PropTypes.func.isRequired,
  viewBusinessProfileSettings: PropTypes.func.isRequired,
};



export default function BusinessProfile_manager (businessInfo, editBusinessProfile, deleteBusinessProfile, uploadBusinessProfilePicture, viewIntegrations, viewThankyouData, viewBusinessProfileQRSettings, viewBusinessProfileSettings) {
  const bItem = (
      <Card >
        <CardHeader title={businessInfo.name} />
        <Divider />
        <CardContent>
          <Stack direction = "column" spacing = {1}>
            {BusinessProfile(
              businessInfo
            )}
            <Stack spacing={3} alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  variant="outlined"
                  component="label"
                  >
                  Upload Picture
                  <input
                    type="file"
                    hidden
                    onChange = {(e) => uploadBusinessProfilePicture(e, businessInfo.id, businessInfo.businessprofilepicture)}
                  />
                </Button>
                <Button 
                  variant="outlined"
                  color="secondary"
                  onClick= { () => editBusinessProfile(businessInfo)}
                  >
                    Edit
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Button 
                  variant="outlined"
                  color="secondary"
                  onClick= { () => viewIntegrations()}
                  >
                    Integrations
                </Button>
                <Button 
                  variant="outlined"
                  color="secondary"
                  onClick= { () => viewThankyouData()}
                  >
                  "Thank-you" Card
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Button 
                  onClick={ () => viewBusinessProfileQRSettings()} 
                  variant="outlined"
                  color="secondary"
                  >
                    Business QR Code Settings
                </Button>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Button 
                  onClick={ () => viewBusinessProfileSettings()} 
                  variant="outlined"
                  color="secondary"
                  >
                    Business Settings
                </Button>
              </Stack>
              <Button 
                variant="outlined"
                color="error" 
                onClick= { () => deleteBusinessProfile(businessInfo)}
                >
                  Delete
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
  );
  return bItem;
}

