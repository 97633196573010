import React from "react";
import { Navigate } from "react-router-dom";
import {InputAdornment,IconButton, Button, Stack, TextField, Box, CircularProgress} from '@material-ui/core';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {resetPassword} from "./scripts"
import withErrorHandling from "../../hocs/withErrorHandling";

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
            uid:props.uid,
            token:props.token,
            password: "",
            confirmed_password:"",
            showPassword:false,
            passwords_correct:true,
            data_provided:false,
            confirms_correct:true,
        } 
      this.props.registerCloseSuccessModalSuper(this.superCloseSuccessModal);
      }


    superCloseSuccessModal = () =>
    {
      this.setState({
        redirect:"/login"
      })
    }


    checkEmpty() {
      if (this.state.password && this.state.confirmed_password) {
        this.setState({
          data_provided:true
        })
      }
      else {
        this.setState({
          data_provided:false
        })
      }
    }

    checkConfirms() {
      if ((this.state.password!==this.state.confirmed_password)) {
        this.setState({
          confirms_correct : false,
          passwords_correct:false,
        })
      }
      else {
        this.setState({
          passwords_correct:true,
        })
      }
      
      if ((this.state.password===this.state.confirmed_password)) {
        this.setState({
          confirms_correct : true,
          })
      }
    }

    handleChange = e => {
      const name = e.target.name
      const value = e.target.value
      this.state[name]=value
      this.checkEmpty()
      this.checkConfirms()
  }
    
    showPassword = () => {
      this.setState({
          showPassword:!this.state.showPassword
      })
}

    render() {
      if (this.state.redirect) {
        return <Navigate to={this.state.redirect} />
      }
        return (
            <form onSubmit = { e => {
              this.props.setLoadingTrue()
              resetPassword(e, this.state, this.props.handleSuccess, this.props.handleFailure)
              }
            }>
              
                <Stack direction = 'column'>
                  <TextField
                    margin="dense"
                    id="user-password"
                    label="New Password"
                    name="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.handleChange}
                    error = {this.state.error}
                    color = {this.state.error ? "error" : "primary"}
                    helperText={this.state.error ? "Issue with password." : ""}
                    placeholder="Enter your new password"
                    required="true"
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.showPassword} edge="end">
                              <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}/>
                  <TextField
                    margin="dense"
                    id="user-confirmed-password"
                    label="Confirm New Password"
                    name="confirmed_password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.confirmed_password}
                    onChange={this.handleChange}
                    error = {(this.state.error || !this.state.passwords_correct) ? true : false}
                    color = {this.state.error ? "error" : "primary"}
                    helperText={(this.state.error || !this.state.passwords_correct) ? (this.state.error ? "Issue with password." : "Confirmed password doesn't match." ) : ""}
                    placeholder="Confirm your new password"
                    required="true"
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={this.showPassword} edge="end">
                              <Icon icon={this.state.showPassword ? eyeFill : eyeOffFill} />
                            </IconButton>
                          </InputAdornment>
                        )
                    }}/>
                </Stack>
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button 
                    fullWidth
                    type='submit'
                    variant="contained" 
                    color="primary" 
                    disabled = {this.state.loading || !this.state.data_provided || !this.state.confirms_correct}
                    >
                    Submit
                </Button>
                {this.state.loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "primary",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />)}
            </Box>
            </form>
        );
    }
  }

  export default withErrorHandling(ResetPasswordForm);