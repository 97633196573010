import Rating from '@material-ui/lab/Rating';
import React, { Component } from "react";
import { Stack, Typography,} from '@material-ui/core';
import ServiceProfessionalImage from '../../serviceProfessionalProfile/ServiceProfessionalImage';
import BusinessProfileImage from '../../businessProfile/BusinessProfileImage';
import { withTranslation } from 'react-i18next';

class TipAndRateProfile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      is_service_professional:props.is_service_professional,
      active_item: props.active_item,
    }
  }

  render = ()=> {
    const { t } = this.props;
    return (
      <Stack direction = "column" spacing = {0} alignItems="center">
          {(this.state.is_service_professional)
          ? <ServiceProfessionalImage serviceProfessionalProfileInfo={this.state.active_item}/>
          : <BusinessProfileImage businessInfo={this.state.active_item}/>
          }
          <Stack direction = "column" alignItems="center" justifyContent = "space-around" >
              <Rating
                  size="large"
                  name="rating"
                  readOnly
                  value={this.state.active_item.rating}
              />
              {this.state.active_item.number_of_reviews} {t('TipAndRateProfile_profile_reviews')}
              {(this.state.is_service_professional)
              ?(
                  <Typography variant="body">
                      {this.state.active_item.role} <b>@</b>  {this.state.active_item.business_name}
                  </Typography>
              )
              :""
              }
          </Stack>
      </Stack>
    );
  }
}
export default withTranslation()(TipAndRateProfile);