import { Navigate } from "react-router-dom";
import React, { Component } from "react";
import { Container} from '@material-ui/core';
import BusinessProfileModal from '../../businessProfile/BusinessProfileModal'
import BusinessProfileIntegrationsModal from "../../businessProfile/businessProfileIntegrations/BusinessProfileIntegrationsModal";
import BusinessProfileThankyouCardsModal from "../../businessProfile/businessProfileThankyouCards/BusinessProfileThankyouCardsModal";
import BusinessProfile_manager from "../BusinessProfile_manager";
import { toggleThankyouData, getSharedQRBusiness, getThankyouDataBusiness, createThankyouData, uploadBusinessProfilePicture, deleteBusinessProfile, getBusinessData, submitProfileInfo, submitIntegrationsInfo, getIntegrationsInfo } from "./scripts"; 
import DeleteModal from "../../../misc/modals/DeleteModal";
import BusinessProfileQRSettingsModal from '../../businessProfile/businessProfileQRSettings/BusinessProfileQRSettingsModal';
import BusinessProfileSettingsModal from "../../businessProfile/businessProfileSettings/BusinessProfileSettingsModal";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES } from "../../../../config/systemMessages";

class BusinessProfileDetailsDashboard extends Component
{
  constructor (props)
  {
    super(props);

    this.state = 
    {
      active_item: '',
      modal: false,
      business_info: "",
      id:props.id.id,
      message_modal:false,
      redirect:"",
      integrations_modal:false,
      integrations_info:"",
      business_profile_qr_settings_modal:false,

    };
  }


  toggleProfileModal = () =>
  {
    const prevModal = this.state.modal;
    this.setState({ 
      modal: !prevModal,
    });
  };


  componentDidMount () {
    this.refreshData();
  }

  
  handleSuccessfulBusinessRefresh = (res) => {
    this.setState({
      business_info: res.data,
    });
    
  }
  
  handleSuccesfulProfileSubmit = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.BUSINESS_PROFILE_UPDATED)
    this.toggleProfileModal()
    this.handleSuccesfulSubmit(res)
  }

  handleSuccessfulPictureUpload = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.BUSINESS_PROFILE_PICTURE_UPDATED)
    this.handleSuccesfulSubmit(res)
  }

  handleSuccesfulSubmit = (res) => {
    this.refreshData()
    this.updated()    
  }

  refreshData = () =>
  {
    getBusinessData(this.state.id, this.handleSuccessfulBusinessRefresh, this.props.handleFailure)
  };

  uploadBusinessProfilePicture = (event, id, businessprofilepicture) => {
    const myFile = event.target.files[0]
    const formData = new FormData()
    formData.append("file", myFile)
    formData.append("business",id)

    if (!businessprofilepicture)  
    { 
      this.props.handleLoading()
      uploadBusinessProfilePicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
    }
    else
    {
      formData.append("id",businessprofilepicture.id)
      this.props.handleLoading()
      uploadBusinessProfilePicture(formData, this.handleSuccessfulPictureUpload, this.props.handleFailure)
      };
    }

  submitProfileInfo = (item) =>
  {
    delete item.businessprofilepicture
    submitProfileInfo(item, this.handleSuccesfulProfileSubmit, this.props.handleFailure)
  };

    editBusinessProfile = (item) => {
    this.toggleProfileModal()
    this.setState({ 
      active_item: item,
    });
   };
  
  handleSuccesfulDelete = (res) => {
    this.setState({
      redirect:"/mtip/manager",
    })
  }

  deleteCheck = (item) => {
    const prevModal = this.state.delete_modal;
    this.setState({
      delete_modal:!prevModal,
      item_to_delete:item,
    })
  }

  deleteBusinessProfile = (item) => {
    deleteBusinessProfile(this.state.item_to_delete, this.handleSuccesfulDelete, this.props.handleFailure)
    this.deleteCheck("")
  };

  // submitIntegrationsInfo = (platform, item, sharedData) =>
  submitIntegrationsInfo = (platform, item) =>
  {
    // submitIntegrationsInfo(this.state.id, platform, item, sharedData, this.handleSuccesfulSubmit, this.props.handleFailure);
    submitIntegrationsInfo(this.state.id, platform, item, this.handleSuccessfulIntegrationsSubmit, this.props.handleFailure);
  };
  handleSuccessfulIntegrationsSubmit = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.BUSINESS_INTEGRATIONS_UPDATED)
    this.toggleIntegrationsModal();
    this.handleSuccesfulSubmit(res)
  }


  viewIntegrations = () => {
    getIntegrationsInfo(this.state.id, this.handleSuccessfulBusinessIntegrationsGet, this.props.handleFailure)
   };

  handleSuccessfulBusinessIntegrationsGet = (res) => {
    this.setState({
      integrations_info: res.data,
    })
    this.toggleIntegrationsModal()
  }

  updated = () => {
    this.props.refreshState()
  }

  toggleIntegrationsModal = () =>
  {
    const prevModal = this.state.integrations_modal;
    this.setState({ 
      integrations_modal: !prevModal,
    });
  };
  
  toggleThankyouDataModal = () =>
  {
    const prevModal = this.state.thankyou_data_modal;
    this.setState({ 
      thankyou_data_modal: !prevModal,
    });
  };

  viewThankyouData = () => {
    getThankyouDataBusiness(this.state.id, this.handleSuccessfulThankyouDataGet, this.props.handleFailure)
   };

   handleSuccessfulThankyouDataGet = (res) => {
     this.setState({
       thankyou_data: res.data,
      })
    this.toggleThankyouDataModal()
  }
  
  submitThankyouData = (thankyou_card_type, thankyou_card_data) => {
    createThankyouData(this.state.id, thankyou_card_type, thankyou_card_data, this.handleSuccessfulThankyouDataSubmit, this.props.handleFailure)
  }

  handleSuccessfulThankyouDataSubmit = (res) => {
    this.props.handleSuccess(SUCCESS_RESPONSES.THANKYOU_DATA_UPDATED)
    this.setState({
      thankyou_data: res.data,
     })
   this.toggleThankyouDataModal()
 }
  
 
  toggleThankyouData = () => {
    toggleThankyouData(this.state.id, this.handleSuccessfulThankyouDataToggle, this.props.handleFailure);
  }

  handleSuccessfulThankyouDataToggle = (res) => {
    this.setState({
      thankyou_data: res.data,
    })
  }

  viewBusinessProfileQRSettings = () =>
  {
    getSharedQRBusiness(this.state.id,this.handleSuccesfulBusinessProfileQRSettingsGet, this.props.handleFailure)
  }

  handleSuccesfulBusinessProfileQRSettingsGet = (res) => {
    this.setState({
      business_profile_qr_settings: res.data,
    })
    this.toggleBusinessProfileQRSettingsModal()
  }
  
  toggleBusinessProfileQRSettingsModal = () => {
    const prevModal = this.state.business_profile_qr_settings_modal;
    this.setState({ 
      business_profile_qr_settings_modal: !prevModal,
    });
  };

  viewBusinessProfileSettings = () =>
  {
    this.toggleBusinessProfileSettingsModal()
  }

  toggleBusinessProfileSettingsModal = () => {
    const prevModal = this.state.business_profile_settings_modal;
    this.setState({ 
      business_profile_settings_modal: !prevModal,
    });
  };

  
  render() 
  {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return  (
          <Container >
            {(this.state.business_profile_settings_modal) ? <BusinessProfileSettingsModal business_id={this.state.business_info.id} toggleModal = {this.toggleBusinessProfileSettingsModal}/> : null}  
            {(this.state.business_profile_qr_settings_modal) ? <BusinessProfileQRSettingsModal business_profile_qr_settings={this.state.business_profile_qr_settings} business_id={this.state.business_info.id} toggleModal = {this.toggleBusinessProfileQRSettingsModal}/> : null}  
            {(this.state.thankyou_data_modal) ? <BusinessProfileThankyouCardsModal thankyou_data = {this.state.thankyou_data} submitThankyouData = {this.submitThankyouData} toggleThankyouData={this.toggleThankyouData} toggleModal = {this.toggleThankyouDataModal}/> : null}  
            {(this.state.integrations_modal) ? <BusinessProfileIntegrationsModal integrations_info = {this.state.integrations_info} submitIntegrationsInfo = {this.submitIntegrationsInfo} toggleModal = {this.toggleIntegrationsModal}/> : null}  
            {(this.state.delete_modal) ? <DeleteModal toggle={this.deleteCheck} deleteAction={this.deleteBusinessProfile} /> : ""}
            {this.state.business_info ? (BusinessProfile_manager(this.state.business_info, this.editBusinessProfile, this.deleteCheck, this.uploadBusinessProfilePicture, this.viewIntegrations, this.viewThankyouData, this.viewBusinessProfileQRSettings, this.viewBusinessProfileSettings)) : null}
            {this.props.updated ? (this.updated()) : null}
            {this.state.modal ? <BusinessProfileModal active_item = {this.state.active_item} submitProfileInfo = {this.submitProfileInfo} toggleModal = {this.toggleProfileModal}/> : null}
          </Container>
    );
  }
}


export default withErrorHandling(BusinessProfileDetailsDashboard);