import React, { Component } from "react";
// material
import { Stack, Container, Divider,  Card, CardHeader, CardContent } from '@material-ui/core';

import ServiceProfessionalTipBalance from './ServiceProfessionalTipBalance';
import ServiceProfessionalWithdrawalDestination from './ServiceProfessionalWithdrawalDestination';
import { withdrawTips } from "./scripts";
import withErrorHandling from "../../hocs/withErrorHandling";



class ServiceProfessionalMartipiaAccount extends Component
{
  constructor (props)
  {
    super(props);
    this.state = 
    {
      tipInfo:props.tipInfo,
      pendingWithdrawalInfo:props.pendingWithdrawalInfo,
      autoWithdrawalEnabled:props.autoWithdrawalEnabled,
      withdrawalSubmitted:false,
    };
  }
  
  
  withdrawTips= (amount) => {
    withdrawTips(amount,this.props.handleSuccess,this.props.handleFailure)
  }




  render() 
  {
    return  (
    <Container>
      <Card>
        <CardHeader title="💰 Martipia Account 💸" />
        <Divider />
        <CardContent>
          <Stack spacing={2}>
              
            <ServiceProfessionalTipBalance tipInfo={this.state.tipInfo} pendingWithdrawalInfo={this.state.pendingWithdrawalInfo} autoWithdrawalEnabled={this.state.autoWithdrawalEnabled} withdrawTips={this.withdrawTips} />
            <ServiceProfessionalWithdrawalDestination />
        </Stack>
        </CardContent>
      </Card>
    </Container>
    );
  }
}
export default withErrorHandling(ServiceProfessionalMartipiaAccount);