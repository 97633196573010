
import React, { Component } from "react";

import { Button} from '@material-ui/core';
import { Stack, Typography,} from '@material-ui/core';

import Icon from "@iconify/react";
import google_logo from '@iconify/icons-ant-design/google-circle-filled';
import external_link from '@iconify/icons-eva/globe-outline';

import Iframe from 'react-iframe'
import ReactGA from "react-ga4";

import { withTranslation } from 'react-i18next';
//
class ThankYouCard extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      // active_item: this.props.active_item,
      open: true,
      thankyou_card: props.thankyou_card,
    };
  }

  thankyouRedirect = (link) => {
    ReactGA.event("thank_you_card_link_click", {
      "thankyou_type":this.props.thankyou_card.type,
      "thankyou_link":link,
    })
    const abs_link = link.startsWith('http')  ? link : 'https://' + link;
    window.location.href = abs_link;
  }

  render = () => {

    const { t } = this.props;
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_KEY);

    if (!this.props.thankyou_card.enabled)
    {
      return ("")
    }

    if (this.props.thankyou_card.type==="google_review")
    { 
      return (
        <Stack alignItems="center" spacing={1}>
          {this.props.thankyou_card.thankyou_data.google_reviews_map_enabled
          ? (
            <Iframe 
              url={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=place_id:${this.props.thankyou_card.thankyou_data.google_reviews_location_ID}`}
              width="200"
              height="150"
              id=""
              className=""
              display="block"
              position="relative"/>   
          )
          :""}  
          <Button sx={{maxWidth:300}} onClick = {() => this.thankyouRedirect(this.props.thankyou_card.thankyou_data.google_reviews_link)} variant="outlined" >
            <Stack alignItems="center" justifyContent="center">
              <Typography fontSize="1rem" color="primary">
                {t('ThankYouCard_google_review_button')}
              </Typography>
              <Typography fontSize="1rem" color="primary">
                  <Icon
                    width="50"
                    height="50"
                    icon={google_logo} />
              </Typography>
            </Stack>
          </Button>
        </Stack>
      )
    }    
    if (this.props.thankyou_card.type==="other_link")
    {     
      return (     
        <Button sx={{maxWidth:300}} onClick = {this.thankyouRedirectTag(this.props.thankyou_card.thankyou_data.url)} href={this.props.thankyou_card.thankyou_data.url}  variant="outlined" >
          <Stack alignItems="center" justifyContent="center">
            <Typography fontSize="1rem" color="primary">
              {this.props.thankyou_card.data.text_local}
            </Typography>
            <Typography fontSize="1rem" color="primary">
              {this.props.thankyou_card.data.text_english} 
            </Typography>
            <Typography fontSize="1rem" color="primary">
                <Icon
                  width="35"
                  height="35"
                  icon={external_link} />
            </Typography>
          </Stack>
        </Button>
      )
    }
  }
}

export default withTranslation()(ThankYouCard);