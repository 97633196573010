import { Navigate } from "react-router-dom";
import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import { Stack, Typography, TextField, Divider, RadioGroup, FormControlLabel, Radio  } from "@material-ui/core";
import Switch from '@mui/material/Switch';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';

import {changeQRMenu, changeQREasyTip, toggleSharedQRSplit, toggleSharedQRList, toggleSharedQREasyTip, toggleSharedQRMenu, toggleSharedQRBusiness } from "../../mtipManager/mtipBusinessDashboard/scripts";
import { changeMenuType } from "../../martipiaMenu/internal/menu_api";
import QRCode from "react-qr-code";
import withErrorHandling from "../../../hocs/withErrorHandling";
import { SUCCESS_RESPONSES} from "../../../../config/systemMessages";
import MartipiaMenuSettings from "../../martipiaMenu/internal/MartipiaMenuSettings";
import { getMartipiaMenuSettings } from "../../martipiaMenu/internal/menu_api";

class BusinessProfileQRSettingsModal extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      business_profile_qr_settings: props.business_profile_qr_settings,
      menu_data: props.business_profile_qr_settings.menu_data,
      easy_tip_profile: props.business_profile_qr_settings.easy_tip_profile,
      business_id: props.business_id,
      menu_type: props.business_profile_qr_settings.menu_type,
    };
  }

  openMartipiaMenuSettings = () => {
    getMartipiaMenuSettings(this.state.business_id, this.handleMartipiaMenuSettings, this.props.handleFailure);
  }

  handleMartipiaMenuSettings = (res) => {
    this.setState({
      menu_settings_modal: true,
      menu_settings: res.data.menu_settings,
      menu_categories: res.data.categories,
      menu_items: res.data.items,
    });
  }

  handleMenuTypeChoice = (e, newMenuTypeChoice) => {
    changeMenuType(this.state.business_id,newMenuTypeChoice, this.handleMenuTypeChange, this.props.handleFailure);
  };

  handleMenuTypeChange = (res) => {
    this.setState({
      menu_type: res.data.menu_type,
    });
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_TYPE_UPDATED);
  }
    
  handleToggleRefresh = (res) => {
    this.setState({
      business_profile_qr_settings: res.data,
    });
  }
  handleMenuChange = (res) => {
    this.setState({
      menu_data: res.data.menu_data,
    });
    this.props.handleSuccess(SUCCESS_RESPONSES.MENU_LINK_UPDATED);
  }
  handleEasyTipProfileChange = (res) => {
    this.setState({
      easy_tip_profile: res.data.easy_tip_profile,
    });
    this.props.handleSuccess(SUCCESS_RESPONSES.EASY_TIP_UPDATED)
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    
    this.setState(prev_state=> {
        const new_state = {...prev_state};
        new_state[name] = value;
        return new_state;
    });
  };


  changeQRMenu = () =>
  {
    changeQRMenu(this.state.business_id,this.state.menu_data,this.handleMenuChange,this.props.handleFailure)
  }

  changeQREasyTip = () =>
  {
    changeQREasyTip(this.state.business_id,this.state.easy_tip_profile, this.handleEasyTipProfileChange,this.props.handleFailure)
  }


  toggleSharedQRBusiness = () =>
  {
    toggleSharedQRBusiness(this.state.business_id,this.handleToggleRefresh,this.props.handleFailure)
  }

  toggleSharedQRList = () => {
    toggleSharedQRList(this.state.business_id,this.handleToggleRefresh,this.props.handleFailure)
  }

  toggleSharedQRSplit = () => {
    toggleSharedQRSplit(this.state.business_id,this.handleToggleRefresh,this.props.handleFailure)
  }
  toggleSharedQREasyTip = () => {
    toggleSharedQREasyTip(this.state.business_id,this.handleToggleRefresh,this.props.handleFailure)
  }
  toggleSharedQRMenu = () => {
    toggleSharedQRMenu(this.state.business_id,this.handleToggleRefresh,this.props.handleFailure)
  }

  toggleMenuSettingsModal = () => {
    this.setState({menu_settings_modal: !this.state.menu_settings_modal});
  }
  
  render = ()=> {

  const { toggleModal} = this.props;

  if (this.state.redirect) {
    return <Navigate to={this.state.redirect} />
  }
  return (
    <div>
      {this.state.menu_settings_modal && <MartipiaMenuSettings open={this.state.menu_settings_modal} toggleModal={this.toggleMenuSettingsModal} business_id = {this.state.business_id} menu_settings={this.state.menu_settings} menu_items={this.state.menu_items} menu_categories={this.state.menu_categories}/>}
      <Dialog open={this.state.open} onClose={toggleModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Business QR Code</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={3} alignItems="center">
            <DialogContentText>
            Shared business QR code that can display the list of employees, split tips, and more.
            </DialogContentText>
            <Stack justifyContent={"flex-start"} spacing={0.25}>

              <Typography align={"left"} variant='subtitle'>          
                Enable shared business QR code.
              </Typography>
                <Switch
                checked={this.state.business_profile_qr_settings.enabled || false}
                onChange={() => this.toggleSharedQRBusiness()}
                name="shared_qr"
                color="primary"  
              />

              <Typography align={"left"} variant='subtitle'>
                Enable list of employees for tippers to choose from.
              </Typography>
                <Switch
                disabled = {!(this.state.business_profile_qr_settings.enabled) || false}
                checked={(this.state.business_profile_qr_settings.list_enabled || false)}
                onChange={() => this.toggleSharedQRList()}
                name="shared_qr_list_enabled"
                color="primary"  
              />

              <Typography align={"left"} variant='subtitle'>
                Enable split tips that get shared between all employees.
              </Typography>
                <Switch
                disabled = {!(this.state.business_profile_qr_settings.enabled) || false}
                checked={(this.state.business_profile_qr_settings.split_enabled || false)}
                onChange={() => this.toggleSharedQRSplit()}
                name="shared_qr_split_enabled"
                color="primary"  
              />

              <Typography align={"left"} variant='subtitle'>
                Enable easy tip. This will direct the tipper to a profile you choose.
              </Typography>
                <Switch
                disabled = {!(this.state.business_profile_qr_settings.enabled) || false}
                checked={(this.state.business_profile_qr_settings.easy_tip_profile_enabled || false)}
                onChange={() => this.toggleSharedQREasyTip()}
                name="shared_qr_easy_tip_profile_enabled"
                color="primary"  
              />

              <Typography align={"left"} variant="body2">
                Please enter the easy tip profile ID.
              </Typography>
              <Stack direction = 'row' spacing={2}>
                <TextField
                  margin="dense"
                  label="Easy Tip Profile"
                  name="easy_tip_profile"
                  type="text"
                  value={this.state.easy_tip_profile || ""}
                  onChange={this.handleChange}
                  placeholder="Easy Tip Profile ID"
                >
                </TextField>
                <Button 
                  type='submit'
                  size="small"
                  variant="text" 
                  color="inherit" 
                  disabled = {!(this.state.business_profile_qr_settings.enabled) || false}
                  onClick={() => this.changeQREasyTip()}
                  >
                  Save
                </Button>
              </Stack>

              {this.state.business_profile_qr_settings && (
                <Stack>
                  <Typography variant="subtitle2">
                    QR Code:
                  </Typography>
                  <QRCode value={(process.env.REACT_APP_ENVIRONMENT==='DEV') ? `https://test.app.martipia.com/rate/${this.state.business_profile_qr_settings.id}` : `https://app.martipia.com/rate/${this.state.business_profile_qr_settings.id}`} size={125} />

                  <Typography variant="subtitle2">
                    Code ID:
                  </Typography>
                  <Typography variant={"caption"}>
                    {this.state.business_profile_qr_settings.id}
                  </Typography>
                </Stack>
              )}

              <Divider />
              <Stack></Stack>
              <Typography align={"left"} variant='h5'>
                Martipia Plus Subscription Features
              </Typography>
              <Typography align={"left"} variant='caption'>
                Features that are only available with a Martipia Plus subscription. Please contact davitk@martipia.com for more information.
              </Typography>
              <Typography align={"left"} variant='subtitle'>
                Enable menus.
              </Typography>
                <Switch
                disabled = {!(this.state.business_profile_qr_settings.enabled) || !(this.state.business_profile_qr_settings.menu_allowed) || false}
                checked={(this.state.business_profile_qr_settings.menu_enabled || false)}
                onChange={() => this.toggleSharedQRMenu()}
                name="shared_qr_menu_enabled"
                color="primary"  
              />
              
              <Typography align={"left"} variant="subtitle">
                  Choose menu type:
                </Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={this.state.menu_type}
                  exclusive
                  onChange={this.handleMenuTypeChoice}
                  aria-label="Menu choice"
                  disabled = {!(this.state.business_profile_qr_settings.enabled) || !(this.state.business_profile_qr_settings.menu_allowed) || false}
                >
                  <ToggleButton value="link">Link</ToggleButton>
                  <ToggleButton value="custom">Custom Martipia Menu</ToggleButton>
                  {/* <ToggleButton value="thirdOption">Third Option</ToggleButton> */}
                </ToggleButtonGroup>

                {/* Conditional Rendering based on Choice */}
                {this.state.menu_type === 'link' && (
                  // Input for Link
                  <div>
                    <Typography variant="body2">
                      Please enter the web link to the menu.
                    </Typography>
                    <Stack direction = 'row' spacing={2}>
                      <TextField
                        margin="dense"
                        label="Menu Link"
                        name="menu_data"
                        type="text"
                        disabled = {!(this.state.business_profile_qr_settings.enabled) || !(this.state.business_profile_qr_settings.menu_allowed) || false}
                        value={this.state.menu_data || ""}
                        onChange={this.handleChange}
                        placeholder="Menu Link"
                      >
                      </TextField>
                      <Button 
                        type='submit'
                        size="small"
                        variant="text" 
                        color="inherit" 
                        disabled = {!(this.state.business_profile_qr_settings.enabled) || !(this.state.business_profile_qr_settings.menu_allowed) || false}
                        onClick={() => this.changeQRMenu()}
                        >
                        Save
                      </Button>
                    </Stack>
                  </div>
                )}
                {this.state.menu_type === 'custom' && (
                  <Button onClick={ () => this.openMartipiaMenuSettings()} variant='outlined'>
                    Manage Martipia Menu
                  </Button>
                )}
                

                {/* Continue with your existing components */}

            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
}
export default withErrorHandling(BusinessProfileQRSettingsModal);